import { BaseModel } from "./BaseModel";
import { App } from "../App";

/*
 long id;
 long deviceId;
 Date fixTime;
 double latitude;
 double longitude;
 double altitude; // value in meters
 double course;
 double speed; // value in knots
 boolean ignition;
 long hours;
 double odometer;
 double fuelLevel;
 double fuel;
 String driverUniqueId;
 boolean motion;
 double totalDistance;

    //ignored
 Date idleEndTime;
 long idleDuration;
*/

export const fields = [{
    name: 'id',
    type: 'int',
    isPk: true,
}, {
    name: 'deviceId',
    type: 'int'
}, {
    name: 'fixTime',
    type: 'date',
    dateFormat: 'c'
},  {
    name: 'latitude',
    type: 'float'
}, {
    name: 'longitude',
    type: 'float'
}, {
    name: 'altitude',
    type: 'float'
}, {
    name: 'speed',
    type: 'float',
    convert: (value) => App.AttributeFormatter.getConverter('speed')(value)
}, {
    name: 'ignition',
    type: 'boolean'
}, {
    name: 'hours',
    type: 'int'
}, {
    name: 'odometer',
    type: 'float',
}, {
    name: 'fuelLevel',
    type: 'float'
},  {
    name: 'fuel',
    type: 'float'
}, {
    name: 'driverUniqueId',
    type: 'string'
}, {
    name: 'motion',
    type: 'boolean'
}, {
    name: 'totalDistance',
    type: 'float'
}]

export class ReportPosition extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}