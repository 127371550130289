export const types = {
    CHANGE_LOCATION: 'locale/CHANGE_LOCATION',
    MESSAGES_LOADED: 'locale/MESSAGES_LOADED'
}

export const initialState = {
    locale: 'en',
    messages: {}
}

export const actions = {
    messagesLoaded(payload) {
        return { type: types.MESSAGES_LOADED, payload }
    }
}

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.MESSAGES_LOADED:
            return {
                ...state,
                locale: action.payload.locale,
                messages: action.payload.messages
            }
        default:
            return state
    }
}

export default {
    types,
    reducers,
    actions,
    state: initialState
}