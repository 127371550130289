
import { BaseModel } from "./BaseModel";
import { Position } from "./Position";
export const fields = [{
    name: 'id',
    type: 'int',
    isPk: true
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'uniqueId',
    type: 'string'
}, {
    name: 'phone',
    type: 'string',
    allowNull: true
}, {
    name: 'model',
    type: 'string',
    allowNull: true
}, {
    name: 'contact',
    type: 'string',
    allowNull: true
}, {
    name: 'category',
    type: 'string',
    allowNull: true,
    default: 'default',
}, {
    name: 'status',
    type: 'string',
    allowNull: true,
    default: 'unknown',
}, {
    name: 'lastUpdate',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'groupId',
    type: 'int'
}, {
    name: 'disabled',
    type: 'boolean'
}, {
    name: 'geofenceIds'
}, {
    name: 'attributes'
}];

export class Device extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
        this._viewstate = {
            hidden: false,
            selected: false,
            history: []
        }
    }

    /*set position(position) {
        if (!position) {
            this._position = null;
            return;
        }
        if ((!this._position) || (this._position && this._position.id !== position.id)) {
            this._position = position;
            this._viewstate.history.push(position);
        }
    }

    get position() {
        return this._position;
    }*/

    get history() {
        return this.getViewState('history');
    }

    set history(value) {
        this.setViewState('history', value);
    }

    set hidden(value) {
        this.setViewState('hidden', value)
    }

    get hidden() {
        return this.getViewState('hidden')
    }

    set selected(value) {
        this.setViewState('selected', value)
    }

    get selected() {
        return this.getViewState('selected');
    }


    deserialize(input) {
        super.deserialize(input);
        //console.log(input.position);
        if (input.position) {
            this.position = new Position().deserialize(input.position);
        }
        return this;
    }
}