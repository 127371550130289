import constants from "../../config/constants";


class CoordinateFormatsConverter {
    formatValue(key, value, unit) {
        let hemisphere, degrees, minutes, seconds;
        if (key === 'latitude') {
            hemisphere = value >= 0 ? 'N' : 'S';
        } else {
            hemisphere = value >= 0 ? 'E' : 'W';
        }
        switch (unit) {
            case 'ddm':
                value = Math.abs(value);
                degrees = Math.floor(value);
                minutes = (value - degrees) * 60;
                return degrees + '° ' + minutes.toFixed(constants.coordinatePrecision) + '\' ' + hemisphere;
            case 'dms':
                value = Math.abs(value);
                degrees = Math.floor(value);
                minutes = Math.floor((value - degrees) * 60);
                seconds = Math.round((value - degrees - minutes / 60) * 3600);
                return degrees + '° ' + minutes + '\' ' + seconds + '" ' + hemisphere;
            default:
                return value.toFixed(constants.coordinatePrecision) + '°';
        }

    }
}

export default new CoordinateFormatsConverter();