import { t } from "../Translator";
import { App } from "../../App";


class VolumeUnitsConverter {
    convertValue(value, unit, back) {
        if (!unit) {
            unit = 'ltr';
        }
        const model = App.Store.getState().volume_units.volume_units[unit];
        return back ? value / model.factor : value * model.factor;
    }

    formatValue(value, unit, convert) {
        if (!unit) {
            unit = 'ltr';
        }
        const model = App.Store.getState().volume_units.volume_units[unit];
        return (convert ? this.convertValue(value, unit) : value).toFixed(1) + ' ' + t(model.name);
    }
}

export default new VolumeUnitsConverter();