import createGenericStore from "../generic_store";
import { t } from '../../utils/Translator';


const data = [];

const store = createGenericStore('alarm_types', {
    autoLoad: true,
    pk: 'key',
    initialState: {
        alarm_types: data
    },
    actions: {
        loaded(items) {
            const d = [];
            for (const key in items) {
                if (items.hasOwnProperty(key) && key.lastIndexOf('alarm', 0) === 0) {
                    d.push({
                        key: key.charAt(5).toLowerCase() + key.slice(6),
                        name: items[key]
                    });
                }
            }
            return {
                type: 'alarm_types/LOADED',
                payload: { items: d },
            }
        },
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}