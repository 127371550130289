import groups from "../store/groups";
import { Group } from "../models";
import ApiStoreService from "./ApiStoreService";

export class GroupService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'groups';
        this.storeName = groups;
    }

    transformSingleResponse(data) {
        return new Group().deserialize(data);
    }


}

export default new GroupService();