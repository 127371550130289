import createGenericStore from "../generic_store";
import { t } from "../../utils/Translator";
import { KnownAttribute } from "../../models/KnownAttribute";

const data = [{
    key: 'color',
    name: t('attributeColor'),
    valueType: 'color'
}, {
    key: 'speedLimit',
    name: t('attributeSpeedLimit'),
    valueType: 'number',
    dataType: 'speed'
}, {
    key: 'polylineDistance',
    name: t('attributePolylineDistance'),
    valueType: 'number',
    dataType: 'distance'
}]

const attrs = {};
Object.values(data).map(attr => {
    attrs[attr.key] = new KnownAttribute().deserialize(attr);
})
const store = createGenericStore('geofence_attributes', {
    autoLoad: true,
    initialState: {
        geofence_attributes: attrs
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}