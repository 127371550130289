export default class BaseMap {
    constructor() {
        this.config = {};
        this.container = null;
    }

    init(container, config) {
        this.container = container;
        this.config = config;
        //init the map
        this._initMap(container, config);

    }

    //override on childs
    _initMap(container, config) {

    }




}