import api from '../utils/Api';
import { convertToQs } from '../utils/functions';

export default class ApiService {
    constructor() {
        this.api = api;
        this.baseUrl = '';
        //this.store = store;
    }

    async list(params = {}) {
        const { data } = await this.api.get(this.baseUrl + '/?' + convertToQs(params));
        return this.transformListResponse(data);
    }

    async listAll(params = {}) {
        params.all = true;
        return this.list(params);
    }

    async getOne(params = {}) {
        const { data } = await this.api.get(this.baseUrl + '/?' + convertToQs(params));
        return this.transformSingleResponse(data[0]);
    }

    async save(model, files = []) {
        const { data } = await this.api.post(this.baseUrl, this.serialize(model));
        return this.transformSingleResponse(data);
    }

    async update(model, files = []) {
        const { data } = await this.api.put(this.baseUrl + '/' + model.pk, this.serialize(model));
        return this.transformSingleResponse(data);
    }

    async destroyWithParams(model) {
        const { data } = await this.api.delete(this.baseUrl, {
            data: this.serialize(model)
        });
        return this.transformSingleResponse(data);
    }

    async destroy(id) {
        const { data } = await this.api.delete(this.baseUrl + '/' + id);
        return this.transformSingleResponse(data);
    }

    serialize(model) {
        return model.serialize();
    }

    transformSingleResponse(data) {
        return data;
    }

    transformListResponse(data) {
        const ret = [];
        data.map(r => {
            ret.push(this.transformSingleResponse(r));
            return r;
        });
        return ret;
    }
}