import drivers from "../store/drivers";
import { Driver } from "../models";
import ApiStoreService from "./ApiStoreService";

export class DriversService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'drivers';
        this.storeName = drivers;
    }

    

    transformSingleResponse(data) {
        return new Driver().deserialize(data);
    }


}

export default new DriversService();