import  { BaseModel } from "./BaseModel";
export const fields= [{
    name: 'id',
    type: 'int'
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'groupId',
    type: 'int'
}, {
    name: 'attributes'
}];

export class Group extends BaseModel {
    constructor(data={}){
        super(fields,data);
    }
}