import { KnownCommand } from "../models";
import ApiStoreService from "./ApiStoreService";
import command_types from "../store/command_types";

export class CommandTypesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'commands/types';
        this.storeName = command_types;
    }

    

    transformSingleResponse(data) {
        return new KnownCommand().deserialize(data);
    }


}

export default new CommandTypesService();