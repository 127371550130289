import createGenericStore from "../generic_store";
const data = {
    ltr: {
        key: 'ltr',
        name: 'sharedLiterAbbreviation',
        fullName: 'sharedLiter',
        factor: 1
    },
    impGal: {
        key: 'impGal',
        name: 'sharedGallonAbbreviation',
        fullName: 'sharedImpGallon',
        factor: 4.546
    },
    usGal: {
        key: 'usGal',
        name: 'sharedGallonAbbreviation',
        fullName: 'sharedUsGallon',
        factor: 3.785
    }
};

const store = createGenericStore('volume_units', {
    autoLoad: true,
    initialState: {
        volume_units: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}