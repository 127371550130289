import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import EventItem from './listItem';

class EventsList extends React.Component {
    constructor(props) {
        super();
    }
    
    selectEvent(id){

    }

    componentDidMount() {
    }

    componentWillUnmount() {
     
    }

    render() {
        const { events } = this.props;
        return (
            <ListGroup className="border-0 rounded-0 events-list">
                {events.map(e => {
                    return (
                        <ListGroup.Item key={e.id} onClick={() => this.selectEvent(e.id)} className="list-item">
                            <EventItem event={e} />
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        )
    }
}

EventsList.propTypes = {
    events: PropTypes.array.isRequired
};


EventsList.defaultProps = {
}

export default EventsList;