import calendars from "../store/calendars";
import { Calendar } from "../models";
import ApiStoreService from "./ApiStoreService";

export class CalendarsService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'calendars';
        this.storeName = calendars;
    }

    

    transformSingleResponse(data) {
        return new Calendar().deserialize(data);
    }


}

export default new CalendarsService();