import * as React from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Day, Week, WorkWeek, Month, Agenda, ICalendarExport, ICalendarImport, Inject, Resize, DragAndDrop } from '@syncfusion/ej2-react-schedule';
import './calendar-export-import.css';
import { extend } from '@syncfusion/ej2-base';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { PropertyPane } from './property-pane';
import { t } from "../../../utils/Translator";
import '@syncfusion/ej2-react-schedule/styles/material.css'
import '@syncfusion/ej2-react-inputs/styles/material.css'
import '@syncfusion/ej2-react-buttons/styles/material.css'
import '@syncfusion/ej2-base/styles/material.css'

import '@syncfusion/ej2-calendars/styles/material.css'
import '@syncfusion/ej2-buttons/styles/material.css'
import '@syncfusion/ej2-dropdowns/styles/material.css'
import '@syncfusion/ej2-icons/styles/material.css'
import '@syncfusion/ej2-inputs/styles/material.css'
import '@syncfusion/ej2-lists/styles/material.css'
import '@syncfusion/ej2-navigations/styles/material.css'
import '@syncfusion/ej2-popups/styles/material.css'
import '@syncfusion/ej2-schedule/styles/material.css'
import '@syncfusion/ej2-splitbuttons/styles/material.css'

import * as dataSource from './datasource.json';
/**
 * Schedule ICS Export and Import sample
 */
export class CalendarImportExport extends React.Component {
    constructor(props) {
        super(props);
        this.multiple = false;
        this.showFileList = false;
        this.allowedExtensions = '.ics';
        this.data = extend([], dataSource.scheduleData, null, true);
    }
    onClick() {
        this.scheduleObj.exportToICalendar();
    }
    onSelect(args) {
        this.scheduleObj.importICalendar(args.event.target.files[0]);
    }
    render() {
        return (<div className='schedule-control-section'>
        <div className='col-lg-12 control-section'>
          <div className='control-wrapper'>
            <ScheduleComponent width='100%' height='650px' ref={schedule => this.scheduleObj = schedule} selectedDate={new Date()} eventSettings={{ dataSource: this.data }}>
              <ViewsDirective>
                <ViewDirective option='Day'/>
                <ViewDirective option='Week'/>
                <ViewDirective option='WorkWeek'/>
                <ViewDirective option='Month'/>
                <ViewDirective option='Agenda'/>
              </ViewsDirective>
              <Inject services={[Day, Week, WorkWeek, Month, Agenda, ICalendarExport, ICalendarImport, Resize, DragAndDrop]}/>
            </ScheduleComponent>
          </div>
        </div>
        <div className='col-lg-3 property-section'>
            <table id='property' title='Properties' className='property-panel-table' style={{ width: '100%' }}>
              <tbody>
                <tr style={{ height: '50px' }}>
                  <td style={{ width: '50%' , padding:20}}>           
                      <ButtonComponent id='ics-export' title='Export' onClick={this.onClick.bind(this)}>{t("Export Icalendar")}</ButtonComponent>
                  </td>
                  <td style={{ width: '50%' }}>
                    <UploaderComponent id='fileUpload' type='file' allowedExtensions={this.allowedExtensions} cssClass='calendar-import' buttons={{ browse: t("Import Icalendar") }} multiple={this.multiple} showFileList={this.showFileList} selected={this.onSelect.bind(this)}></UploaderComponent>
                  </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>);
    }
}
export default CalendarImportExport;