class TranslatorClass {
    constructor() {
        this.lang = 'en';
        this.messages = {};
    }

    init(config = {}) {
        this.lang = config.lang || 'en';
        this.messages = config.messages || {};
    }

    translate(key, params = {}) {
        return this.messages[key] || key;
    }


}

export const Translator = new TranslatorClass();
export const t = (key, params, defaultValue) => {
    return Translator.translate(key, params);
};