import React from 'react';
//import OLReportsMap from '../../utils/maps/openlayers/OLReportsMap';
import { store } from '../../store';
import LeafletReportsMap from '../../utils/maps/leaflet/LeafletReportsMap';
import events from '../../utils/Events';
import positions from '../../store/positions';
import app from '../../store/app';
import devices from '../../store/devices';
import geofences from '../../store/geofences';

class ReportMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.map = null;
        this.selectedDeviceId = null;


        //bind methods
        this.onOptionsChanged = this.onOptionsChanged.bind(this);
        this.zoomToAll = this.zoomToAll.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.onDeviceUpdate = this.onDeviceUpdate.bind(this);
        this.onPositionSelected = this.onPositionSelected.bind(this);
        this.onPositionUnselected = this.onPositionUnselected.bind(this);
        this.onPositionUpdated = this.onPositionUpdated.bind(this);
        this.updateGeofences = this.updateGeofences.bind(this);
        this.invalidateSize = this.invalidateSize.bind(this);
        this.clear = this.clear.bind(this);
        this.onData = this.onData.bind(this);
    }

    componentDidMount() {
        this.initListeners();
        this.initMap();
        //        this.clear();
    }

    componentWillUnmount() {
        this.clearListeners();
        this.map = null;
    }

    initMap() {
        if (this.map) {
            this.map.destroy();
            this.map = null;
        }
        this.map = new LeafletReportsMap();
        this.map.onMapClick = (e) => {
            this.onPositionUnselected();
        }

        this.map.onPositionSelected = (id) => {
           
        };

        this.map.onStateChanged = (config) => {
            store.dispatch(app.actions.mapStateChanged(config))
        }


        this.map.init(this.mapContainerRef.current, this.props.mapConfig)

        this.updateGeofences();
    }

    switchMap() {
        
    }

    updateMarkers(positions) {
        this.map.updatePositions(positions);
    }

    updateGeofences() {
        this.map.updateGeofences(Object.values(store.getState().geofences.geofences));
    }

    onOptionsChanged(value) {
        if (value.cluster_enabled) {
            this.map.enableCluster();
        } else {
            this.map.disableCluster();
        }
        if (value.show_geofences) {
            this.map.showGeofences();
        } else {
            this.map.hideGeofences();
        }
        if (value.show_routes) {
            this.map.showRoutes();
        } else {
            this.map.hideRoutes();
        }
        if (value.show_names) {
            this.map.showObjectNames();
        } else {
            this.map.hideObjectNames();
        }

        if (value.follow_devices) {
            this.map.followDevices();
        } else {
            this.map.unfollowDevices();
        }

        this.config = value;
    }

    zoomToAll() {
        this.map.zoomToAll();
    }

    zoomIn() {
        this.map.zoomIn();
    }

    zoomOut() {
        this.map.zoomOut();
    }

    invalidateSize() {
        this.map.invalidateSize();
    }

    onDeviceUpdate(device) {
        this.updateMarkers([device]);
    }

    onPositionSelected(id) {
        this.map.selectMarker(id);
    }


    onPositionUpdated(payload){
        this.map.updateMarker(payload.id, payload.data);
    }

    onPositionUnselected() {
        this.map.unselectMarker();
    }


    onData(data) {
        this.clear(data.config.clearRoutes);
        this.updateMarkers(data);
    }

    clear(clearRoutes = true) {
        this.map.clearMarkers();
        if(clearRoutes) this.map.clearRoutes();
    }

    clearListeners() {
        //events.off(app.types.MAP_OPTIONS_CHANGE, this.onOptionsChanged)
        events.off(app.types.MAP_CONFIG, this.switchMap)
        events.off('ReportsMap/ZOOM_TO_ALL', this.zoomToAll);
        events.off('ReportsMap/ZOOM_IN', this.zoomIn);
        events.off('ReportsMap/ZOOM_OUT', this.zoomOut);
        events.off('reports/SHOW', this.invalidateSize);
        events.off('reports/MAP_DATA', this.onData);
        events.off('reports/CLEAR', this.clear);
        events.off('reports/HIDE', this.clear)
        events.off('reports/SELECT_POSITION', this.onPositionSelected)
        events.off('reports/UNSELECT_POSITION', this.onPositionUnselected)
        events.off('reports/UPDATE_POSITION', this.onPositionUpdated);
        events.off('reports/TOGGLE', this.invalidateSize);
        events.off(geofences.types.UPDATED, this.updateGeofences);
        events.off(geofences.types.DELETED, this.updateGeofences);
    }

    initListeners() {
        //events.on(app.types.MAP_OPTIONS_CHANGE, this.onOptionsChanged)
        events.on(app.types.MAP_CONFIG, this.switchMap)
        events.on('ReportsMap/ZOOM_TO_ALL', this.zoomToAll);
        events.on('ReportsMap/ZOOM_IN', this.zoomIn);
        events.on('ReportsMap/ZOOM_OUT', this.zoomOut);
        events.on('reports/SHOW', this.invalidateSize);
        events.on('reports/MAP_DATA', this.onData)
        events.on('reports/HIDE', this.clear)
        events.on('reports/CLEAR', this.clear);
        events.on('reports/SELECT_POSITION', this.onPositionSelected)
        events.on('reports/UNSELECT_POSITION', this.onPositionUnselected)
        events.on('reports/UPDATE_POSITION', this.onPositionUpdated)
        events.on('reports/TOGGLE', this.invalidateSize);
        events.on(geofences.types.UPDATED, this.updateGeofences);
        events.on(geofences.types.DELETED, this.updateGeofences);
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.mapContainerRef}></div>
                {this.props.children}
            </React.Fragment>
        );
    }
}


export default ReportMap;