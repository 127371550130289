import notifications from "../store/notifications";
import { Notification } from "../models";
import ApiStoreService from "./ApiStoreService";

export class NotificationsService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'notifications';
        this.storeName = notifications;
    }

    transformSingleResponse(data) {
        return new Notification().deserialize(data);
    }


}

export default new NotificationsService();