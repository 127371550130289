import maintenances from "../store/maintenances";
import { Maintenance } from "../models";
import ApiStoreService from "./ApiStoreService";

export class MaintenancesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'maintenance';
        this.storeName = maintenances;
    }

    transformSingleResponse(data) {
        return new Maintenance().deserialize(data);
    }


}

export default new MaintenancesService();