import L from 'leaflet';
import 'leaflet-draw';
import { store } from '../../../store';
import BaseMap from '../base/BaseMap';
import { AppService } from '../../../services';
import 'overlapping-marker-spiderfier-leaflet';
import LeafletGeofenceConverter from './LeafletGeofenceConverter';
import omnivore from '@mapbox/leaflet-omnivore';


export default class LeafletGeofencesMap extends BaseMap {
    constructor() {
        super();
        this._geofence = null;
        this.onChange = null;
    }

    destroy() {
        if (this.map) {
            this.map.off();
            this.map.remove();
        }
    }

    set geofence(value) {
        this._geofence = value;
        if (value) {
            const layer = LeafletGeofenceConverter.wktToGeometry(value);
            layer.addTo(this.editableLayers);
            this.map.fitBounds(this.editableLayers.getBounds());
        }
        this.checkControls();
    }

    get geofence() {
        return this._geofence;
    }



    _initMap(target, config = {}) {

        const scaleLine = new L.Control.Scale({
            position: 'bottomright'
        });

        this.config = config;

        let mapUrl = config.map_config && config.map_config.url ? config.map_config.url : store.getState().app.mapstate.map_config.url;
        const subdomains = config.map_config && config.map_config.subdomains ? config.map_config.subdomains : store.getState().app.mapstate.map_config.subdomains;
        const lat = config.map_config && config.map_config.latitude ? config.map_config.latitude : store.getState().app.mapstate.map_config.latitude;
        const lon = config.map_config && config.map_config.longitude ? config.map_config.longitude : store.getState().app.mapstate.map_config.longitude;
        const zoom = config.map_config && config.map_config.zoom ? config.map_config.zoom : store.getState().app.mapstate.map_config.zoom;
        const maxZoom = config.map_config && config.map_config.maxZoom ? config.map_config.maxZoom : store.getState().app.mapstate.map_config.maxZoom;

        this.tileLayer = new L.TileLayer(mapUrl.replace(/&amp;/g, '&'), {
            attribution: '',
            subdomains: subdomains
        });

        this.map = new L.Map(target, {
            center: [lat, lon],
            zoom: zoom,
            maxZoom: maxZoom,
            attributionControl: false,
            zoomControl: false,
            preferCanvas: true,
            layers: [
                this.tileLayer,
            ]
        });

        scaleLine.addTo(this.map);

        this._loadPoiLayer();

        this.editableLayers = new L.FeatureGroup();
        this.map.addLayer(this.editableLayers);

        var options = {
            position: 'topright',
            draw: {
                polyline: {
                    shapeOptions: {
                        color: '#f357a1',
                        weight: 10
                    }
                },
                polygon: {
                    allowIntersection: false, // Restricts shapes to simple polygons
                    drawError: {
                        color: '#e1e100', // Color the shape will turn when intersects
                        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
                    },
                    shapeOptions: {
                        color: '#bada55'
                    }
                },
                circle: {

                }, // Turns off this drawing tool
                marker: false,
                circlemarker: false,
                rectangle: false,

            },
            edit: {
                featureGroup: this.editableLayers, //REQUIRED!!
                remove: false
            }
        };

        this.drawControl = new L.Control.Draw(options);
        this.map.addControl(this.drawControl);


        this.drawControlEditOnly = new L.Control.Draw({
            position: 'topright',
            edit: {
                featureGroup: this.editableLayers
            },
            draw: false
        });


        this.map.on("draw:created", (e) => {
            const layer = e.layer;
            this._geofence = LeafletGeofenceConverter.geometryToWkt(layer);
            layer.addTo(this.editableLayers);
            this._fireChangeEvent();
            this.checkControls();
        });

        this.map.on("draw:edited", (e) => {
            const layers = e.layers;
            layers.eachLayer((layer) => {
                this._geofence = LeafletGeofenceConverter.geometryToWkt(layer);
            })
            this._fireChangeEvent();
            this.checkControls();
        });

        this.map.on("draw:deleted", () => {
            this.geofence = null;
            this.checkControls();
            this._fireChangeEvent();
        });


        return this.map;

    }


    _loadPoiLayer() {
        const poiLayer = AppService.getPreference('poiLayer', null);

        if (poiLayer) {
            omnivore.kml(poiLayer).addTo(this.map);
        }

    }

    _fireChangeEvent() {
        if (this.onChange) {
            this.onChange(this._geofence);
        }
    }

    checkControls() {
        if (this._geofence) {
            this.map.removeControl(this.drawControl);
            this.map.addControl(this.drawControlEditOnly);
        } else {
            this.map.removeControl(this.drawControlEditOnly);
            this.map.addControl(this.drawControl);
        }
    }


}