import React from 'react';
import {Checkbox, FormControlLabel} from "@mui/material";

export default class CustomCheckbox extends React.Component {
    render() {
        return (
            this.props.label ?
                <FormControlLabel
                    className="ml-0"
                    control={
                        <Checkbox
                            className="ml-0 pl-0"
                            {...this.props}
                        />
                    }
                    label={<span style={{fontSize: '0.7rem'}}>{this.props.label}</span>}
                />
                : <Checkbox {...this.props}
                />
            // <label className="custom-chk">
            //     <input type="checkbox" {...this.props} />
            //     <span className="checkmark" ></span>
            //     <strong>{this.props.label}</strong>
            // </label>
        )
    }
}