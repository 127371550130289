import ApiService from './ApiService';
import { store } from '../store';

export default class ApiStoreService extends ApiService {
    constructor() {
        super();
        this.storeName = null;
    }

    async list(params = {}, populateStore = true) {
        const items = await super.list(params);
        if (populateStore)
            store.dispatch(this.storeName.actions['loaded'](items))
        return items;
    }

    async listAll(params = {}, populateStore = true) {
        const items = await super.listAll(params);
        if (populateStore)
            store.dispatch(this.storeName.actions['loaded_all'](items))
        return items;
    }

    async getOne(params = {}, populateStore = true) {
        const item = await super.getOne(params);
        if (populateStore)
            store.dispatch(this.storeName.actions['udated'](item))
        return item;
    }

    async save(model, files = [], populateStore = true) {
        const item = await super.save(model, files);
        if (populateStore)
            store.dispatch(this.storeName.actions['added'](item))
        return item;
    }

    async update(model, files = [], populateStore = true) {
        const item = await super.update(model, files);
        if (populateStore)
            store.dispatch(this.storeName.actions['updated'](item))
        return item;
    }

    async destroy(id, populateStore = true) {
        const item = await super.destroy(id);
        if (populateStore)
            store.dispatch(this.storeName.actions['deleted'](id))
        return item;
    }
}