import createGenericStore from "../generic_store";
import { Device } from "../../models";


const attributeOptions = {
    'lastUpdate': {
        key: 'lastUpdate',
        title: 'deviceLastUpdate',
        show: true,
        canUnselect: true,
    },
    'phone': {
        key: 'phone',
        title: 'sharedPhone',
        show: false,
        canUnselect: true,
    },
    'uniqueId': {
        key: 'uniqueId',
        title: 'deviceIdentifier',
        show: false,
        canUnselect: true,
    },
    'model': {
        key: 'model',
        title: 'deviceModel',
        show: false,
        canUnselect: true,
    },
    'contact': {
        key: 'contact',
        title: 'deviceContact',
        show: false,
        canUnselect: true,
    }
}


const types = {
    DEVICE_SELECTED: 'devices/DEVICE_SELECTED',
    DEVICE_UNSELECTED: 'devices/DEVICE_UNSELECTED',
    TOGGLE: 'devices/TOGGLE',
    RESET: 'devices/RESET',
    RESET_POSITIONS: 'devices/RESET_POSITIONS',
    FILTER_BY_NAME: 'devices/FILTER_BY_NAME',
    CHANGE_DISPLAY_ATTRIBUTES: 'devices/CHANGE_DISPLAY_ATTRIBUTES',
    UPDATED: 'devices/UPDATED',
};

const actions = {
    updated(item){
        return {
            type: types.UPDATED,
            payload: { item }
        }
    },
    setSelectedDevice(id) {
        return {
            type: types.DEVICE_SELECTED,
            payload: { id }
        }
    },
    unselectDevice() {
        return {
            type: types.DEVICE_UNSELECTED
        }
    },
    deviceToggled(id) {
        return {
            type: types.TOGGLE,
            payload: { id },
        }
    },
    filterByName(filter) {
        return {
            type: types.FILTER_BY_NAME,
            payload: filter
        }
    },
    resetPositions() {
        return {
            type: types.RESET_POSITIONS,
        }
    },
}

const reducers = {
    [types.TOGGLE]: (state, action) => {
        const id = action.payload.id;
        const device = state.devices[id];
        if (device) {
            device.hidden = !device.hidden;
            state.devices[id] = new Device().deserialize(device)
        }
    },
    [types.DEVICE_UNSELECTED]: (state, action) => {
        if (state.selectedDeviceId && state.devices[state.selectedDeviceId]) {
            state.devices[state.selectedDeviceId].selected = false;
            state.devices[state.selectedDeviceId] = new Device().deserialize(state.devices[state.selectedDeviceId])
            state.selectedDevice = null;

        }
        state.selectedDeviceId = null;
    },
    [types.DEVICE_SELECTED]: (state, action) => {
        const id = action.payload.id;
        if (id !== state.selectedDeviceId) {
            if (state.selectedDeviceId && state.devices[state.selectedDeviceId]) {
                state.devices[state.selectedDeviceId].selected = false;
                state.devices[state.selectedDeviceId] = new Device().deserialize(state.devices[state.selectedDeviceId])
            }
            if (id > 0) {
                state.selectedDeviceId = id;
                const device = state.devices[id];
                state.devices[id].selected = true;
                state.devices[id] = new Device().deserialize(state.devices[id])
                state.selectedDevice = state.devices[id];
            } else {
                state.selectedDeviceId = null;
                state.selectedDevice = null;
            }
        }

    },
    [types.FILTER_BY_NAME]: (state, action) => {
        if (state.currentFilterByName !== action.payload) {
            state.currentFilterByName = action.payload;
        }

    },
    [types.UPDATED]: (state, action) => {
        if (action.payload.item.id === state.selectedDeviceId) {
            action.payload.item.selected = true;
            state.selectedDevice = action.payload.item;
        }
        state.devices[action.payload.item.id] = action.payload.item;
        state.all_devices[action.payload.item.id] = action.payload.item;
    },
}


const store = createGenericStore('devices', {
    pk: 'id',
    initialState: {
        device_attributes: attributeOptions
    },
    types: types,
    actions: actions,
    reducers: reducers
});



export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}