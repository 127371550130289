import createGenericStore from "../generic_store";
import { KnownAttribute } from "../../models/KnownAttribute";
//console.log(Device);

const data = {
    raw: {
        key: 'raw',
        name: 'positionRaw',
        valueType: 'string'
    },
    index: {
        key: 'index',
        name: 'positionIndex',
        valueType: 'number'
    },
    hdop: {
        key: 'hdop',
        name: 'positionHdop',
        valueType: 'number'
    },
    vdop: {
        key: 'vdop',
        name: 'positionVdop',
        valueType: 'number'
    },
    pdop: {
        key: 'pdop',
        name: 'positionPdop',
        valueType: 'number'
    },
    sat: {
        key: 'sat',
        name: 'positionSat',
        valueType: 'number'
    },
    satVisible: {
        key: 'satVisible',
        name: 'positionSatVisible',
        valueType: 'number'
    },
    rssi: {
        key: 'rssi',
        name: 'positionRssi',
        valueType: 'number'
    },
    gps: {
        key: 'gps',
        name: 'positionGps',
        valueType: 'number'
    },
    roaming: {
        key: 'roaming',
        name: 'positionRoaming',
        valueType: 'boolean'
    },
    event: {
        key: 'event',
        name: 'positionEvent',
        valueType: 'string'
    },
    alarm: {
        key: 'alarm',
        name: 'positionAlarm',
        valueType: 'string'
    },
    status: {
        key: 'status',
        name: 'positionStatus',
        valueType: 'string'
    },
    odometer: {
        key: 'odometer',
        name: 'positionOdometer',
        valueType: 'number',
        dataType: 'distance'
    },
    serviceOdometer: {
        key: 'serviceOdometer',
        name: 'positionServiceOdometer',
        valueType: 'number',
        dataType: 'distance'
    },
    tripOdometer: {
        key: 'tripOdometer',
        name: 'positionTripOdometer',
        valueType: 'number',
        dataType: 'distance'
    },
    hours: {
        key: 'hours',
        name: 'positionHours',
        valueType: 'number',
        dataType: 'hours'
    },
    steps: {
        key: 'steps',
        name: 'positionSteps',
        valueType: 'number'
    },
    input: {
        key: 'input',
        name: 'positionInput',
        valueType: 'string'
    },
    output: {
        key: 'output',
        name: 'positionOutput',
        valueType: 'string'
    },
    power: {
        key: 'power',
        name: 'positionPower',
        valueType: 'number',
        dataType: 'voltage'
    },
    battery: {
        key: 'battery',
        name: 'positionBattery',
        valueType: 'number',
        dataType: 'voltage'
    },
    batteryLevel: {
        key: 'batteryLevel',
        name: 'positionBatteryLevel',
        valueType: 'number',
        dataType: 'percentage'
    },
    fuel: {
        key: 'fuel',
        name: 'positionFuel',
        valueType: 'number',
        dataType: 'volume'
    },
    fuelConsumption: {
        key: 'fuelConsumption',
        name: 'positionFuelConsumption',
        valueType: 'number',
        dataType: 'consumption'
    },
    versionFw: {
        key: 'versionFw',
        name: 'positionVersionFw',
        valueType: 'string'
    },
    versionHw: {
        key: 'versionHw',
        name: 'positionVersionHw',
        valueType: 'string'
    },
    type: {
        key: 'type',
        name: 'sharedType',
        valueType: 'string'
    },
    ignition: {
        key: 'ignition',
        name: 'positionIgnition',
        valueType: 'boolean'
    },
    flags: {
        key: 'flags',
        name: 'positionFlags',
        valueType: 'string'
    },
    charge: {
        key: 'charge',
        name: 'positionCharge',
        valueType: 'boolean'
    },
    ip: {
        key: 'ip',
        name: 'positionIp',
        valueType: 'string'
    },
    archive: {
        key: 'archive',
        name: 'positionArchive',
        valueType: 'boolean'
    },
    distance: {
        key: 'distance',
        name: 'positionDistance',
        valueType: 'number',
        dataType: 'distance'
    },
    totalDistance: {
        key: 'totalDistance',
        name: 'deviceTotalDistance',
        valueType: 'number',
        dataType: 'distance'
    },
    rpm: {
        key: 'rpm',
        name: 'positionRpm',
        valueType: 'number'
    },
    vin: {
        key: 'vin',
        name: 'positionVin',
        valueType: 'string'
    },
    approximate: {
        key: 'approximate',
        name: 'positionApproximate',
        valueType: 'boolean'
    },
    throttle: {
        key: 'throttle',
        name: 'positionThrottle',
        valueType: 'number'
    },
    motion: {
        key: 'motion',
        name: 'positionMotion',
        valueType: 'boolean'
    },
    armed: {
        key: 'armed',
        name: 'positionArmed',
        valueType: 'number'
    },
    geofence: {
        key: 'geofence',
        name: 'sharedGeofence',
        valueType: 'string'
    },
    acceleration: {
        key: 'acceleration',
        name: 'positionAcceleration',
        valueType: 'number'
    },
    deviceTemp: {
        key: 'deviceTemp',
        name: 'positionDeviceTemp',
        valueType: 'number',
        dataType: 'temperature'
    },
    operator: {
        key: 'operator',
        name: 'positionOperator',
        valueType: 'string'
    },
    command: {
        key: 'command',
        name: 'deviceCommand',
        valueType: 'string'
    },
    blocked: {
        key: 'blocked',
        name: 'positionBlocked',
        valueType: 'boolean'
    },
    dtcs: {
        key: 'dtcs',
        name: 'positionDtcs',
        valueType: 'string'
    },
    obdSpeed: {
        key: 'obdSpeed',
        name: 'positionObdSpeed',
        valueType: 'number',
        dataType: 'speed'
    },
    obdOdometer: {
        key: 'obdOdometer',
        name: 'positionObdOdometer',
        valueType: 'number',
        dataType: 'distance'
    },
    result: {
        key: 'result',
        name: 'eventCommandResult',
        valueType: 'string'
    },
    driverUniqueId: {
        key: 'driverUniqueId',
        name: 'positionDriverUniqueId',
        valueType: 'string',
        dataType: 'driverUniqueId'
    }
}


const attrs = {};
Object.values(data).map(attr => {
    attrs[attr.key] = new KnownAttribute().deserialize(attr);
})


const store = createGenericStore('position_attributes', {
    initialState: {
        position_attributes: attrs,
        all_position_attributes: attrs
    }
});

export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}