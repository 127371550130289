import React from 'react';
import AttributesPage from '../Attributes';
import {Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {Button} from "@mui/material"
import {t} from '../../../utils/Translator';
import {Formik} from 'formik';
import * as yup from 'yup';
import {store} from '../../../store';
import {Geofence} from '../../../models';
import {deepClone, isEqual} from '../../../utils/functions';
import LeafletGeofencesMap from '../../../utils/maps/leaflet/LeafletGeofencesMap';
import CustomSelect from '../../Controls/CustomSelect';
import {App} from "../../../App";
import CustomNumberField from "../../Controls/CustomNumberField";
import CustomColorField from "../../Controls/CustomColorField";


export default class GeofenceForm extends React.Component {
    constructor(props) {
        super(props);

        this.mapContainerRef = React.createRef();
        const calendarOptions = [];
        Object.values(store.getState().calendars.calendars).map(o => {
            calendarOptions.push({label: t(o.name), value: o.id})
        });
        calendarOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        const item = new Geofence().deserialize(deepClone(props.item))

        if (item.attributes === undefined)
            item.attributes = {}

        this.state = {
            attributesPage: false,
            item: item,
            mode: props.mode,
            sending: false,
            changed: false,
            calendarOptions: calendarOptions,
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
    }

    initMap() {
        if (this.map) {
            this.map.destroy();
            this.map = null;
        }
        this.map = new LeafletGeofencesMap();
        this.map.init(this.mapContainerRef.current)
        this.map.geofence = this.state.item.area;
        this.map.onChange = (area) => {
            this.state.item.area = area;
            this.setState({changed: true});
        };
    }


    componentDidMount() {
        this.initMap();
    }

    componentWillUnmount() {
        this.map = null;
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({attributesPage: true})
    }

    onAttributesCancel() {
        const clone = new Geofence().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({item: new Geofence().deserialize(deepClone(newProps.item))})
    }


    render() {
        const {sending, item, calendarOptions} = this.state;


        const schema = yup.object({
            name: yup.string().required(),
            description: yup.string().optional(),
        });

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="GeofencesAttributes" onHide={() => this.onAttributesClosed()}
                                onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()}/>
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        description: item.description,
                        calendarId: item.calendarId,
                        attributes: item.attributes
                    }}
                >
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          dirty,
                          isSubmitting,
                          setFieldValue,
                          errors,
                          isValid
                      }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate
                                  className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i
                                                className="mdi mdi-map-marker-radius"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedGeofence')}
                                            </h3>
                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("sharedDescription")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="description"
                                                    defaultValue={values.description}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.description}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("attributeColor")}</FormLabel>
                                                <CustomColorField
                                                    name="color"
                                                    value={values.attributes["color"]}
                                                    onChange={event => {
                                                        this.state.item.attributes["color"] = event
                                                        this.setState({item: this.state.item, changed: true})
                                                        handleChange(event)
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("attributeSpeedLimit")}</FormLabel>
                                                <CustomNumberField
                                                    name="speed"
                                                    value={values.attributes.speedLimit}
                                                    type="number"
                                                    xdatatype={'speed'}
                                                    onChange={event => {
                                                        if (event.target.value > 0)
                                                            this.state.item.attributes["speedLimit"] = event.target.value
                                                        else
                                                            delete this.state.item.attributes["speedLimit"]
                                                        handleChange(event)
                                                    }}
                                                />
                                            </FormGroup>
                                            {/* todo fix */}
                                            {/*<FormGroup>*/}
                                            {/*    <FormLabel>{t("attributePolylineDistance")}</FormLabel>*/}
                                            {/*    <CustomNumberField*/}
                                            {/*        name="distance"*/}
                                            {/*        value={values.attributes.polylineDistance}*/}
                                            {/*        type="number"*/}
                                            {/*        xdatatype={'distance'}*/}
                                            {/*        onChange={event => {*/}
                                            {/*            if (event.target.value > 0)*/}
                                            {/*                this.state.item.attributes["polylineDistance"] = event.target.value*/}
                                            {/*            else*/}
                                            {/*                delete this.state.item.attributes["polylineDistance"]*/}
                                            {/*            handleChange(event)*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</FormGroup>*/}
                                            <FormGroup style={{zIndex: 1}}>
                                                <FormLabel>{t("sharedCalendar")}</FormLabel>
                                                <CustomSelect options={calendarOptions} isClearable name="calendarId"
                                                              defaultValue={values.calendarId}
                                                              onChange={setFieldValue}/>
                                            </FormGroup>
                                            <div ref={this.mapContainerRef} className="geofence-map d-flex flex-grow-1"
                                                 style={{height: '500px', zIndex: 0}}></div>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {
                                                    App.App.user.administrator ?
                                                        <Button variant="primary" className="align-self-stretch"
                                                                onClick={() => this.openAttributesPage()}>
                                                            <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                        </Button>
                                                        : null
                                                }
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red mr-2" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" size="large" className="btn-blue"
                                                    disabled={!this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}