import createGenericStore from "../generic_store";
import { t } from '../../utils/Translator'

const data = {
    'mail.smtp.host': {
        key: 'mail.smtp.host',
        name: t('attributeMailSmtpHost'),
        valueType: 'string'
    }, 'mail.smtp.port': {
        key: 'mail.smtp.port',
        name: t('attributeMailSmtpPort'),
        valueType: 'number',
        allowDecimals: false,
        minValue: 1,
        maxValue: 65535
    }, 'mail.smtp.starttls.enable': {
        key: 'mail.smtp.starttls.enable',
        name: t('attributeMailSmtpStarttlsEnable'),
        valueType: 'boolean'
    }, 'mail.smtp.starttls.required': {
        key: 'mail.smtp.starttls.required',
        name: t('attributeMailSmtpStarttlsRequired'),
        valueType: 'boolean'
    }, 'mail.smtp.ssl.enable': {
        key: 'mail.smtp.ssl.enable',
        name: t('attributeMailSmtpSslEnable'),
        valueType: 'boolean'
    }, 'mail.smtp.ssl.trust': {
        key: 'mail.smtp.ssl.trust',
        name: t('attributeMailSmtpSslTrust'),
        valueType: 'string'
    }, 'mail.smtp.ssl.protocols': {
        key: 'mail.smtp.ssl.protocols',
        name: t('attributeMailSmtpSslProtocols'),
        valueType: 'string'
    }, 'mail.smtp.from': {
        key: 'mail.smtp.from',
        name: t('attributeMailSmtpFrom'),
        valueType: 'string'
    }, 'mail.smtp.auth': {
        key: 'mail.smtp.auth',
        name: t('attributeMailSmtpAuth'),
        valueType: 'boolean'
    }, 'mail.smtp.username': {
        key: 'mail.smtp.username',
        name: t('attributeMailSmtpUsername'),
        valueType: 'string'
    }, 'mail.smtp.password': {
        key: 'mail.smtp.password',
        name: t('attributeMailSmtpPassword'),
        valueType: 'string'
    }
};


const attr_data = {};


Object.values(data).map(o => {
    attr_data[o.key] = o;
})


const store = createGenericStore('user_attributes', {
    initialState: {
        user_attributes: attr_data,
        all_user_attributes: attr_data
    }
});

export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}