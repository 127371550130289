import { ComputedAttribute } from "../models";
import ApiStoreService from "./ApiStoreService";
import computed_attributes from "../store/computed_attributes";

export class ComputedAttributesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'attributes/computed';
        this.storeName = computed_attributes;
    }

    

    transformSingleResponse(data) {
        return new ComputedAttribute().deserialize(data);
    }


}

export default new ComputedAttributesService();