import React from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { t } from '../../../utils/Translator';
import { AppService, UsersService, GeofencesService, PermissionsService, DevicesService } from '../../../services';
import Page from '../../Page';
import UserForm from './form';
import { User } from '../../../models';
import List from './list';
import ExcelFile from "react-export-excel/dist/ExcelPlugin/components/ExcelFile";
import ExcelSheet from "react-export-excel/dist/ExcelPlugin/elements/ExcelSheet";
import ExcelColumn from "react-export-excel/dist/ExcelPlugin/elements/ExcelColumn";

class UserListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showForm: false,           
            item: null
        }

        this.hideForm = this.hideForm.bind(this);
        this.showForm = this.showForm.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.onSave = this.onSave.bind(this);
       
    }

    async deleteItem(id) {
        if (await AppService.showDeleteConfirm()) {
            UsersService.destroy(id);
        }
    }

    showForm(id) {
        this.setState({
            showForm: true,
            item: id ? new User().deserialize(this.props.items[id]) : new User()
        })
    }

    async onSave(item) {
        try {
            if (item.id) {
                await UsersService.update(item)
                this.hideForm();
            } else {
                item = await UsersService.save(item);
                this.showForm(item.id)
            }
        } catch (ex) {
            AppService.showError(ex)
        }
    }

    componentWillMount() {
        UsersService.list();
    }

    hideForm() {
        this.setState({
            showForm: false,
            item: null
        })
    }


    render() {
        const { onHide } = this.props;
        const items = this.state.items || this.props.items
        const exportData = this.state.filter == null ? Object.values(items) : List.filterValues(Object.values(items), this.state.filter)

        return <React.Fragment>
            {this.state.showForm ?
                <Page onHide={this.hideForm} title={<React.Fragment><h3 className="title"><i className="mdi mdi mdi-account-multiple"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('settingsUsers')}</h3></React.Fragment>}>
                    <UserForm item={this.state.item} onSave={this.onSave} onHide={this.hideForm}></UserForm>
                </Page>
                : null}

            <div className="container-wrapper grow">
                <div className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                    <div className="flex-grow-1 overflow">
                        <div className="container p-0">
                            <List items={this.props.items} onDelete={this.deleteItem} onSelect={this.showForm} enableDelete={true} enableSelection={false} />
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container d-flex">
                            <div className="flex-grow-1 d-flex">
                                <Button variant="contained" size="large" className="align-self-stretch btn-blue" onClick={() => this.showForm('')}>
                                    <i className="mdi mdi-plus-circle-outline"></i> {t('sharedAdd')}
                                </Button>
                            </div>
                            <ExcelFile
                                element={<Button variant="contained" size="large" className="btn-green mr-2"><i className="mdi mdi-download"></i>{t('reportExport')}
                                </Button>}>
                                <ExcelSheet data={exportData} name={t("settingsUsers")}>
                                    <ExcelColumn label={t('sharedName')} value="name"/>
                                    <ExcelColumn label={t('userEmail')} value="email"/>
                                    <ExcelColumn label={t('userAdmin')}
                                                 value="administrator"/>
                                    {/*<ExcelColumn label={t('deviceTitle')}*/}
                                    {/*             value={col => col.devices ? col.devices.length.toString() : ""}/>*/}
                                    <ExcelColumn label={t('userDeviceLimit')}
                                                 value="deviceLimit"/>
                                    <ExcelColumn label={t('userUserLimit')}
                                                 value="userLimit"/>
                                </ExcelSheet>

                            </ExcelFile>
                            <Button variant="contained" size="large" className="btn-red" onClick={onHide}>
                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                            </Button>
                        </div>
                    </footer>
                </div>
            </div>
        </React.Fragment>
    }
}


const mapStateToProps = (state) => ({
    items: state.users.users
})

export default connect(mapStateToProps, null)(UserListPage);