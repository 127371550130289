import React from 'react';
import { connect } from 'react-redux';
import Page from '../Page';
import { store } from '../../store';
import app from '../../store/app';


///settings
import AccountForm from './Account/form';
import AccountSettings from './Account/preferences';
import ServerPage from './Server';
import { MaintenanceList } from './Maintenances';
import { DriversList } from './Drivers';
import { CalendarsList } from './Calendars';
import { GeofencesList } from './Geofences';
import { SavedCommandsList } from './SavedCommands';
import { StatisticsList } from './Statistics';
import { NotificationsList } from './Notifications';
import { ComputedAttributesList } from './ComputedAttributes';
import { DevicesList } from './Devices';
import { CustomerDevicesList } from './CustomerDevices';
import { ExpensesList } from './Expenses';
import { UsersList } from './Users';
import { GroupsList } from './Groups';
import GroupForm from './Groups/form';
import DeviceForm from './Devices/form';


class CrudPages extends React.Component {

    renderModal(config) {
        const modal = config.config;
        const props = config.props;

        return <Page open={true} onHide={() => this.hideModal()} title={<h1 className="title"><i className={modal.icon}></i> {modal.title}</h1>}>
            {this.renderModalComponent(modal, props)}
        </Page>
    }

    hideModal() {
        store.dispatch(app.actions.hideModal());
    }

    renderModalComponent(modal, props) {
        let Component = null;
        // eslint-disable-next-line default-case
        switch (modal.name) {
            case 'calendars':
                Component = CalendarsList
                break;
            case 'computedAttributes':
                Component = ComputedAttributesList;
                break;
            case 'server':
                Component = ServerPage;
                break;
            case 'account':
                Component = AccountForm;
                break;
            case 'accountSettings':
                Component = AccountSettings;
                break;
            case 'drivers':
                Component = DriversList;
                break;
            case 'devices':
                Component = DevicesList;
                break;
            case 'customerDevices':
                Component = CustomerDevicesList;
                break;
                // todo reenable
            // case 'expenses':
            //     Component = ExpensesList;
            //     break;
            case 'device-form':
                Component = DeviceForm
                break;
            case 'geofences':
                Component = GeofencesList;
                break;
            case 'expenses':
                Component = ExpensesList;
                break;    
            case 'groups':
                Component = GroupsList;
                break;
            case 'group-form':
                Component = GroupForm
                break;
            case 'maintenance':
                Component = MaintenanceList;
                break;
            case 'notifications':
                Component = NotificationsList;
                break;
            case 'statistics':
                Component = StatisticsList;
                break;
            case 'savedCommands':
                Component = SavedCommandsList;
                break;
            case 'users':
                Component = UsersList;
                break;
        }

        return <Component {...modal} {...props} onHide={() => this.hideModal()} />
    }

    render() {
        const { modal } = this.props;
        return <React.Fragment>
            {modal && modal != null ? this.renderModal(modal) : null}
        </React.Fragment>
    }
}


const mapStateToProps = ((state) => ({
    modal: state.app.modal,
}));

export default connect(mapStateToProps, null)(CrudPages); 