import { t } from "../Translator";
import { App } from "../../App";

class TimeUnitsConverter {
    convertValue(value, unit, back) {
        if (!unit) {
            unit = 's'
        }
        value=parseFloat(value);
        const model = App.Store.getState().time_units.time_units[unit];        
        const ret= back ? value * model.factor :  value / model.factor;
        return ret;
    }
}

export default new TimeUnitsConverter();