import { BaseModel } from "./BaseModel";
import { App } from "../App";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'description',
    type: 'string'
}, {
    name: 'type',
    type: 'string'
}, {
    name: 'attribute',
    type: 'string'
}, {
    name: 'expression',
    type: 'string'
}];

export class ComputedAttribute extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }

    get attributeName() {
        if (this.attribute) {
            const attr = App.Store.getState().position_attributes.position_attributes[this.attribute];
            if (attr) {
                return attr.name;
            }
            return this.attribute;
        }
        return '';
    }

    get attributeType() {
        if (this.attribute) {
            const attr = App.Store.getState().position_attributes.position_attributes[this.attribute];
            if (attr) {
                return attr.valueType;
            }
            return 'string';
        }
        return 'string';
    }
} 