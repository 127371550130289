import React from 'react';
import { Link } from 'react-router-dom'

export class Error404Page extends React.Component {

    componentWillMount() {
        document.body.classList.add('external');
    }

    componentWillUnmount() {
        document.body.classList.remove('external');
    }


    render() {
        return (
            <div className="page page-error" id="pg404">
                <div id="contentWrapper" className="h-100 d-flex flex-column justify-content-center">
                    <h1 className="title">404</h1>
                    <h1 className="sub-title">Not Found!</h1>
                    <div className="descripton text-center">
                        <Link to="/" className="btn btn-lg btn-light"><i className="mdi mdi-home"></i> Home</Link>
                    </div>
                </div>
            </div>
        )
    }
}