import { createReducer } from "redux-starter-kit";
import { isArray } from "util";


const createGenericStore = (name, config = {
    pk: 'pk'
}) => {
    const types = Object.assign({
        LOADED: `${name}/LOADED`,
        LOADED_ALL: `${name}/LOADED_ALL`,
        RESET: `${name}/RESET`,
        UPDATED: `${name}/UPDATED`,
        ADDED: `${name}/ADDED`,
        DELETED: `${name}/DELETED`,
        MERGE: `${name}/MERGE`,
    }, config.types || {});

    const initialState = Object.assign({
        loading: false,
        [name]: {},
        [`all_${name}`]: {}
    }, config.initialState || {});

    const actions = Object.assign({
        loaded(items) {
            return { 
                type: types.LOADED,
                payload: { items },
            }
        },
        loadedAll(items) {
            return {
                type: types.LOADED_ALL,
                payload: { items },
            }
        },
        reset() {
            return {
                type: types.RESET
            }
        },
        added(item) {
            return {
                type: types.ADDED,
                payload: { item },
            }
        },
        updated(item) {
            return {
                type: types.UPDATED,
                payload: { item },
            }
        },
        deleted(pk) {
            return {
                type: types.DELETED,
                payload: { [config.pk]: pk },
            }
        },
        merge(items) {
            return {
                type: types.MERGE,
                payload: { items },
            }
        },
    }, config.actions || {})

    const reducers = createReducer(initialState, Object.assign({
        [types.RESET]: (state, action) => {
            state = { ...initialState }
        },
        [types.LOADED_ALL]: (state, action) => {
            state[`all_${name}`] = action.payload.items.reduce((map, obj) => (map[obj[config.pk]] = obj, map), {});
            if (config.onAllLoaded) {
                config.onAllLoaded(state, action);
            }
        },
        [types.LOADED]: (state, action) => {
            state[`${name}`] = action.payload.items.reduce((map, obj) => (map[obj[config.pk]] = obj, map), {});
            if (config.onLoaded) {
                config.onLoaded(state, action);
            }
        },
        [types.MERGE]: (state, action) => {
            if (isArray(action.payload.items)) {
                state[`${name}`] = Object.assign(state[`${name}`], action.payload.items.reduce((map, obj) => (map[obj[config.pk]] = obj, map), {}));
            } else {
                state[`${name}`] = Object.assign(state[`${name}`], action.payload.items);
            }
            if (config.onMerge) {
                config.onMerge(state, action);
            }
        },
        [types.UPDATED]: (state, action) => {
            state[`${name}`][action.payload.item[config.pk]] = action.payload.item;
            state[`all_${name}`][action.payload.item[config.pk]] = action.payload.item;
            if (config.onUpdated) {
                config.onUpdated(state, action);
            }
        },
        [types.ADDED]: (state, action) => {
            state[`${name}`][action.payload.item[config.pk]] = action.payload.item;
            state[`all_${name}`][action.payload.item[config.pk]] = action.payload.item;
            if (config.onAdded) {
                config.onAdded(state, action);
            }
        },
        [types.DELETED]: (state, action) => {
            delete state[`${name}`][action.payload[config.pk]];
            delete state[`all_${name}`][action.payload[config.pk]];
            if (config.onDeleted) {
                config.onDeleted(state, action);
            }
        }
    }, config.reducers || {}),
    )

    return {
        types,
        reducers,
        actions,
        state: initialState
    }
}

export default createGenericStore;