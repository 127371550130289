import { BaseModel } from "./BaseModel";
export const fields = [{
    name: 'key',
    type: 'string',
    isPk: true,
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'valueType',
    type: 'string'
}, {
    name: 'dataType',
    type: 'string'
}];



export class KnownAttribute extends BaseModel {

    constructor(data = {}) {
        super(fields, data);
    }
}
