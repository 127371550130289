//const imagesId = ['arrow','animal', 'bicycle', 'boat', 'bus', 'car', 'crane', 'helicopter', 'default', 'motorcycle', 'offroad', 'person', 'pickup', 'plane', 'ship', 'tractor', 'train', 'tram', 'trolleybus', 'truck', 'van'];
const imagesId = ['arrow', 'bus', 'car', 'motorcycle', 'default', 'pickup', 'truck', 'van', 'person', 'bicycle'];
const startEndId = ['marker_a','marker_b']
const stopIdle = ['marker_stop', 'marker_idle']
const alarm = ['marker_acceleration','marker_braking','marker_cornering']
const images = {};
const reportImages = {};
const startEndMarkers = {};
const stopIdleMarkers = {};
const alarmMarkers = {}

const addSvgFile = (file, id) => {
    const svg = document.createElement('object');
    svg.setAttribute('id', id);
    svg.setAttribute('data', file);
    svg.setAttribute('type', 'image/svg+xml');
    svg.setAttribute('style', 'visibility:hidden;position:absolute;left:-100px;');
    document.body.appendChild(svg);
}

imagesId.map(i => {
    addSvgFile('img/markers/' + i + '.svg', i + 'Svg');
    /*images[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        statusFillId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'status' : 'background',
        iconFillId: i === 'car' || i === 'default' || i === 'pickup'? 'icon' : null,
        rotateId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'marker' : 'background',
        scaleId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'marker' : 'layer1'
    }*/
    images[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        statusFillId: i === 'arrow' ? 'arrow' : 'status',
        iconFillId: i === 'arrow' ? null : 'icon',
        rotateId: i === 'arrow' ? 'arrow' : 'marker',
        scaleId: i === 'arrow' ? 'arrow' : 'marker'
    }
    return images[i];
});

imagesId.map(i => {
    addSvgFile('img/report_markers/' + i + '.svg', 'report' + i + 'Svg');
    /*images[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        statusFillId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'status' : 'background',
        iconFillId: i === 'car' || i === 'default' || i === 'pickup'? 'icon' : null,
        rotateId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'marker' : 'background',
        scaleId: i === 'arrow' ? 'arrow' : i === 'car' || i === 'default' || i === 'van' || i === 'pickup' || i === 'truck' || i === 'bus' ? 'marker' : 'layer1'
    }*/
    reportImages[i] = {
        key: 'report' + i + 'Svg',
        name: i,
        svg: document.getElementById('report' + i + 'Svg').contentDocument,
        iconFillId: i === 'arrow' ? null : 'icon',
        rotateId: i === 'arrow' ? 'arrow' : 'marker',
        scaleId: i === 'arrow' ? 'arrow' : 'marker'
    }
    return reportImages[i];
});

startEndId.map(i => {
    addSvgFile('img/markers/' + i + '.svg',  i + 'Svg');
    startEndMarkers[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        iconFillId: 'icon',
        rotateId: 'marker',
        scaleId: 'marker'
    }
    return startEndMarkers[i]
})

stopIdle.map(i => {
    addSvgFile('img/markers/' + i + '.svg',  i + 'Svg');
    stopIdleMarkers[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        iconFillId: 'icon',
        rotateId: 'marker',
        scaleId: 'marker'
    }
    return stopIdleMarkers[i]
})

alarm.map(i => {
    addSvgFile('img/markers/' + i + '.svg',  i + 'Svg');
    alarmMarkers[i] = {
        key: i + 'Svg',
        name: i,
        svg: document.getElementById(i + 'Svg').contentDocument,
        iconFillId: 'icon',
        rotateId: 'marker',
        scaleId: 'marker'
    }
    return alarmMarkers[i]
})

export default {
    ids: imagesId,
    images: images,
    reportImages: reportImages,
    startEndMarkers: startEndMarkers,
    stopIdleMarkers: stopIdleMarkers,
    alarmMarkers: alarmMarkers
};