import React from 'react';
import DeviceList from '../DeviceList/list';
import { t } from '../../utils/Translator';
import { store } from '../../store';
import app from '../../store/app';
import { App } from '../../App';
import { GroupsService, AppService } from '../../services';
import groups from '../../store/groups';
import Events from '../../utils/Events';
import devices from '../../store/devices';

export class GroupItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showForm: false
        }

        this.toggleGroup = this.toggleGroup.bind(this);
        this.showForm = this.showForm.bind(this);
        this.hideForm = this.hideForm.bind(this);
    }

    toggleGroup(g) {
        g.open = !g.open;
        this.forceUpdate();
    }

    shouldComponentUpdate(nextProps) {
        return this.props.items.length != nextProps.items.length;
    }

    showChanged(group) {
        group.hidden = !group.hidden;
        group.open = !group.hidden;

        group.devices.map(id => {
            const d = store.getState().devices.devices[id];
            d.hidden = group.hidden;
            Events.emit(devices.types.UPDATED, d);
        })

        this.forceUpdate();
    }

    async save(item) {
        try {
            await GroupsService.update(item);
            store.dispatch(app.actions.hideModal());
            store.dispatch(groups.actions.updated(item));
            Events.emit(groups.types.UPDATED, item)
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    showForm(e) {
        e.stopPropagation();
        store.dispatch(app.actions.showModal({ name: 'group-form', icon: 'mdi mdi-google-circles-extended', title: t('sharedEdit') + ' ' + t('groupParent') }, { item: this.props.group, onHide: this.hideForm, onSave: this.save }))
    }

    hideForm() {
        store.dispatch(app.actions.hideModal());
    }

    render() {
        const { group } = this.props;

        return <React.Fragment>

            <div className={'device-groups-item ' + (group.open ? 'expanded' : 'collapsed')} key={group.id}>
                <div className="header" onClick={() => this.toggleGroup(group)}>
                    <label className="custom-chk float-left" style={{ fontSize: '.8em', top: '-2px', left: '-2px' }}>
                        <input type="checkbox" checked={!group.hidden} onChange={() => this.showChanged(group)} onClick={(e) => e.stopPropagation()} />
                        <span className="checkmark" onClick={(e) => e.stopPropagation()}></span>
                        &nbsp;
                </label>
                    <h3 className="title">{group.name} <span className="count">({group.devices.length})</span></h3>
                    {group.id && App.App.userHasPermission('groups') ? <span className="edit" title={t('sharedEdit')} onClick={(e) => this.showForm(e)}><i className="mdi mdi-dots-vertical"></i></span> : null}
                </div>
                <div className="content">
                    {group.open ? <DeviceList devices={group.devices}></DeviceList> : null}
                </div>
            </div>
        </React.Fragment>
    }
}

