import { Command } from "../models";
import ApiStoreService from "./ApiStoreService";
import commands from "../store/commands";

export class CommandsService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'commands';
        this.storeName = commands;
    }


    async getSendOptions(deviceId) {
        const { data } = await this.api.get(this.baseUrl + '/send?deviceId=' + deviceId);
        return this.transformListResponse(data);
    }

    async send(command) {
        const response = await this.api.post(this.baseUrl + '/send', command.serialize());
        return response;
    }


    transformSingleResponse(data) {
        return new Command().deserialize(data);
    }


}

export default new CommandsService();