import React from 'react';
import {connect} from 'react-redux';
import {actions} from '../../../store/session';
import * as yup from 'yup';
import {AppService, PasswordResetService} from '../../../services';
import {t} from '../../../utils/Translator';
import {Alert, Box, Button, Container, Grid, Snackbar, TextField, Typography} from '@mui/material';
import {Col, Row} from "react-bootstrap";
import {Form, Formik} from "formik";

export class VerifyCodePage extends React.Component {
    constructor(...args) {
        super(...args);
        this.state = {error: '', email: this.props.location.state.email, sending: false, resetSuccess:false, lang: AppService.lang['name']};
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeLang = this.changeLang.bind(this);
    }

    async handleSubmit(values) {
        if (!values.password) return

        try {
            this.setState({sending: true, error: ''})
            const data = await PasswordResetService.verifyCode(values.password,this.state.email);
            if(data.verified) {
                this.setState({sending: false, error: '', resetSuccess:true})
                this.props.history.push('/newpassword', {code: values.password});
            }
            else 
                this.setState({error: t('passwordResetCodeFail'), sending: false})            
        } catch (ex) {
            this.setState({error: t('passwordResetCodeFail'), sending: false})
        }
    }

    componentWillUpdate() {
    }

    changeLang(lang) {
        const params = new URLSearchParams(window.location.search);
        params.set('lang', lang);
        const host = "/";
        window.history.replaceState({}, '', `${host}?${params}`);
        window.location.reload(true);
    }


    render() {
        const {sending, error, lang, resetSuccess} = this.state;
        const schema = yup.object({
            password: yup.string().min(6).max(6).required(),
        });

        return (
            <Container fluid={true} maxWidth={false} className="h-100 w-100">
                <Snackbar
                    anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                    open={resetSuccess} autoHideDuration={4000}
                >
                    <Alert severity="success" sx={{width: '100%'}}>
                        {t("passwordResetSuccess")}
                    </Alert>
                </Snackbar>
                <Snackbar
                    anchorOrigin={{ vertical:'top', horizontal:'right' }}
                    open={error!==null && error!==undefined && error.length>0} autoHideDuration={4000} >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
                <Row className="d-flex h-100 w-100 login-container">
                    {/*<Head>*/}
                    {/*    <title>Login | Material Kit</title>*/}
                    {/*</Head>*/}
                    <div className="login-title">
                        <h1 className="welcome">Welcome to</h1>
                        <img className="pt-2 login-logo"
                             src="img/logo-light.svg" alt="IQ track"
                        />
                        <h2 className="business">Business</h2>
                    </div>
                    <Formik
                        validationSchema={schema}
                        onSubmit={(e) => this.handleSubmit(e)}
                        disabled={sending}
                        initialValues={{}}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              errors,
                          }) => (
                            <Form noValidate onSubmit={handleSubmit} className="form form-horizontal ">
                                    <Box
                                        className="login-card"
                                        component="main"
                                        sx={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            flexGrow: 1,
                                            minHeight: '100%',

                                            backgroundImage: ("/static/images/a.jpg")
                                        }}

                                    >
                                        <Container maxWidth="sm">

                                            <form onSubmit={this.handleSubmit}>
                                                <Box sx={{my: 3}}>
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="h4"
                                                    >
                                                        {t("passwordReset")}
                                                    </Typography>
                                                    <Typography
                                                        color="textSecondary"
                                                        gutterBottom
                                                        variant="body2"
                                                    >
                                                        {t("passwordResetCodeHint")}
                                                    </Typography>
                                                </Box>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                >

                                                </Grid>
                                                <TextField
                                                    error={Boolean(errors.password)}
                                                    fullWidth
                                                    label={t('passwordResetCode')}
                                                    margin="normal"
                                                    name="password"
                                                    // onBlur={}
                                                    onChange={handleChange}
                                                    value={values.password}
                                                    variant="outlined"
                                                />
                                                <Box sx={{py: 2}}>
                                                    <Button
                                                        color="primary"
                                                        disabled={sending}
                                                        fullWidth
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                    >
                                                        {sending ? t('sharedLoading') : t('reset')}
                                                    </Button>
                                                </Box>
                                            </form>
                                        </Container>
                                    </Box>
                            </Form>
                        )}
                    </Formik>
                </Row>
            </Container>
        )
    }
}


const mapStateToProps = (state) => ({
    loading: state.session.loading,
    error: state.session.error,
    user: state.session.user,
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    login: credentials => {
        dispatch(actions.login(credentials));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodePage)