import notificators from "../store/notificators";
import { KnownNotificator } from "../models";
import ApiStoreService from "./ApiStoreService";

export class NotificatorsService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'notifications/notificators';
        this.storeName = notificators;
    }

    transformSingleResponse(data) {
        return new KnownNotificator().deserialize(data);
    }


}

export default new NotificatorsService();