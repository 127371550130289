import React from 'react';
import AttributesPage from '../Attributes';
import {Form, FormGroup, FormLabel, FormControl} from 'react-bootstrap';
import {Button} from '@mui/material'
import {t} from '../../../utils/Translator';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Driver} from '../../../models';
import {deepClone, getRandomId, isEqual} from '../../../utils/functions';
import {App} from "../../../App";


export default class DriverForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attributesPage: false,
            item: new Driver().deserialize(deepClone(props.item)),
            mode: props.mode,
            sending: false,
            changed: false
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.generateId = this.generateId.bind(this)
    }


    save(values) {
        const item = this.state.item.deserialize(values);
        this.props.onSave(item);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({attributesPage: true})
    }

    onAttributesCancel() {
        const clone = new Driver().deserialize(deepClone(this.state.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({item: new Driver().deserialize(deepClone(newProps.item))})
    }

    generateId() {
        const item =  new Driver().deserialize(deepClone(this.state.item))
        item.uniqueId = getRandomId()

        this.setState({
            item,
            changed:  true
        })
    }

    render() {
        const {sending, item} = this.state;
        const schema = yup.object({
            name: yup.string().required(),
            uniqueId: yup.string().min(1).required(),
        });

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="driversAttributes" onHide={() => this.onAttributesClosed()}
                                onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()}/>
                : null}

            <div className="container-wrapper">
                <Formik
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        uniqueId: item.uniqueId
                    }}
                >
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          dirty,
                          isSubmitting,
                          errors,
                          isValid
                      }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate
                                  className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i
                                                className="mdi mdi-account-key"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedDriver')}
                                            </h3>
                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={e => {
                                                        const item = new Driver().deserialize(deepClone(this.state.item))
                                                        item.name = e.target.value

                                                        this.setState({
                                                            item,
                                                            changed: true
                                                        })
                                                    }
                                                    }
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <FormLabel>{t("deviceIdentifier")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="uniqueId"
                                                    defaultValue={values.uniqueId}
                                                    onChange={handleChange}
                                                    disabled={this.state.item.id}
                                                    isInvalid={!!errors.uniqueId}
                                                />
                                                {this.state.item.id? null :
                                                    <Button variant="outline-primary" onClick={() => {
                                                        this.generateId()
                                                    }}>
                                                        {t('generate')}
                                                    </Button>
                                                }
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {
                                                    App.App.user.administrator ?
                                                        <Button variant="primary" className="align-self-stretch"
                                                                onClick={() => this.openAttributesPage()}>
                                                            <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                        </Button> : null
                                                }
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red mr-2" onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" size="large" className="btn-blue"
                                                    disabled={!this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}