import { Group } from "../../models";
import { t } from "../../utils/Translator";

import createGenericStore from "../generic_store";

const store = createGenericStore('groups', {
    pk: 'id',
    reducers: {
        'groups/LOADED_ALL': (state, action) => {
            const groups = action.payload.items.reduce((map, obj) => (map[obj.pk] = obj, map), {});
            groups[0] = new Group().deserialize({ id: 0, name: t('groupNoGroup') });
            state.all_groups = groups;
        },
        'groups/LOADED': (state, action) => {
            const groups = action.payload.items.reduce((map, obj) => (map[obj.pk] = obj, map), {});
            groups[0] = new Group().deserialize({ id: 0, name: t('groupNoGroup') });
            state.groups = groups;
        },
        'groups/ADDED': (state, action) => {
            state[`groups`][action.payload.item['id']] = action.payload.item;
            state[`all_groups`][action.payload.item['id']] = action.payload.item;
        },
        'groups/UPDATED': (state, action) => {
            state['groups'][action.payload.item['id']] = action.payload.item;
            state['all_groups'][action.payload.item['id']] = action.payload.item;
        },
        'groups/ADDED': (state, action) => {
            state['groups'][action.payload.item['id']] = action.payload.item;
            state['all_groups'][action.payload.item['id']] = action.payload.item;
        },
        'groups/DELETED': (state, action) => {

            delete state['groups'][action.payload['id']];
            delete state['all_groups'][action.payload['id']];
        }
    }
});

export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}