import { BaseModel } from "./BaseModel";
import { t } from "../utils/Translator";
import { AppService } from "../services";
import { store } from "../store";
import { AttributeFormatter } from "../utils/traccar/AttributeFormatter";
export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'type',
    type: 'string'
}, {
    name: 'serverTime',
    type: 'date'
}, {
    name: 'deviceId',
    type: 'int'
}, {
    name: 'positionId',
    type: 'int'
}, {
    name: 'geofenceId',
    type: 'int'
}, {
    name: 'maintenanceId',
    type: 'int'
}, {
    name: 'text',
    convert: (v, rec) => {
        let text, alarmKey;
        if (rec.type === 'commandResult') {
            text = t('eventCommandResult') + ': ' + rec.attributes['result'];
        } else if (rec.type === 'alarm') {
            alarmKey = rec.attributes['alarm'];
            alarmKey = 'alarm' + alarmKey.charAt(0).toUpperCase() + alarmKey.slice(1);
            text = t(alarmKey);
        } else if (rec.type === 'textMessage') {
            text = t('eventTextMessage') + ': ' + rec.attributes['message'];
        } else if (rec.type === 'driverChanged') {
            text = t('eventDriverChanged') + ': ' +
                AttributeFormatter.driverUniqueIdFormatter(rec.attributes['driverUniqueId']);
        } else {
            text = AppService.getEventString(rec.type);
        }
        if (rec.geofenceId) {
            const geofence = store.getState().geofences.geofences[rec.geofenceId];
            if (geofence) {
                text += ' "' + geofence.name + '"';
            }
        }
        if (rec.maintenanceId) {
            const maintenance = store.getState().maintenances.maintenances[rec.maintenanceId];
            if (maintenance) {
                text += ' "' + maintenance.name + '"';
            }
        }
        return text;
    }
}, {
    name: 'attributes'
}, {
    name: 'displayType',
    convert: (v, rec) => {
        if (rec.type === 'alarm') {
            return 'error';
        }
        if (['deviceOnline'].includes(rec.type)) {
            return 'info'
        }

        if (['deviceUnknown'].includes(rec.type)) {
            return 'warning'
        }

        if (['deviceOffline'].includes(rec.type)) {
            return 'error'
        }
        return 'info';
    }
}, {
    name: 'cssClass',
    convert: (v, rec) => {
        // eslint-disable-next-line default-case
        switch (rec.displayType) {
            case 'error':
                return 'danger';
            case 'info':
                return 'info';
            case 'warning':
                return 'warning';
            case 'success':
                return 'success'
        }
        return 'info';

    }
}];
export class Event extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}