import { KnownNotification } from "../models";
import ApiStoreService from "./ApiStoreService";
import notification_types from "../store/notification_types";

export class NotificationTypesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'notifications/types';
        this.storeName = notification_types;
    }

    

    transformSingleResponse(data) {
        return new KnownNotification().deserialize(data);
    }


}

export default new NotificationTypesService();