import { createReducer } from "redux-starter-kit";
import { Event } from "../../models";

export const types = {
    LOADED: 'events/LOADED',
    ADD_EVENT: 'events/ADD',
    REMOVE_EVENT: 'events/REMOVE',
    RESET: 'events/RESET',
    CLEAR: 'events/CLEAR',
    FILTER_BY_NAME: 'events/FILTER_BY_NAME',
}


export const initialState = {
    events: {},
    loaded: false,
    currentFilterByName: ''

}

export const actions = {
    loaded(events) {
        return {
            type: types.LOADED,
            payload: { events }
        }
    },
    add(event) {
        return {
            type: types.ADD_EVENT,
            payload: { event }
        }
    },
    remove(id) {
        return {
            type: types.REMOVE_EVENT,
            payload: { id }
        }
    },
    reset() {
        return {
            type: types.RESET
        }
    },
    clear() {
        return {
            type: types.CLEAR
        }
    },
    filterByName(filter) {
        return {
            type: types.FILTER_BY_NAME,
            payload: filter
        }
    }
}

const reducers = createReducer(initialState, {
    [types.RESET]: (state, action) => {
        state = { ...initialState }
    },
    [types.LOADED]: (state, action) => {
        const events = action.payload.events.reduce((map, obj) => (map[obj.pk] = obj, map), {});
        state.events = events;
    },
    [types.ADD_EVENT]: (state, action) => {
        state.events[action.payload.event.id] = new Event().deserialize(action.payload.event)
    },
    [types.REMOVE_EVENT]: (state, action) => {
        delete state.events[action.payload.id];
    },
    [types.CLEAR]: (state, payload) => {
        state.events = {}
    },
    [types.FILTER_BY_NAME]: (state, action) => {
        if (state.currentFilterByName != action.payload) {
            state.currentFilterByName = action.payload;
        }
    }
})


export default {
    types,
    reducers,
    actions,
    state: initialState
}