import BaseService from './ApiService';
import { Server } from '../models';
import server from '../store/server';
import { store } from '../store'

export class ServerService extends BaseService {
    constructor() {
        super();
        this.baseUrl = '/server';
    }

    async info() {
        const { data } = await this.api.get(this.baseUrl);
        const info = this.transformSingleResponse(data);
        store.dispatch({ type: server.types.LOADED, payload: { server: info } });
        return info;
    }

    async update(model) {
        const { data } = await this.api.put(this.baseUrl, model.serialize());
        return this.transformSingleResponse(data);
    }

    transformSingleResponse(data) {
        return Server.deserialize(data);
    }
}
export default new ServerService();