import createGenericStore from "../generic_store";
import { t } from "../../utils/Translator";
import { KnownAttribute } from "../../models/KnownAttribute";
const data = [{
    key: 'web.reportColor',
    name: t('attributeWebReportColor'),
    valueType: 'color'
}, {
    key: 'devicePassword',
    name: t('attributeDevicePassword'),
    valueType: 'string'
}, {
    key: 'processing.copyAttributes',
    name: t('attributeProcessingCopyAttributes'),
    valueType: 'string'
}, {
    key: 'decoder.timezone',
    name: t('sharedTimezone'),
    valueType: 'string',
    dataType: 'timezone'
}]

const attrs = {};
Object.values(data).map(attr => {
    attrs[attr.key] = new KnownAttribute().deserialize(attr);
})
const store = createGenericStore('device_attributes', {
    autoLoad: true,
    initialState: {
        device_attributes: attrs
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}