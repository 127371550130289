import React from 'react';
//import OLMainMap from '../../utils/maps/openlayers/OLMainMap';
import { store } from '../../store';
import LeafletMainMap from '../../utils/maps/leaflet/LeafletMainMap';
import events from '../../utils/Events';
import positions from '../../store/positions';
import app from '../../store/app';
import devices from '../../store/devices';
import geofences from '../../store/geofences';

class MainMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.mapContainerRef = React.createRef();
        this.map = null;
        this.selectedDeviceId = null;


        //bind methods
        this.onPositionsLoaded = this.onPositionsLoaded.bind(this);
        this.switchMap = this.switchMap.bind(this);
        this.onOptionsChanged = this.onOptionsChanged.bind(this);
        this.zoomToAll = this.zoomToAll.bind(this);
        this.zoomIn = this.zoomIn.bind(this);
        this.zoomOut = this.zoomOut.bind(this);
        this.onDeviceUpdate = this.onDeviceUpdate.bind(this);
        this.onDeviceSelected = this.onDeviceSelected.bind(this);
        this.onDeviceUnselected = this.onDeviceUnselected.bind(this);
        this.onConnectionChanged = this.onConnectionChanged.bind(this);
        this.updateGeofences = this.updateGeofences.bind(this);
    }

    componentDidMount() {
        this.initListeners();
        this.initMap();
    }

    componentWillUnmount() {
        this.clearListeners();
        this.map = null;
    }

    initMap() {
        if (this.map) {
            this.map.destroy();
            this.map = null;
        }
        this.map = new LeafletMainMap();
        this.map.onMapClick = (e) => {
            if (this.selectedDeviceId) {
                store.dispatch(devices.actions.unselectDevice())
                events.emit(devices.types.DEVICE_UNSELECTED)
            }

        }

        this.map.onDeviceSelected = (id) => {
            store.dispatch(devices.actions.setSelectedDevice(id))
            events.emit(devices.types.DEVICE_SELECTED, id)
        };

        this.map.onStateChanged = (config) => {
            store.dispatch(app.actions.mapStateChanged(config))
        }


        this.map.init(this.mapContainerRef.current, this.props.mapConfig)


        this.updateMarkers(Object.values(store.getState().devices.devices));
        this.updateGeofences();
    }

    switchMap() {
        this.initMap();
    }


    updateMarkers(devices) {
        this.map.updatePositions(devices);
    }

    onPositionsLoaded() {
        this.map.clearMarkers();
        this.map.clearRoutes();
        this.updateMarkers(Object.values(store.getState().devices.devices));
    }

    onConnectionChanged(connected) {
        if (connected === 'online') {
            //this.onPositionsLoaded();
        }
    }

    updateGeofences() {
        this.map.updateGeofences(Object.values(store.getState().geofences.geofences));
    }

    onOptionsChanged(value) {
        if (value.cluster_enabled) {
            this.map.enableCluster();
        } else {
            this.map.disableCluster();
        }
        if (value.show_geofences) {
            this.map.showGeofences();
        } else {
            this.map.hideGeofences();
        }
        if (value.show_routes) {
            this.map.showRoutes();
        } else {
            this.map.hideRoutes();
        }
        if (value.show_names) {
            this.map.showObjectNames();
        } else {
            this.map.hideObjectNames();
        }

        if (value.follow_devices) {
            this.map.followDevices();
        } else {
            this.map.unfollowDevices();
        }

        this.config = value;
    }

    zoomToAll() {
        this.map.zoomToAll();
    }

    zoomIn(){
        this.map.zoomIn();
    }

    zoomOut(){
        this.map.zoomOut();
    }

    onDeviceUpdate(device) {
        this.updateMarkers([device]);
    }

    onDeviceSelected(id) {
        const marker = this.map.getMarker(id);
        if (marker) {
            this.selectedDeviceId = id;
            const device = store.getState().devices.devices[id];
            this.map.selectMarker(device);
        }
    }

    onDeviceUnselected() {
        const marker = this.map.getMarker(this.selectedDeviceId);
        if (marker) {
            const device = store.getState().devices.devices[this.selectedDeviceId];
            this.map.unselectMarker(device);
        }
        this.selectedDeviceId = null;
    }

    clearListeners() {
        events.off(positions.types.LOADED, this.onPositionsLoaded)
        events.off(app.types.MAP_CONFIG, this.switchMap)
        events.off(app.types.MAP_OPTIONS_CHANGE, this.onOptionsChanged)
        events.off('MainMap/ZOOM_TO_ALL', this.zoomToAll);
        events.off('MainMap/ZOOM_IN', this.zoomIn);
        events.off('MainMap/ZOOM_OUT', this.zoomOut);
        events.off(devices.types.UPDATED, this.onDeviceUpdate)
        events.off(devices.types.DEVICE_SELECTED, this.onDeviceSelected)
        events.off(devices.types.DEVICE_UNSELECTED, this.onDeviceUnselected)
        events.off(app.types.CONNECTION_CHANGED, this.onConnectionChanged)
        events.off(geofences.types.UPDATED, this.updateGeofences);
        events.off(geofences.types.DELETED, this.updateGeofences);
    }

    initListeners() {
        events.on(positions.types.LOADED, this.onPositionsLoaded)
        events.on(app.types.MAP_CONFIG, this.switchMap)
        events.on(app.types.CONNECTION_CHANGED, this.onConnectionChanged)
        events.on(app.types.MAP_OPTIONS_CHANGE, this.onOptionsChanged)
        events.on('MainMap/ZOOM_TO_ALL', this.zoomToAll);
        events.on('MainMap/ZOOM_IN', this.zoomIn);
        events.on('MainMap/ZOOM_OUT', this.zoomOut);
        events.on(devices.types.UPDATED, this.onDeviceUpdate)
        events.on(devices.types.DEVICE_SELECTED, this.onDeviceSelected)
        events.on(devices.types.DEVICE_UNSELECTED, this.onDeviceUnselected)
        events.on(geofences.types.UPDATED, this.updateGeofences);
        events.on(geofences.types.DELETED, this.updateGeofences);
    }

    render() {
        return (
            <React.Fragment>
                <div ref={this.mapContainerRef}></div>
                {this.props.children}
            </React.Fragment>
        );
    }
}


export default MainMap;