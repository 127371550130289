import {ReportsService} from "../services";

export default class EcoScoreUtils {

    static async addEcoScore(model, complete, filter) {
        const defaultComplete = {
            acceleration: "-",
            braking: "-",
            cornering: "-",
            total: "-"
        }
        const defaultSingle = "-"

        try {
            const data = await ReportsService.getReport('ecoscores', filter);
            const current = data.data[Array.isArray(filter.deviceId) ? filter.deviceId[0] : filter.deviceId][0]

            if (current.metadata.fallback) {
                if (complete) {
                    model.ecoScore = defaultComplete
                    return;
                }

                model.ecoScore = defaultSingle
                return
            }

            if (complete) {
                model.ecoScore = current
                return;
            }

            model.ecoScore = current.total
        } catch (e) {
            console.log(e)
            if (complete) {
                model.ecoScore = defaultComplete
                return;
            }

            model.ecoScore = defaultSingle
        }
    }

    static format(ecoScore) {
        if (ecoScore.metadata.fallback) {
            ecoScore.acceleration = "-";
            ecoScore.braking = "-";
            ecoScore.cornering = "-";
            ecoScore.total = "-";
        }
        return ecoScore
    }

    static async addIdle(model, filter) {
        try {
            const data = await ReportsService.getReport('idling', filter);
            Object.keys(data.data).forEach(key => {
                model.idleTime = data.data[key].duration
            })
        } catch (e) {
            model.idleTime = 0
        }
    }

}