import { BaseModel } from "./BaseModel";
import { AppService } from "../services";

export const fields = [{
    name: 'type',
    type: 'string',
    isPk: true
}, {
    name: 'name',
    convert: (v, rec) => {
        return AppService.getNotificatorString(rec.type)
    }
}]

export class KnownNotificator extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}