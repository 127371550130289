import React from 'react';
import { connect } from 'react-redux';
import { actions } from '../../../store/session';
import { Formik } from 'formik';
import * as yup from 'yup';
import { FormControl, Button, Card, Form, Alert, FormGroup, Container, Row, Col, Dropdown } from 'react-bootstrap';
import { SessionService, AppService } from '../../../services';
import { t } from '../../../utils/Translator';
import languages from '../../../config/languages';
import { Server } from '../../../models';
import { Link } from 'react-router-dom';
import { truncate } from 'lodash';


export class RegisterPage extends React.Component {
    constructor(...args) {
        super(...args);

        this.state = { error: '', sending: false, lang: AppService.lang['name'] };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeLang = this.changeLang.bind(this);
    }

    async handleSubmit(values) {
        try {
            try {
                this.setState({ sending: true, error: '' })
                await SessionService.register(values)
                await AppService.showSuccess(t('loginCreated'))
                this.props.history.replace('/');
            } catch (ex) {
                this.setState({ error: ex.response ? ex.response.data : ex })
                this.setState({ sending: false })
            } finally {

            }
        } catch (e) {

        }

    }

    componentWillMount() {
        if (!Server.registration) {
            this.props.history.replace('/')
        }

    }

    componentWillUpdate() {
    }

    changeLang(lang) {
        const params = new URLSearchParams(window.location.search);
        params.set('lang', lang);
        window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
        window.location.reload();
    }


    render() {
        const { sending, error, lang } = this.state;
        const allow_registration = Server.registration;

        const schema = yup.object({
            name: yup.string().min(4).required(),
            email: yup.string().email().required(),
            password: yup.string().min(4).required(),
            
        });
        return (
            <div className="page" id="pgLogin">
                <Container className="h-100">
                    <Row className="h-100 d-flex justify-content-center align-items-center">
                        <Col className="login-form m-md-0">
                            <Formik
                                validationSchema={schema}
                                onSubmit={(e) => this.handleSubmit(e)}
                                disabled={sending}
                                initialValues={{

                                }}
                            >
                                {({
                                    handleSubmit,
                                    handleChange,
                                    errors,
                                }) => (
                                        <Form noValidate onSubmit={handleSubmit} className="form form-horizontal ">
                                            <Card className="border-0 shadow-lg">
                                                <Card.Header className="bg-dark text-white text-center">
                                                    <h1 className="h3 m-0"><img src="img/logo-light.svg" alt="IQ track" height="50" /></h1>
                                                </Card.Header>
                                                <Card.Body className="pb-4">
                                                    <h2 className="text-center mb-3">{t('loginRegister')}</h2>

                                                    {error ? <Alert variant="danger"> {error}</Alert> : ''}

                                                    <FormGroup className="mb-3">
                                                        {/*<FormLabel>{intl.formatMessage({ id: 'userEmail' })}</FormLabel> */}
                                                        <FormControl
                                                            placeholder={t('sharedName')}
                                                            type="text"
                                                            aria-label="Username"
                                                            autoComplete="current-user"
                                                            required
                                                            name="name"
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.name}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.name}
                                                        </Form.Control.Feedback>
                                                    </FormGroup>


                                                    <FormGroup className="mb-3">
                                                        {/*<FormLabel>{intl.formatMessage({ id: 'userEmail' })}</FormLabel> */}
                                                        <FormControl
                                                            placeholder={t('userEmail')}
                                                            type="email"
                                                            aria-label="Email"
                                                            autoComplete="current-email"
                                                            required
                                                            name="email"
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.email}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.email}
                                                        </Form.Control.Feedback>
                                                    </FormGroup>
                                                    <FormGroup>
                                                        {/* <FormLabel>{intl.formatMessage({ id: 'userPassword' })}</FormLabel>*/}
                                                        <FormControl
                                                            placeholder={t('userPassword')}
                                                            aria-label="Password"
                                                            type="password"
                                                            autoComplete="current-password"
                                                            required
                                                            name="password"
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.password}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <FormControl
                                                            placeholder={t('IMEI')}
                                                            aria-label="IMEI"
                                                            type="id"
                                                            
                                                            required
                                                            name="id"
                                                            onChange={handleChange}
                                                            isInvalid={!!errors.id}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {errors.password}
                                                        </Form.Control.Feedback>
                                                    </FormGroup>


                                                    <div className="">
                                                        <Button type="submit" variant="dark" className="btn-block" disabled={sending}>{sending ? t('sharedLoading') : t('loginRegister')}</Button>
                                                    </div>
                                                    {allow_registration ?
                                                        <div className="mt-2">
                                                            <Link to="/login" className="btn btn-outline btn-block">{t('loginLogin')}</Link>
                                                        </div> : null}
                                                </Card.Body>
                                            </Card>
                                        </Form>

                                    )}
                            </Formik>
                        </Col>
                    </Row>
                </Container >
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    loading: state.session.loading,
    error: state.session.error,
    user: state.session.user,
})

const mapDispatchToProps = dispatch => ({
    dispatch,
    login: credentials => {
        dispatch(actions.login(credentials));
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)