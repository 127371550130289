import React from 'react';
import PropTypes from 'prop-types';
import { ListGroup } from 'react-bootstrap';
import DeviceItem from './listItem';
import { store } from '../../store';
import devices from '../../store/devices';
import events from '../../utils/Events';
import { AppService } from '../../services';
import Events from '../../utils/Events';
import { connect } from 'react-redux';

class DeviceList extends React.Component {
    constructor(props) {
        super();
        this.force = false;
        this.updateInterval = null;
        this.visible = false;
        this.rerender = this.rerender.bind(this);
    }
    selectDevice(id) {
        if (!this.props.selectDeviceOnClick) {
            return;
        }

        if (this.props.selectedId == id) {
            return;
        }

        if (AppService.isMobile) {
            events.emit('CLOSE_SIDEBAR');
        }

        store.dispatch(devices.actions.setSelectedDevice(id))
        events.emit(devices.types.DEVICE_SELECTED, id);

    }


    //CHANGED TO PURE COMPONENT
    /*shouldComponentUpdate(nextProps, nextState) {
        if ((nextProps.force || this.force)) {
            this.force = false;
            this.createInterval();
            return true;
        }
        this.hasChanges = true;
        return false;
    }*/

    createInterval() {
        try {
            clearInterval(this.updateInterval);
        } catch (ex) { }
        //only update every 5 seconds
        this.updateInterval = setInterval(async () => {
            if (this.hasChanges) {
                this.rerender();
            }
        }, 5000)
    }

    rerender() {
        this.forceUpdate();
    }

    componentDidMount() {
        this.createInterval();
        /*Events.on(devices.types.DEVICE_SELECTED, this.rerender)
        Events.on(devices.types.DEVICE_UNSELECTED, this.rerender)
        Events.on(devices.types.FILTER_BY_NAME, this.rerender)*/
    }

    componentWillUnmount() {
        try {
            clearInterval(this.updateInterval);
            Events.off(devices.types.DEVICE_SELECTED, this.rerender)
            Events.off(devices.types.DEVICE_UNSELECTED, this.rerender)
            Events.off(devices.types.FILTER_BY_NAME, this.rerender)
        } catch (ex) { }
    }

    render() {
        const { devices } = this.props;
        return (
            <ListGroup className="border-0 rounded-0 device-list t01">
                {devices.map(d => {
                    return (
                        <ListGroup.Item key={d} onClick={() => this.selectDevice(d)} className="list-item">
                            <ConnectedItem id={d}  />
                        </ListGroup.Item>
                    );
                })}
            </ListGroup>
        )
    }
}

const ConnectedItem = connect((state, props) => ({
    device: state.devices.devices[props.id],
    position: state.positions.positions[props.id],
    active: !state.devices.devices[props.id].hidden,
    selected: state.devices.devices[props.id].selected,
}))(DeviceItem);

DeviceList.propTypes = {
    devices: PropTypes.array.isRequired,
    //activeDevices: PropTypes.object.isRequired,
    selectDeviceOnClick: PropTypes.bool,
    selectedId: PropTypes.number,
    visible: PropTypes.bool
};


DeviceList.defaultProps = {
    selectDeviceOnClick: true,
    selectedId: null,
    //visible: true
}

export default DeviceList;