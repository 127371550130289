import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router'

//reducers
import session from './session';
import locale from './locale';
import server from './server';
import devices from './devices';
import device_attributes from './devices/attributes';
import positions from './positions';
import position_attributes from './positions/attributes';
import app from './app';
import speed_units from './app/speed_units';
import distance_units from './app/distance_units';
import coordinate_formats from './app/coordinate_formats';
import hours_units from './app/hours_units';
import volume_units from './app/volume_units';
import geofences from './geofences';
import groups from './groups';
import events from './events';
import maintenances from './maintenances';
import maintenance_types from './maintenances/types';
import drivers from './drivers';
import calendars from './calendars';
import computed_attributes from './computed_attributes';
import command_types from './command_types';
import commands from './commands';
import notificators from './notificators';
import user_attributes from './users/attributes';
import common_user_attributes from './app/common_user_attributes';
import common_device_attributes from './app/common_device_attributes';
import timezones from './app/timezones';
import known_commands from './app/known_commands';
import notifications from './notifications';
import notification_types from './notification_types';
import alarm_types from './app/alarm_types';
import geofence_attributes from './app/geofence_attributes';
import users from './users';
import expenses from './expenses'
import time_units from './app/time_units';
import report_types from './reports/types';


export const history = createBrowserHistory()


const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    session: session.reducers,
    locale: locale.reducers,
    server: server.reducers,
    devices: devices.reducers,
    groups: groups.reducers,
    geofences: geofences.reducers,
    drivers: drivers.reducers,
    maintenances: maintenances.reducers,
    maintenance_types: maintenance_types.reducers,
    positions: positions.reducers,
    position_attributes: position_attributes.reducers,
    events: events.reducers,
    app: app.reducers,
    hours_units: hours_units.reducers,
    volume_units: volume_units.reducers,
    speed_units: speed_units.reducers,
    distance_units: distance_units.reducers,
    coordinate_formats: coordinate_formats.reducers,
    calendars: calendars.reducers,
    computed_attributes: computed_attributes.reducers,
    command_types: command_types.reducers,
    commands: commands.reducers,
    notificators: notificators.reducers,
    user_attributes: user_attributes.reducers,
    common_user_attributes: common_user_attributes.reducers,
    common_device_attributes: common_device_attributes.reducers,
    timezones: timezones.reducers,
    known_commands: known_commands.reducers,
    notifications: notifications.reducers,
    notification_types: notification_types.reducers,
    alarm_types: alarm_types.reducers,
    geofence_attributes: geofence_attributes.reducers,
    device_attributes: device_attributes.reducers,
    users: users.reducers,
    time_units: time_units.reducers,
    report_types:report_types.reducers,
    expenses: expenses.reducers
});

export const store = createStore(
    rootReducer(history), // root reducer with router state
    {},
    compose(
        applyMiddleware(
            routerMiddleware(history), // for dispatching history actions
            thunk,
            // ... other middlewares ...
        ),
    ),
)
