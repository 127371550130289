import React from "react";
import { connect } from "react-redux";
import {Button} from "@mui/material"
import { t } from "../../../utils/Translator";
import { AppService, NotificationsService } from "../../../services";
import Page from "../../Page";
import NotificationForm from "./form";
import { Notification } from "../../../models";
import List from "./list";

class NotificationListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      item: null,
      new_count: 0
    };

    this.hideForm = this.hideForm.bind(this);
    this.showForm = this.showForm.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.onSave = this.onSave.bind(this);
    this.new_notification = this.new_notification.bind(this);
  }

  async deleteItem(id) {
    if (await AppService.showDeleteConfirm()) {
      NotificationsService.destroy(id);
    }
  }

  showForm(id) {
    this.setState({
      showForm: true,
      item: id
        ? new Notification().deserialize(this.props.items[id])
        : new Notification()
    });
  }

  async onSave(item) {
    try {
      if (item.id > 0) {
        await NotificationsService.update(item);
      } else {
        await NotificationsService.save(item);
      }
      this.hideForm();
    } catch (ex) {
      AppService.showError(ex);
    }
  }

  hideForm() {
    this.setState({
      showForm: false,
      item: null
    });
  }

  setFilter(value) {
    if (value !== this.state.filter) {
      this.setState({ filter: value });
    }
  }

  filterValues(values) {
    if (this.state.filter && this.state.filter !== "") {
      return Object.values(values).filter(o =>
        t(o.eventString)
          .toLowerCase()
          .includes(this.state.filter.toLowerCase())
      );
    }
    return Object.values(values);
  }
  new_notification() {
    this.setState({ new_count: this.state.new_count - 1 });
  }

  render() {
    const { items, onHide } = this.props;
    const values = this.filterValues(items);
    values.sort((a, b) =>
      a.eventString.toLowerCase() > b.eventString.toLowerCase() ? 1 : -1
    );
    return (
      <React.Fragment>
        {this.state.showForm ? (
          <Page
            onHide={this.hideForm}
            title={
              <React.Fragment>
                <h3 className="title">
                  <i className="mdi mdi-bell-ring"></i>{" "}
                  {this.state.item.id ? t("sharedEdit") : t("sharedAdd")}{" "}
                  {t("sharedNotification")}
                </h3>
              </React.Fragment>
            }
          >
            <NotificationForm
              item={this.state.item}
              onSave={this.onSave}
              new_notification={this.new_notification}
              new_count={this.state.new_count}
              onHide={this.hideForm}
            ></NotificationForm>
          </Page>
        ) : null}

        <div className="container-wrapper grow">
          <div className="d-flex flex-column h-100 w-100 flex-grow-1 form form-sm ">
            <div className="flex-grow-1 overflow">
              <div className="container p-0">
                <List
                  items={this.props.items}
                  onDelete={this.deleteItem}
                  onSelect={this.showForm}
                  enableDelete={true}
                  enableSelection={false}
                />
              </div>
            </div>
            <footer className="footer">
              <div className="container d-flex">
                <div className="flex-grow-1 d-flex">
                  <Button
                      variant="contained" size="large"
                    className="align-self-stretch btn-blue"
                    onClick={() => this.showForm("")}
                  >
                    <i className="mdi mdi-plus-circle-outline"></i>{" "}
                    {t("sharedAdd")}
                  </Button>
                </div>
                <Button variant="contained" size="large" className="btn-red" onClick={onHide}>
                  <i className="mdi mdi-close"></i> {t("sharedCancel")}
                </Button>
              </div>
            </footer>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  items: state.notifications.notifications
});

export default connect(mapStateToProps, null)(NotificationListPage);
