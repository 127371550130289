import { t } from "../Translator";
import { store } from "../../store";

class PositionAttributes {
    getAttributeName(key, capitalize) {
        let model = store.getState().position_attributes.position_attributes[key];
        if (model) {
            return t(model.name);
        } else if (capitalize) {
            return key.replace(/^./, (match) => {
                return match.toUpperCase();
            });
        } else {
            return key;
        }
    }

    getAttributeDataType(key) {
        let model = store.getState().position_attributes.position_attributes[key];
        if (model && model.dataType) {
            return model.dataType;
        } else {
            return null;
        }
    }
}

export default new PositionAttributes();