import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap'
import Interval from '../Interval';
import { store } from '../../store';
import events from '../../store/events';
import devices from '../../store/devices';
import Events from '../../utils/Events';
import { AppService } from '../../services';
import { App } from '../../App';

class EventItem extends React.Component {
    constructor(props) {
        super(props);
        this.deleteEvent = this.deleteEvent.bind(this);
        this.selectEvent = this.selectEvent.bind(this);
    }


    deleteEvent(id) {
        store.dispatch(events.actions.remove(id));
    }

    selectEvent(event) {
        store.dispatch(devices.actions.setSelectedDevice(event.deviceId));
        Events.emit(devices.types.DEVICE_SELECTED, event.deviceId);
        if (AppService.isMobile) {
            Events.emit('CLOSE_SIDEBAR');
        }
    }

    render() {
        const { event } = this.props;
        const formatter = App.AttributeFormatter.getFormatter('lastUpdate');
        return (
            <Alert variant={event.cssClass} dismissible onClose={() => this.deleteEvent(event.id)}>
                <div onClick={() => this.selectEvent(event)} >
                    <Alert.Heading>{event.title}</Alert.Heading>
                    <span>{event.text}</span><br />
                    <small>{App.AttributeFormatter.getFormatter('date')(event.serverTime)} | <Interval delay="60000">{formatter(event.lastUpdate)}</Interval></small>
                </div>
            </Alert>
        )
    }
}

EventItem.propTypes = {
    event: PropTypes.object.isRequired
};

export default EventItem;