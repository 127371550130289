import React from 'react';

export default class Page extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            closed: false,
        }
        this.close = this.close.bind(this);
    }

    close() {
        this.props.onHide();
    }

    render() {
        const { children, title, onHide, ...rest } = this.props;
        return (
            <div {...rest} className={"page-component animated bounceInRight " + (this.props.open && !this.state.closed ? 'open' : '')}>
                <div className="wrapper">
                    <div className="header">
                        {/*{title || null}*/}
                        <button className="close" onClick={this.close}><i className="mdi mdi-close"></i></button>
                    </div>
                    {this.props.toolbar ? <div className="page-toolbar">{this.props.toolbar}</div> : null}
                    <div className="body">
                        {children ? children : null}
                    </div>
                </div>
            </div>
        )
    }
}