import createGenericStore from "../generic_store";
import { t } from '../../utils/Translator';
import { KnownAttribute } from "../../models/KnownAttribute";

const data = [{
    key: 'speedLimit',
    name: t('attributeSpeedLimit'),
    valueType: 'number',
    dataType: 'speed'
}, {
    key: 'report.ignoreOdometer',
    name: t('attributeReportIgnoreOdometer'),
    valueType: 'boolean'
}]

const attrs = {};
Object.values(data).map(attr => {
    attrs[attr.key] = new KnownAttribute().deserialize(attr);
})

const store = createGenericStore('common_device_attributes', {
    autoLoad: true,
    initialState: {
        common_device_attributes: attrs
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}