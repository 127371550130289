import createGenericStore from "../generic_store";
import { t } from "../../utils/Translator";
const data = {
    s: {
        key: 's',
        name: t('sharedSecondAbbreviation'),
        factor: 1
    }, m: {
        key: 'm',
        name: t('sharedMinuteAbbreviation'),
        factor: 60
    }, h: {
        key: 'h',
        name: t('sharedHourAbbreviation'),
        factor: 3600
    }
};

const store = createGenericStore('time_units', {
    autoLoad: true,
    initialState: {
        time_units: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}