import React from 'react';
import Select from 'react-select';
import {AppService} from '../../services';

class CustomSelect extends React.Component {
    constructor(props) {
        super(props);
        this.el = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.parseDefaultValue = this.parseDefaultValue.bind(this);
    }


    handleChange(e) {
        let value = e ? e.value : null;
        if (e) {
            if (this.props.isMulti) {
                value = [];
                e.map(v => {
                    value.push(v.value);
                })
                value = value.join(',');
            }
        }
        this.props.onChange(this.props.name, value);
    }

    parseDefaultValue() {
        let value = null;
        const options = this.props.options || [];
        const defaultValue = this.props.defaultValue || null;
        if (defaultValue) {
            if (this.props.isMulti) {
                const selected = [];
                defaultValue.split(',').map(v => {
                    selected.push(options.find(o => String(o.value).valueOf().toLowerCase() === String(v).valueOf().toLowerCase()))
                })
                value = selected;
            } else {
                value = options.find(o =>String(o.value).valueOf().toLowerCase() === String(defaultValue).valueOf().toLowerCase())
                if (value === null) value = []
            }
        }
        return value;
    }

    render() {
        const def = this.parseDefaultValue();
        return (
            <React.Fragment>
                <div>
                    <div className="custom-select-wrapper" ref={this.el}>
                        { !this.props.valueEnabled ?
                            <Select menuPosition={!AppService.isMobile ? 'fixed' : 'absolute'}
                                    {...this.props}
                                    defaultValue={def}
                                    className={"custom-select-component " + this.props.className}
                                    classNamePrefix="custom-select" onChange={this.handleChange}/>
                            :
                            <Select menuPosition={!AppService.isMobile ? 'fixed' : 'absolute'}
                                    {...this.props}
                                    defaultValue={def}
                                    value={def}
                                    className={"custom-select-component " + this.props.className}
                                    classNamePrefix="custom-select" onChange={this.handleChange}/>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default CustomSelect;