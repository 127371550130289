import React from 'react';
import { FormGroup, InputGroup, FormControl } from 'react-bootstrap';
import { t } from '../../utils/Translator';

export default class TextFilter extends React.Component {
    render() {
        return (
            <div className={'filter ' + (this.props.wrapperClass?this.props.wrapperClass:'')}>
                <FormGroup>
                    <InputGroup>
                        <FormControl placeholder={t('sharedSearch')} onKeyUp={(e) => this.props.onFilter(e.target.value)} />
                    </InputGroup>
                </FormGroup>
            </div>
        )
    }
}