import DeleteIcon from "@mui/icons-material/Delete";
import Pencil from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { MDBDataTable } from "mdbreact";
import React from 'react';
import { App } from "../../../App";
import { User } from "../../../models";
import DevicesService from "../../../services/DevicesService";
import UsersService from "../../../services/UsersService";
import { deepClone } from "../../../utils/functions";
import { t } from '../../../utils/Translator';
import CustomCheckbox from "../../Controls/CustomCheckBox";
import TextFilter from "../../Controls/TextFilter";


export default class List extends React.Component {

    static deleteEvent = false

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            selectedItems: props.selectedItems || [],
            allSelected: false,
            items: props.items || undefined,
        }
        this.deleteItem = this.deleteItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unselectAll = this.unselectAll.bind(this);

        this.columns = [
            {
                label: t("sharedName"),
                field: "name",
                sort: "enabled",
            },
            {
                label: t("userEmail"),
                field: "email",
                sort: "enabled",
            },
            {
                label: t("userAdmin"),
                field: "administration",
                sort: "enabled",
            },
            {
                label: t("deviceTitle"),
                field: "devices",
                sort: "enabled",
            },
            {
                label: t("userDeviceLimit"),
                field: "deviceLimit",
                sort: "enabled",
            },
            {
                label: t("userUserLimit"),
                field: "userLimit",
                sort: "enabled",
            },
        ]

        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;

        if (enableDelete)
            this.columns.push({
                label: t("actions"),
                field: "sharedRemove",
                sort: "disabled",
            })

        const enableSelection = this.props.enableSelection !== undefined ? this.props.enableSelection : false;

        if (enableSelection)
            this.columns.splice(0, 0, {
                label: "",
                field: "checkBox",
                sort: "disabled",
            })

    }

    componentDidMount() {
        const enableSelection = this.props.enableSelection != undefined ? this.props.enableSelection : false;
        console.log("this.props.userId", this.props.userId)
        if (this.props.userId && enableSelection && App.App.user.administrator) {
            const userId = this.props.userId;
            UsersService.userItems(userId).then(users => {
                let userUsers = []
                users.forEach(d => {
                    userUsers.push(d)
                })
                const selectedItems = []
                if (userUsers.length === 0) userUsers = null
                else {
                    if (this.state.items !== null && this.state.items !== undefined
                        && typeof this.state.items[Symbol.iterator] === 'function') {
                        userUsers.forEach(user => {
                            for (let allUser of this.state.items) {
                                if (user.id === allUser.id) {
                                    selectedItems.push(user.id)
                                    break
                                }
                            }
                        })
                    }
                }
                this.setState({ userUsers: userUsers, selectedItems: selectedItems })
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.items) {
            this.setState({ items: nextProps.items })
        }
    }

    deleteItem(id) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    selectItem(id) {
        this.toggleItem(id, () => {
            if (this.props.onSelect) {
                this.props.onSelect(id, this.isItemSelected(id));
            }
        });
    }

    setFilter(value) {
        if (value != this.state.filter) {
            this.setState({ filter: value })
        }
    }

    filterValues(values) {
        if (this.state.filter && this.state.filter !== '') {
            return Object.values(values).filter(o => o.name.toLowerCase().includes(this.state.filter.toLowerCase()) || o.email.toLowerCase().includes(this.state.filter.toLowerCase()))
        }
        return Object.values(values)
    }

    toggleItem(id, callback) {
        const selectionMode = this.props.selectionMode !== undefined ? this.props.selectionMode : 'multiple';
        if (selectionMode !== 'multiple') {
            this.setState({ selectedItems: [] });
        }
        let selectedItems = this.state.selectedItems;
        if (this.isItemSelected(id)) {
            selectedItems = selectedItems.filter(o => o !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({ selectedItems: selectedItems }, callback);
    }

    isItemSelected(id) {
        return (this.state.selectedItems ? this.state.selectedItems.includes(id) : false);
    }

    selectAll() {
        const { items } = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        // eslint-disable-next-line array-callback-return
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({ selectedItems: selectedItems, allSelected: true }, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    unselectAll() {
        const { items } = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        // eslint-disable-next-line array-callback-return
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({ selectedItems: [], allSelected: false }, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    toggleAll() {
        if (this.state.allSelected) {
            this.unselectAll();
        } else {
            this.selectAll();
        }
    }

    render() {
        const items = Object.values(this.state.items).length === 0 ? this.props.items : this.state.items;
        const userUsers = this.state.userUsers
        const userValues = userUsers ? this.filterValues(userUsers).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) : null;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const enableFilter = this.props.enableFilter !== undefined ? this.props.enableFilter : true;
        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;
        const enableSelection = this.props.enableSelection !== undefined ? this.props.enableSelection : false;


        const fetchUserDevices = async (items) => {
            if (!items || items.length === 0) return;
            if (this.fetched) return
            this.fetched = true;

            let updatedItems = {}
            for (const userItem of items) {
                const devs = await DevicesService.userDevices(userItem._data.id)
                const newItem = new User().deserialize(deepClone(userItem._data))
                newItem['devices'] = devs
                updatedItems[newItem._data.id] = newItem
            }
            this.setState({ items: updatedItems })
        }

        fetchUserDevices([...values])


        const userRow = (row) => {
            return {
                name: <div searchvalue={row.name.ucFirst()}>{
                    !row.name ? "" : t(String(row.name)).ellipsisEnd()
                }</div>,
                email: !row.email ? "" : row.email,
                administration: row.administrator.toString(),
                devices: row.devices ? row.devices.length.toString() : "",
                deviceLimit: row.deviceLimit.toString(),
                userLimit: row.userLimit.toString(),
                "sharedRemove":
                    <div className="flexbox-container"
                        style={{
                            display: "flex",
                            flexDirection: "row"
                        }}>
                        <IconButton style={{
                            border: "none",
                            outline: "none"
                        }} color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0" onClick={() => {
                            if (List.deleteEvent) {
                                List.deleteEvent = false;
                                return
                            }
                            this.selectItem(row.pk)
                        }}><Pencil color="primary" fontSize="small"></Pencil></IconButton>
                        <IconButton style={{
                            border: "none",
                            outline: "none"
                        }} color="error" className="p-0 mr-2" onClick={() => {
                            if (!row.disabled || App.App.user.administrator) {
                                this.deleteItem(row.pk)
                                List.deleteEvent = true;
                            }
                        }}><DeleteIcon color="warn" fontSize="small"></DeleteIcon></IconButton>
                    </div>,
                checkBox: enableSelection ? <CustomCheckbox checked={this.isItemSelected(row.pk)} onChange={() => {
                    this.selectItem(row.pk)
                }} />
                    : null,
            }
        }

        const rows = []
        values.forEach(row => {
            rows.push(userRow(row))
        })

        const data = {
            columns: this.columns,
            rows: rows
        }

        const usersRows = []
        if (userValues !== null) {
            userValues.forEach(row => {
                usersRows.push(userRow(row))
            })
        }

        const userData = {
            columns: this.columns,
            rows: usersRows
        }

        return <React.Fragment>

            <header className="header-small w-100">
                <div className="container d-flex pb-0">
                    <h3 className="text-white"><i className="mdi mdi mdi-account-multiple"></i> {t('settingsUsers')}
                    </h3>
                </div>
            </header>
            <div className="p-2">
                {enableSelection && App.App.user.administrator ?
                    <section className="ml-2 mr-2 statisc-table">
                        <div className="flex-grow-1 overflow">
                            <MDBDataTable
                                infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                noRecordsFoundLabel={t("sharedEmtpyList")}
                                paginationLabel={[t("previous"), t("next")]}
                                entriesLabel={t("showEntries")}
                                striped
                                hover
                                searching={false}
                                bordered
                                small
                                noBottomColumns={true}
                                data={userData}
                                sortRows={['name']}
                            />
                        </div>
                    </section>
                    : null}

                {enableSelection && App.App.user.administrator ?
                    <div className="list-controls">
                        <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()} />
                    </div>
                    : null}
                {enableFilter ? <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)} />
                </React.Fragment> : null}
                <section className="ml-2 mr-2 statisc-table">
                    <div className="flex-grow-1 overflow">
                        <MDBDataTable
                            infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                            noRecordsFoundLabel={t("sharedEmtpyList")}
                            paginationLabel={[t("previous"), t("next")]}
                            entriesLabel={t("showEntries")}
                            striped
                            hover
                            searching={false}
                            bordered
                            small
                            noBottomColumns={true}
                            data={data}
                            sortRows={['name']}
                        />
                    </div>
                </section>
            </div>

            {/*    <div className="container d-flex">*/}
            {/*        <div className="flex-grow-1 d-flex"></div>*/}
            {/*        <Button variant="secondary" onClick={this.showStastics}>*/}
            {/*            {t("reportShow")}*/}
            {/*        </Button>*/}
            {/*        <Button variant="secondary" onClick={onHide}>*/}
            {/*            <i className="mdi mdi-close"></i> {t("sharedCancel")}*/}
            {/*        </Button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*TABLE OLD*/
            }

            {/*<h3 className="internal-title"><i className="mdi mdi mdi-account-multiple"></i> {t('settingsUsers')}</h3>*/
            }
            {/*<div className="list-tools">*/
            }
            {/*    {enableSelection ?*/
            }
            {/*        <div className="list-controls">*/
            }
            {/*            <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()}/>*/
            }
            {/*        </div>*/
            }
            {/*        : null}*/
            }
            {/*    {enableFilter ? <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)}/>*/
            }
            {/*    </React.Fragment> : null}*/
            }
            {/*    <hr/>*/
            }
            {/*</div>*/
            }
            {/*{values.length > 0 ?*/
            }
            {/*    <div className="flex-grow-1 pt-3 overflow">*/
            }
            {/*        <div className="container">*/
            }
            {/*        </div>*/
            }
            {/*    </div>*/
            }
            {/*    : <EmptyList/>}*/
            }


            {/*LIST*/
            }

            {/*{values.length > 0 ? <ListGroup>*/
            }
            {/*    {values.map(i => {*/
            }
            {/*        if (App.App.user.is_super_admin === 1) {*/
            }

            {/*        } else {*/
            }
            {/*            return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={i.pk}>*/
            }
            {/*                {enableSelection ?*/
            }
            {/*                    <CustomCheckbox checked={this.isItemSelected(i.pk)}*/
            }
            {/*                                    onChange={() => this.selectItem(i.pk)}/>*/
            }
            {/*                    : null}*/
            }
            {/*                <div className="content" onClick={() => this.selectItem(i.pk)}>*/
            }
            {/*                    <strong className="name">{i.name}</strong>*/
            }
            {/*                    <span className="value">*/
            }
            {/*                    <span className="attribute">*/
            }
            {/*                        <span className="attr-name">{t('userEmail')}:</span>*/
            }
            {/*                        <span className="attr-value">{i.email}</span>*/
            }
            {/*                    </span>*/
            }
            {/*                    <span className="attribute">*/
            }
            {/*                        <span className="attr-name">{t('userAdmin')}:</span>*/
            }
            {/*                        <span className="attr-value">{i.administrator.toString()}</span>*/
            }
            {/*                    </span>*/
            }
            {/*                    <span className="attribute">*/
            }
            {/*                        <span className="attr-name">{t('userDeviceLimit')}:</span>*/
            }
            {/*                        <span className="attr-value">{i.deviceLimit.toString()}</span>*/
            }
            {/*                    </span>*/
            }
            {/*                    <span className="attribute">*/
            }
            {/*                        <span className="attr-name">{t('userUserLimit')}:</span>*/
            }
            {/*                        <span className="attr-value">{i.userLimit.toString()}</span>*/
            }
            {/*                    </span>*/
            }
            {/*                        /!* <span className="attribute"> *!/*/
            }
            {/*                        /!* <span className="attr-name">{t('online')}:</span> *!/*/
            }
            {/*                        /!* <span className="attr-value">{i.attributes.online === undefined ? "N/A" : i.attributes.online.toString()}</span> *!/*/
            }
            {/*                        /!* </span> *!/*/
            }

            {/*                        <span className="attribute">*/
            }
            {/*                        <span className="attr-name">{t('sharedDisabled')}:</span>*/
            }
            {/*                        <span className="attr-value">{i.disabled.toString()}</span>*/
            }
            {/*                    </span>*/
            }
            {/*                </span>*/
            }
            {/*                </div>*/
            }
            {/*                {enableDelete ? <div className="actions">*/
            }
            {/*                    <Button variant="default" className="danger" onClick={() => this.deleteItem(i.pk)}><i*/
            }
            {/*                        className="mdi mdi-delete"></i></Button>*/
            }
            {/*                </div> : null}*/
            }
            {/*            </ListGroup.Item>*/
            }
            {/*        }*/
            }
            {/*    })}*/
            }
            {/*</ListGroup> : <EmptyList/>}*/
            }
        </React.Fragment>
    }

}