import { store } from "../store";
import geofences from "../store/geofences";
import { Geofence } from "../models";
import ApiStoreService from "./ApiStoreService";

export class GeofencesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'geofences';
        this.storeName = geofences;
    }

    async list(params = {}) {
        const items = super.list(params);
        return items;
    }

    transformSingleResponse(data) {
        return new Geofence().deserialize(data);
    }


}

export default new GeofencesService();