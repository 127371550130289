import React from 'react';
import { FormControl } from 'react-bootstrap';
import { store } from '../../store';
import { AppService } from '../../services';
import { t } from '../../utils/Translator';
import { App } from '../../App';
export default class CustomNumberField extends React.Component {
    constructor(props) {
        super(props);

        this.parseConfig(props);
        this.state = {
            value: this.config.valueToRaw(props.value)
        };
        this.numberField = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.parseValue = this.parseValue.bind(this);
        this.setUnitOption = this.setUnitOption.bind(this);
    }

    componentWillReceiveProps(nextProps, nextState) {
        /*const value = nextProps.value || nextProps.defaultValue || 0;

        this.parseConfig(nextProps);
        this.setState({ value: this.config.valueToRaw(value) })*/
    }

    parseConfig(props) {
        const config = {
            dataType: props.xdatatype
        };



        switch (props.xdatatype) {
            case 'speed':
                config.units = {};
                config.units.getConverter = () => {
                    return App.SpeedUnitsConverter.convertValue;
                };
                config.units.getValue = () => {
                    return AppService.getAttributePreference('speedUnit', 'kn');
                };
                config.unitName = store.getState().speed_units.speed_units[config.units.getValue()].name;
                break;
            case 'distance':
                config.units = {};
                config.units.getConverter = () => {
                    return App.DistanceUnitsConverter.convertValue;
                };
                config.units.getValue = () => {
                    return AppService.getAttributePreference('distanceUnit', 'km');
                };
                config.unitName = store.getState().distance_units.distance_units[config.units.getValue()].name;
                break;
            case 'hours':
                config.units = {};
                config.units.getConverter = () => {
                    return App.HoursUnitsConverter.convertValue;
                };
                config.units.getValue = () => {
                    return 'h';
                };
                config.unitName = 'sharedHourAbbreviation';
                break;
            case 'frequency':
                config.units = {};
                config.units.getConverter = () => {
                    return App.TimeUnitsConverter.convertValue;
                };
                config.units.getValue = () => {
                    return this.unitOption || 's';
                };
                config.options = Object.values(store.getState().time_units.time_units);
                config.unitName = store.getState().time_units.time_units[config.units.getValue()].name;
                break;
            case "days":
                break;
            default:
                break;
        }


        config.rawToValue = function (rawValue) {
            if (this.units) {
                return this.units.getConverter()(rawValue, this.units.getValue(), true);
            } else {
                return rawValue;
            }
        };
        config.valueToRaw = function (value) {
            if (this.units) {
                const val = String(this.units.getConverter()(value, this.units.getValue(), false));
                return val;
            } else {
                return String(value);
            }
        };
        if (config.units) {
            config.step = config.units.getConverter()(1, config.units.getValue(), true);
        } else {
            config.step = 0.1;
        }

        // config.step = 0.1;

        if(this.props.xdatatype === "days")
            config.step = 1

        this.config = config;
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
        e.target.value = this.config.rawToValue(e.target.value);
        this.props.onChange(e);
    }

    parseValue(value) {
        return value;
    }

    setUnitOption(e) {
        this.unitOption = e.target.value;
        var e = new Event('change', { bubbles: true, target: this.numberField.current });
        //this.numberField.current.value = this.config.valueToRaw(this.numberField.current.value);
        this.numberField.current.dispatchEvent(e);
        //console.log(this.numberField)
    }

    renderUnitsOptions() {
        return <FormControl
            as="select" className="unit-options" onChange={this.setUnitOption}>
            {this.config.options.map(o => {
                return <option value={o.key} key={o.key}>{t(o.name)}</option>
            })}
        </FormControl>
    }

    renderUnit() {
        return <div className="unit">{!this.config.options ? t(this.config.unitName) : this.renderUnitsOptions()}</div>;
    }

    render() {
        return (
            <div className="custom-number-field">
                <div className="field">
                    <FormControl
                        ref={this.numberField}
                        type="number"
                        {...this.props}
                        step={this.config.step}
                        onChange={this.handleChange}
                        value={this.state.value}
                    />
                </div>

                {this.config.unitName ? this.renderUnit() : null}

            </div>
        )
    }
}