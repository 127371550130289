export const types = {
    LOADED: 'server/LOADED',
}

export const initialState = {
    info: null,
    loading: false
}

export const actions = {
    loaded(payload) {
        return {
            type: types.LOADED,
            payload: payload,
        }
    }
}

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOADED:
            return {
                ...state,
                info: action.payload.server
            };
        default:
            return state
    }
}

export default {
    types,
    reducers,
    actions,
    state: initialState
}