import { BaseModel } from "./BaseModel";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'name',
    type: 'string'
}, {
    name: 'description',
    type: 'string'
}, {
    name: 'area',
    type: 'string'
}, {
    name: 'calendarId',
    type: 'int'
}, {
    name: 'attributes'
}];

export class Geofence extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}