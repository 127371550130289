import api from "../utils/Api";
import { convertToQs } from "../utils/functions";

export class StatisticsService {
  constructor() {
    this.baseUrl = "statistics";
  }

  async getStatistics(filter) {
    const { data } = await api.get(this.baseUrl + "/?" + convertToQs(filter));
    return data;
  }
}

export default new StatisticsService();
