import { createSelector } from 'reselect'

const _getAllDevices = (state) => state.devices.devices;
const _getAllGroups = (state) => state.groups.groups;
const _getDevicesFilter = (state) => state.devices.currentFilterByName;
const _getSelectedDevice = (state) => state.devices.selectedDevice;
const _getSelectedDevicePosition = (state) => state.devices.selectedDevice ? state.positions.positions[state.devices.selectedDevice.id] : null;
const _getMapConfig = (state) => state.app.mapstate;
const _getAllEvents = (state) => state.events.events;
const _getEventsFilter = (state) => state.events.currentFilterByName;
let sidebarDevices = [];

export const getSidebarDevices = createSelector(
    [_getAllDevices, _getDevicesFilter],
    (devices, filter) => {
        let arr = Object.values(devices).map(o => {
            return o;
        });
        if (filter && filter !== '') {
            arr = arr.filter(o => o.uniqueId.toLowerCase().includes(filter) || o.name.toLowerCase().includes(filter));
        }
        arr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        return arr;
    }
)

export const getSidebarGroups = createSelector(
    [_getAllGroups, getSidebarDevices],
    (groups, devices) => {
        const arr = Object.values(groups).filter(o => o.id > 0).map(o => {
            return o;
        });

        arr.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        arr.unshift(groups[0])

        arr.map(g => {
            if (g.id > 0) {
                g.devices = devices.filter(o => o.groupId === g.id).map(o => o.id)
            } else {
                g.devices = devices.filter(o => o.groupId === 0 || (!o.groupId) || (!groups[o.groupId])).map(o => o.id)
            }
        });
        return arr;
    }
)

export const getSelectedDevice = createSelector(
    [_getSelectedDevice],
    (device) => {
        return device
    }
)

export const getSelectedDevicePosition = createSelector(
    [_getSelectedDevicePosition],
    (position) => {
        return position;
    }
)

export const getAllDevices = createSelector(
    [_getAllDevices],
    (devices) => {
        return devices;
    }
)

export const getMapConfig = createSelector(
    [_getMapConfig], (config) => config
)

export const getEvents = createSelector(
    [_getAllEvents, _getEventsFilter],
    (events, filter) => {
        let arr = Object.values(events);
        if (filter && filter !== '') {
            arr = arr.filter(o => o.device && o.device.name.toLowerCase().includes(filter));
        }
        arr.sort((a, b) => a.id > b.id ? -1 : 1)
        return arr;
    }
)