export const types = {
    LOGIN: 'session/LOGIN',
    LOGOUT: 'session/LOGOUT',
    LOADING: 'session/LOADING',
    ERROR: 'session/ERROR',
    USER_UPDATE: 'session/USER_UPDATE',
}

export const initialState = {
    user: null,
    token: null,
    loading: false
}

export const actions = {
    check(user) {
        return {
            type: types.LOGIN,
            payload: user
        };
    },
    login(data) {
        return {
            type: types.LOGIN,
            payload: data
        };
    },
    /*login: (credentials) => async (dispatch, state) => {
        dispatch({
            type: types.LOADING,
            loading: true
        });
        try {
            const user = await SessionService.login(credentials);

            dispatch({
                type: types.LOGIN,
                payload: { user }
            });

        } catch (ex) {
            if (ex.response.status == 401) {
                dispatch({
                    type: types.ERROR,
                    error: { message: 'Wrong credentials!' }
                });
            } else {
                dispatch({
                    type: types.ERROR,
                    error: ex
                });
            }

        } finally {
            dispatch({
                type: types.LOADING,
                loading: false
            });
        }


    },*/
    logout() {
        return {
            type: types.LOGOUT
        };
    }

}

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.LOGIN:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
            };
        case types.LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case types.ERROR:
            return {
                ...state,
                error: action.error
            };
        case types.LOGOUT:
            return {
                ...state,
                user: null,
                token: null,
            };
        case types.USER_UPDATE:
            return {
                ...state,
                user: action.payload.user,
            };
        default:
            return state
    }
}

export default {
    types,
    reducers,
    actions,
    state: initialState
}