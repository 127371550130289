import React from 'react';
import AttributesPage from '../Attributes';
import {Form, FormControl, FormGroup, FormLabel, Row} from 'react-bootstrap';
import {Button} from '@mui/material'
import {t} from '../../../utils/Translator';
import {Formik} from 'formik';
import * as yup from 'yup';
import {Maintenance, Notification} from '../../../models';
import {deepClone, isEqual} from '../../../utils/functions';
import CustomNumberField from '../../Controls/CustomNumberField';
import {App} from "../../../App";
import Datetime from "react-datetime";
import CustomCheckbox from "../../Controls/CustomCheckBox";
import CustomSelect from "../../Controls/CustomSelect";
import {store} from "../../../store";
import {NotificationsService, PermissionsService} from "../../../services";


export default class MaintenanceForm extends React.Component {
    constructor(props) {
        super(props);


        const notificatorOptions = [];
        Object.values(store.getState().notificators.notificators).map(o => {
            notificatorOptions.push({
                label: t("notificator" + o.name.ucFirst()),
                value: o.type
            });
        });
        notificatorOptions.sort((a, b) =>
            a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
        );

        const typeOptions = [];
        // Object.values(store.getState().maintenance_types.maintenance_types).map(o => {
        //     typeOptions.push({ label: t(o.name), value: o.key })
        // });
        // typeOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        typeOptions.push({
                value: 1,
                label: t("positionOdometer")
            },
            {
                value: 0,
                label: t("Time")
            }
        );

        const allDevices = store.getState().devices.devices;
        const deviceOptions = [];
        Object.values(allDevices).map(d => {
            if (!d.disabled) deviceOptions.push({value: d.id, label: d.name});
        })
        deviceOptions.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1)

        if (props.item.attributes === undefined)
            props.item.attributes = {}

        const item = new Maintenance().deserialize(deepClone(props.item))

        this.initialNotification = null;
        let notificationEnabled = false;
        let selectedNotificators = null;
        let always = false;
        let selectedDevices = ""

        const id = item && item.id ? item.id : null
        if (id !== null) {
            const notifications = Object.values(store.getState().notifications.notifications)
            for (let n of notifications) {
                if (n.type === "maintenance" && n.attributes && n.attributes["maintenanceId"] === id) {
                    this.initialNotification = new Notification().deserialize(n._data);
                    this.initialSelectedDevices = n._data.attributes["selectedDevices"] ?? null;
                    always = n._data.always;
                    selectedNotificators = n._data.notificators;
                    notificationEnabled = true
                    selectedDevices = n._data.attributes["selectedDevices"] ?? ""
                    break;
                }
            }
        }

        this.state = {
            attributesPage: false,
            item: item,
            mode: props.mode,
            sending: false,
            changed: false,
            typeOptions: typeOptions,
            selectedOption: 0,
            selectedDevices: selectedDevices,
            allDevices: allDevices,
            deviceOptions: deviceOptions,
            notificatorOptions: notificatorOptions,
            notificationEnabled: notificationEnabled,
            selectedNotificators: selectedNotificators,
            always: always
        }

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.changeSelectedDevices = this.changeSelectedDevices.bind(this);
    }

    async save(values) {
        const item = this.state.item.deserialize(values);
        console.log("item",item)
        const maintenance = Maintenance.build(
            item,
            item.name,
            item.attributes['date'] !== undefined && item.attributes['date']!==null,
            item.period !== undefined && item.period !== null && item.period > 0,
            item.attributes['date'],
            item.attributes['repeatable'],
            item.attributes['repeatType'],
            item.attributes['repeatDayCount'],
            item.start,
            item.period
        )
        console.log("saving maintenance",maintenance)
        await this.props.onSave(maintenance);
        await this.saveNotification(maintenance);
    }

    async saveNotification(item) {
    if (!this.state.notificationEnabled && this.initialNotification) {
                await NotificationsService.destroy(this.initialNotification._data.id, false)
                return;
            }

            let notification = this.initialNotification ? Object.assign({}, this.initialNotification._data) : {}
            if (!notification.calendarId) {
                notification.calendarId = 0;
            }
            if (!notification.attributes) {
                notification.attributes = {}
            }
            notification.always = this.state.always
            notification.notificators = this.state.selectedNotificators
            notification.type = "maintenance"
            notification.attributes["maintenanceId"] = item.id

            if (!notification.always) {
                notification.attributes["selectedDevices"] = this.state.selectedDevices
            } else {
                delete notification.attributes["selectedDevices"]
            }

            let final = new Notification().deserialize(notification)
            if (this.initialNotification !== null)
                final = await NotificationsService.update(final)
            else
                final = await NotificationsService.save(final)

            if (this.initialSelectedDevices) {
                for (const it of this.initialSelectedDevices.split(",")) {
                    const data = {
                        deviceId: parseInt(it),
                        notificationId: final._data.id,
                    }
                    try {
                        await PermissionsService.destroyWithParams(data)
                    } catch (e) {
                        console.log(e)
                    }
                }
            }

            if (notification.attributes["selectedDevices"]) {
                for (const it of notification.attributes["selectedDevices"].split(",")) {
                    const data = {
                        deviceId: parseInt(it),
                        notificationId: final._data.id,
                    }
                    try {
                        await PermissionsService.save(data)
                    } catch (e) {
                        console.log(e)
                    }
                }
            }
            }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({attributesPage: true})
    }

    changeSelectedDevices(name, value) {
        this.setState({
            selectedDevices: value,
            changed: true
        })
    }

    onAttributesCancel() {
        const clone = new Maintenance().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        })
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        })
    }


    componentWillReceiveProps(newProps, newState) {
        this.setState({item: new Maintenance().deserialize(deepClone(newProps.item))})
    }


    render() {
        const {
            sending,
            item,
            typeOptions,
            notificatorOptions,
            notificationEnabled,
            deviceOptions,
            selectedDevices,
            selectedNotificators,
            always
        } = this.state;

        const schema = yup.object({
            // name: yup.string().required(),
            // type: yup.string().required(),
            // start: yup.number().min(0),
            // period: yup.number().min(0.1)
        });

        const maintenanceCurrentDate = item !== null && item.getAttribute('date') ?
            new Date(item.getAttribute('date')) : Date.now();
        const repeatableSelected = item !== null ?
            item.getAttribute("repeatable") ?? false : false
        const currentRepeatCount = item !== null ? item.getAttribute('repeatDayCount') ?? 0 : 0;
        const maintenanceRepeatType = item !== null ? item.getAttribute('repeatType') ?? 0 : 0;
        const maintenanceOptions = [
            {
                name: t("Days"),
                value: 0
            },
            {
                name: t("Months"),
                value: 1
            },
            {
                name: t("Year"),
                value: 2
            }
        ]

        return <React.Fragment>
            {this.state.attributesPage ?
                <AttributesPage item={item} type="userAttributes" onHide={() => this.onAttributesClosed()}
                                onSave={() => this.onAttributesClosed()} onCancel={() => this.onAttributesCancel()}/>
                : null}

            <div className="container-wrapper">
                <Formik
                    validationSchema={schema}
                    onSubmit={this.save}
                    disabled={sending}
                    initialValues={{
                        name: item.name,
                        period: item.period || 0,
                        start: item.start || 0,
                        type: item.type || '',
                        maintenanceCurrentDate,
                        repeatableSelected,
                        currentRepeatCount,
                        maintenanceRepeatType,
                        maintenanceOptions
                    }}
                >
                    {({
                          values,
                          handleSubmit,
                          handleChange,
                          dirty,
                          isSubmitting,
                          setFieldValue,
                          errors,
                          isValid
                      }) => {
                        return (

                            <Form onSubmit={handleSubmit} noValidate
                                  className="d-flex flex-column h-100 flex-grow-1 form form-sm ">
                                <React.Fragment>
                                    <div className="flex-grow-1 pt-3 overflow">
                                        <div className="container ">
                                            <h3 className="internal-title"><i
                                                className="mdi mdi-wrench"></i> {this.state.item.id ? t('sharedEdit') : t('sharedAdd')} {t('sharedMaintenance')}
                                            </h3>
                                            <FormGroup>
                                                <FormLabel>{t("sharedName")}</FormLabel>
                                                <FormControl
                                                    type="text"
                                                    name="name"
                                                    defaultValue={values.name}
                                                    onChange={handleChange}
                                                    isInvalid={!!errors.name}
                                                />
                                            </FormGroup>
                                            {/*<FormGroup>*/}
                                            {/*    <FormLabel>{t("sharedType")}</FormLabel>*/}
                                            {/*    <CustomSelect options={typeOptions} name="type"*/}
                                            {/*                  defaultValue={this.state.selectedOption}*/}
                                            {/*                  onChange={(name, value) => {*/}
                                            {/*                      console.log("DEF", value)*/}
                                            {/*                      this.setState({selectedOption: value})*/}
                                            {/*                  }}/>*/}

                                            {/*</FormGroup>*/}
                                            {/*{*/}
                                            {/* this.state.selectedOption === 1 ?*/}
                                            <hr className="mb-2"/>
                                            <FormGroup className="mb-1 mt-4">
                                                <FormLabel>{t("Choose Km")}</FormLabel>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormGroup>
                                                    <FormLabel>{t("maintenanceStart")}</FormLabel>
                                                    <CustomNumberField
                                                        type="number"
                                                        name="start"
                                                        xdatatype={'distance'}
                                                        value={values.start}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.start}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    <FormLabel>{t("maintenancePeriod")}</FormLabel>
                                                    <CustomNumberField
                                                        type="number"
                                                        name="period"
                                                        xdatatype={'distance'}
                                                        value={values.period}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.period}
                                                    />
                                                </FormGroup>
                                            </FormGroup>

                                            <FormGroup className="mb-1 mt-4">
                                                <FormLabel>{t("Choose Date")}</FormLabel>
                                            </FormGroup>

                                            <FormGroup>
                                                <FormGroup>
                                                    <div className='ml-2'>
                                                        <Row>
                                                            <FormGroup>
                                                                <Datetime
                                                                    timeFormat={false}
                                                                    dateFormat="YYYY-MM-DD"
                                                                    defaultValue={maintenanceCurrentDate}
                                                                    onChange={(date) => {
                                                                        let m = this.state.item
                                                                        m.attributes["date"] =  date.valueOf()
                                                                        this.setState({
                                                                            deviceMaintenance: m,
                                                                            changed: true
                                                                        })
                                                                    }}> </Datetime>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <div className='ml-3'>
                                                                    <CustomCheckbox name={'repeat'}
                                                                                    value={true}
                                                                                    defaultChecked={repeatableSelected}
                                                                                    onChange={event => {
                                                                                        let m = this.state.item
                                                                                        m.attributes["repeatable"] = event.target.checked;
                                                                                        this.setState({
                                                                                            deviceMaintenance: m,
                                                                                            changed: true
                                                                                        })
                                                                                    }}
                                                                                    label={t('repeatable')}/>
                                                                </div>
                                                            </FormGroup>
                                                        </Row>
                                                    </div>
                                                </FormGroup>

                                                {!repeatableSelected ?
                                                    null : <FormGroup>
                                                        <div className='mt-1'>
                                                            <Row className="ml-2">

                                                                <FormLabel>{t("Every")}</FormLabel>

                                                                <div className='ml-3'>
                                                                    <FormGroup>
                                                                        <CustomNumberField
                                                                            xdatatype={"days"}
                                                                            name="Repeat Unit"
                                                                            value={currentRepeatCount}
                                                                            onChange={event => {
                                                                                let m = this.state.item
                                                                                m.attributes["repeatDayCount"] = event.target.value;
                                                                                this.setState({
                                                                                    deviceMaintenance: m,
                                                                                    changed: true
                                                                                })
                                                                            }
                                                                            }/>
                                                                    </FormGroup>
                                                                </div>
                                                                <div className='ml-3'>
                                                                    <FormGroup>
                                                                        <FormControl
                                                                            as="select"
                                                                            name="repeatType"
                                                                            defaultValue={maintenanceRepeatType}
                                                                            onChange={event => {
                                                                                let m = this.state.item
                                                                                m.attributes["repeatType"] = event.target.value;
                                                                                this.setState({
                                                                                    deviceMaintenance: m,
                                                                                    changed: true
                                                                                })
                                                                            }
                                                                            }>
                                                                            {maintenanceOptions.map(o => {
                                                                                return <option key={o.name}
                                                                                               value={o.value}>{o.name}</option>
                                                                            })}
                                                                        </FormControl>
                                                                    </FormGroup>
                                                                </div>

                                                            </Row>
                                                        </div>
                                                    </FormGroup>
                                                }
                                            </FormGroup>

                                            <hr/>

                                            <FormGroup>
                                                <div className='mt-4'>
                                                    <CustomCheckbox name='notificationEnabled'
                                                                    value={true}
                                                                    defaultChecked={notificationEnabled}
                                                                    onChange={event => {
                                                                        this.setState({
                                                                            notificationEnabled: event.target.checked,
                                                                            changed: true
                                                                        })
                                                                    }}
                                                                    label={t('Create notification')}/>
                                                </div>
                                            </FormGroup>

                                            {!notificationEnabled ? null :
                                                <>
                                                    <FormGroup>
                                                        <FormLabel>{t("notificationNotificators")}</FormLabel>
                                                        <CustomSelect
                                                            options={notificatorOptions}
                                                            isClearable
                                                            isMulti
                                                            name="notificators"
                                                            defaultValue={selectedNotificators}
                                                            onChange={(n, value) => {
                                                                this.setState({
                                                                    selectedNotificators: value,
                                                                    changed: true
                                                                })
                                                            }
                                                            }
                                                        />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <CustomCheckbox
                                                            name="always"
                                                            value={always}
                                                            defaultChecked={always}
                                                            onChange={(event) => {
                                                                this.setState({
                                                                    always: event.target.checked,
                                                                    changed: true
                                                                })
                                                            }}
                                                            label={t("notificationAlways")}
                                                        />
                                                    </FormGroup>
                                                    {!always ?
                                                        <>
                                                            <CustomSelect className="custom-select-margin-50" isMulti
                                                                          options={deviceOptions}
                                                                          defaultValue={selectedDevices}
                                                                          onChange={this.changeSelectedDevices}/>
                                                        </> : null
                                                    }

                                                </>
                                            }
                                        </div>
                                    </div>
                                    <footer className="footer">
                                        <div className="container d-flex">
                                            <div className="flex-grow-1 d-flex">
                                                {
                                                    App.App.user.administrator ?
                                                        <Button variant="primary" className="align-self-stretch"
                                                                onClick={() => this.openAttributesPage()}>
                                                            <i className="mdi mdi-playlist-play"></i> {t('sharedAttributes')}
                                                        </Button> : null
                                                }
                                            </div>
                                            <Button variant="contained" size="large" className="btn-red mr-2"
                                                    onClick={() => this.cancel()}>
                                                <i className="mdi mdi-close"></i> {t('sharedCancel')}
                                            </Button>
                                            <Button type="submit" variant="contained" size="large" className="btn-blue"
                                                    disabled={!this.state.changed && (!dirty || !isValid)}>
                                                <i className="mdi mdi-content-save"></i> {t('sharedSave')}
                                            </Button>
                                        </div>
                                    </footer>
                                </React.Fragment>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </React.Fragment>
    }
}