import { BaseModel } from "./BaseModel";
import { App } from "../App";

export const fields = [{
    name: 'id',
    type: 'int',
    isPk: true,
}, {
    name: 'protocol',
    type: 'string'
}, {
    name: 'deviceId',
    type: 'int'
}, {
    name: 'serverTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'deviceTime',
    type: 'date',
    dateFormat: 'c'
}, {
    name: 'fixTime',
    type: 'date',
    dateFormat: 'YYYY-MM-DD HH:mm:ss'
}, {
    name: 'valid',
    type: 'boolean'
}, {
    name: 'accuracy',
    type: 'float',
    convert: (value) => App.AttributeFormatter.getConverter('accuracy')(value)
}, {
    name: 'latitude',
    type: 'float'
}, {
    name: 'longitude',
    type: 'float'
}, {
    name: 'altitude',
    type: 'float'
}, {
    name: 'speed',
    type: 'float',
    convert: (value) => App.AttributeFormatter.getConverter('speed')(value)
}, {
    name: 'course',
    type: 'float'
}, {
    name: 'address',
    type: 'string'
}, {
    name: 'attributes'
}]

export class Position extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}