import React from 'react';
import {t} from '../../../utils/Translator';
import {GroupsService} from '../../../services';
import TextFilter from '../../Controls/TextFilter';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import {App} from "../../../App";
import {MDBDataTable} from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import Pencil from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";

export default class List extends React.Component {

    static deleteEvent = false

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            selectedItems: props.selectedItems || [],
            allSelected: false,
        }
        this.deleteItem = this.deleteItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unselectAll = this.unselectAll.bind(this);

        this.columns = [
            {
                label: t("sharedName"),
                field: "name",
                sort: 'asc',
                height: 80
            },
            {
                label: t("sharedTimezone"),
                field: "timezone",
                sort: 'asc',
                height: 80
            },
            {
                label: t("attributeSpeedLimit"),
                field: "speedLimit",
                sort: 'asc',
                height: 80
            },
            {
                label: t("attributeReportIgnoreOdometer"),
                field: "ignoreOdometer",
                sort: 'asc',
                height: 80
            }
        ]

        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;

        if (enableDelete)
            this.columns.push({
                label: t("actions"),
                field: "sharedRemove",
                sort: "disabled",
            })

        const enableSelection = this.props.enableSelection !== undefined ? this.props.enableSelection : false;

        if (enableSelection)
            this.columns.splice(0, 0, {
                label: "",
                field: "checkBox",
                sort: "disabled",
            })
    }

    componentDidMount() {
        if (App.App.user.administrator) {
            GroupsService.listAll({all: true}, false).then(groups => {
                this.setState({props: groups})
            })
        }
    }

    deleteItem(id) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    selectItem(id) {
        this.toggleItem(id, () => {
            if (this.props.onSelect) {
                this.props.onSelect(id, this.isItemSelected(id));
            }
        });

    }

    setFilter(value) {
        if (value != this.state.filter) {
            this.setState({filter: value})
        }
    }

    filterValues(values) {
        if (this.state.filter && this.state.filter != '') {
            return Object.values(values).filter(o => o && o.name.toLowerCase().includes(this.state.filter.toLowerCase()))
        }
        return Object.values(values)
    }

    toggleItem(id, callback) {
        const selectionMode = this.props.selectionMode != undefined ? this.props.selectionMode : 'multiple';
        if (selectionMode !== 'multiple') {
            this.setState({selectedItems: []});
        }
        let selectedItems = this.state.selectedItems;
        if (this.isItemSelected(id)) {
            selectedItems = selectedItems.filter(o => o != id);
        } else {
            selectedItems.push(id);
        }
        this.setState({selectedItems: selectedItems}, callback);
    }

    isItemSelected(id) {
        return (this.state.selectedItems ? this.state.selectedItems.includes(id) : false);
    }


    selectAll() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({selectedItems: selectedItems, allSelected: true}, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    unselectAll() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({selectedItems: [], allSelected: false}, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    toggleAll() {
        if (this.state.allSelected) {
            this.unselectAll();
        } else {
            this.selectAll();
        }
    }

    render() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const enableFilter = this.props.enableFilter != undefined ? this.props.enableFilter : true;
        const enableDelete = this.props.enableDelete != undefined ? this.props.enableDelete : false;
        const enableSelection = this.props.enableSelection != undefined ? this.props.enableSelection : false;

        const rows = []

        values.forEach(row => {
            if(!row.attributes)
                row.attributes = {}
            rows.push(
                {
                    name: <div searchvalue={row.name.ucFirst()}>{
                        !row.name?"":t(String(row.name)).ellipsisEnd()
                    }</div>,
                    timezone: t(row.attributes["decoder.timezone"] ?? ""),
                    speedLimit: <div searchvalue={row.attributes["speedLimit"] ?? 0}>{
                        row.attributes["speedLimit"] ? App.AttributeFormatter.getConverter('speed')(row.attributes["speedLimit"]) : ""
                    }</div>,
                    ignoreOdometer: String(row.attributes["report.ignoreOdometer"] ?? ""),
                    "sharedRemove":
                        <div className="flexbox-container"
                             style={{
                                 display: "flex",
                                 flexDirection: "row"
                             }}>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0" onClick={() => {
                                if (List.deleteEvent) {
                                    List.deleteEvent = false;
                                    return
                                }
                                this.selectItem(row.pk)
                            }}><Pencil color="primary" fontSize="small"></Pencil></IconButton>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="error" className="p-0 mr-2" onClick={() => {
                                if (!row.disabled || App.App.user.administrator) {
                                    this.deleteItem(row.pk)
                                    List.deleteEvent = true;
                                }
                            }}><DeleteIcon color="warn" fontSize="small"></DeleteIcon></IconButton>
                        </div>,
                    checkBox: enableSelection ? <CustomCheckbox checked={this.isItemSelected(row.pk)} onChange={() => {
                            this.selectItem(row.pk)
                        }}/>
                        : null,

                }
            )
        })

        const data = {
            columns: this.columns,
            rows: rows
        }

        return <React.Fragment>
            <header className="header-small w-100">
                <div className="container d-flex pb-0">
                    <h3 className="text-white"><i className="mdi mdi-car"></i> {t('settingsGroups')}</h3>
                </div>
            </header>

            <div className="p-2">
                <div className="list-tools">
                    {enableSelection ?
                        <div className="list-controls">
                            <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()}/>
                        </div>
                        : null}
                    {enableFilter ?
                        <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)}/></React.Fragment>
                        : null}
                    <hr/>
                </div>

                <section className="ml-2 mr-2 statisc-table">
                    <div className="flex-grow-1 overflow">
                    <MDBDataTable id="myTableID"
                                  infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                                  noRecordsFoundLabel={t("sharedEmtpyList")}
                                  paginationLabel={[t("previous"), t("next")]}
                                  entriesLabel={t("showEntries")}
                                  striped
                                  hover
                                  searching={false}
                                  bordered
                                  small
                                  noBottomColumns={true}
                                  data={data}
                                  sortRows={['name']}
                    />
                    </div>
                </section>
            </div>

        </React.Fragment>
    }

// {/*    <h3 className="internal-title"><i className="mdi mdi-google-circles-extended"></i> {t('settingsGroups')}</h3>*/}
//         {/*    <div className="list-tools">*/}
//         {/*        {enableSelection ?*/}
//         {/*            <div className="list-controls">*/}
//         {/*                <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()} />*/}
//         {/*            </div>*/}
//         {/*            : null}*/}
//         {/*        {enableFilter ? <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)} />*/}
//         {/*        </React.Fragment> : null}*/}
//         {/*        <hr />*/}
//         {/*    </div>*/}
//
//         {/*    /!*{values.length > 0 ?*!/*/}
//         {/*    /!*    <div className="data-wrapper">*!/*/}
//         {/*    /!*        <div className="table-wrapper">*!/*/}
//         {/*    /!*            <div className="table-fixed">*!/*/}
//         {/*    /!*                <BootstrapTable bootstrap4={true} //classes="table table-sm-8 table-condensed table-hover"*!/*/}
//         {/*    /!*                                striped={true} hover={true} condensed={true}*!/*/}
//         {/*    /!*                                trStyle={this.trStyle}*!/*/}
//         {/*    /!*                                rowClasses={rowClasses} rowEvents={rowEvents} keyField='id'*!/*/}
//         {/*    /!*                                data={values ?? []} columns={this.columns} pagination={pagination}/>*!/*/}
//         {/*    /!*            </div>*!/*/}
//         {/*    /!*        </div>*!/*/}
//         {/*    /!*    </div>*!/*/}
//         {/*    /!*    : <EmptyList/>}*!/*/}
//
//         {/*    {values.length > 0 ? <ListGroup >*/}
//         {/*        {values.map(i => {*/}
//         {/*            if (i.id > 0) {*/}
//         {/*                return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={i.pk}>*/}
//         {/*                    {enableSelection ?*/}
//         {/*                        <CustomCheckbox checked={this.isItemSelected(i.pk)} onChange={() => this.selectItem(i.pk)} />*/}
//         {/*                        : null}*/}
//         {/*                    <div className="content" onClick={() => this.selectItem(i.pk)}>*/}
//         {/*                        <strong className="name">{i.name}</strong>*/}
//         {/*                        <span className="value">*/}
//
//         {/*                        </span>*/}
//         {/*                    </div>*/}
//         {/*                    {enableDelete ? <div className="actions">*/}
//         {/*                        <Button variant="default" className="danger" onClick={() => this.deleteItem(i.pk)}><i className="mdi mdi-delete"></i></Button>*/}
//         {/*                    </div> : null}*/}
//         {/*                </ListGroup.Item>*/}
//         {/*            }*/}
//         {/*            return null;*/}
//         {/*        })}*/}
//         {/*    </ListGroup> : <EmptyList />}*/}
//         {/*</React.Fragment>*/}

}
