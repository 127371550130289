import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { AppService } from '../services';

export class ExternalLayout extends React.Component {

    componentWillMount() {
        AppService.hideNotifications();
        document.body.classList.add('external');
    }

    componentWillUnmount() {
        document.body.classList.remove('external');
    }

    render() {
        return (
            <div id="layoutWrapper">
                <Row className="h-100 d-flex  flex-column m-0">
                    <Col id="contentWrapper" className="flex-grow-1 p-0">
                        {this.props.children}
                    </Col>
                </Row>
            </div>
        );
    }
}

ExternalLayout.propTypes = {
    //dispatch: PropTypes.func.isRequired,
    children: PropTypes.object
};

const mapStateToProps = () => ({
    //count: state.count,
})

const mapDispatchToProps = () => ({
    /*increment: () => dispatch(increment()),
    decrement: () => dispatch(decrement()),*/
})

export default connect(mapStateToProps, mapDispatchToProps)(ExternalLayout)