import { Expense } from "../models";
import ApiStoreService from "./ApiStoreService";
import expenses from "../store/expenses";

export class ExpensesService extends ApiStoreService {
    constructor() {
        super();
        this.baseUrl = 'expenses';
        this.storeName = expenses
    }

    transformSingleResponse(data) {
        return new Expense().deserialize(data);
    }


}

export default new ExpensesService();