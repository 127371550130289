import LeafletGeofenceConverter from "./LeafletGeofenceConverter";
import L from 'leaflet';
import { hexToRGB } from "../../functions";


export const CustomIcon = new L.Icon({
    iconUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=',
});


export default class LeafletGeofence {
    constructor(geofence, parent = null) {
        this.id = geofence.id;
        this.geofence = geofence;
        this.label = null;
        this.parent = parent;
        this.container = null;
        this.container = null;
        this.geometry = null;
        this.color = 'red';
        this.ready = false;
    }

    init(addToParent = true) {
        this.container = new L.FeatureGroup();

        this._createLayers();

        if (addToParent) {
            this.addToParent();
        }
        return this;
    }

    addToParent() {

        if (this.isVisible) {
            return;
        }
        if (!this.container) {
            this.init(false);
        }
        this.parent.addLayer(this.container);
        this.container.bringToBack();
        this.container.setZIndex(1);
    }

    _createLayers() {


        if (this.geofence) {
            this.geometry = LeafletGeofenceConverter.wktToGeometry(this.geofence.area);
            this.geometry.setStyle({
                color: this.geofence.getAttribute('color'),
                fillColor: this.geofence.getAttribute('color')
            })
            let center;
            try {
                center = this.geometry.getLatLng();
            } catch(ex){
                try {
                    center = this.geometry.getBounds().getCenter();
                } catch(ex){

                }

            }

            if (center) {
                /*this.label = new L.Marker(center, {
                    opacity: 0,
                    icon: CustomIcon
                }).bindTooltip(this.geofence.name, {
                    permanent: true,
                    interactive:true,
                    direction: 'center',
                    className: 'geofence-name',
                });*/

                this.label = new L.Marker(center, {
                    opacity: 0,
                    icon: new L.DivIcon({
                        html: '<div class="name" style="background-color: ' + hexToRGB(this.geofence.getAttribute('color'), .8) + '; border: 2px solid ' + this.geofence.getAttribute('color') + '">' + this.geofence.name + "</div>",
                        className: 'geofence-name',
                        iconSize: 'auto'
                    }),
                });

                this.container.addLayer(this.label);
                this.label.setZIndexOffset(1);
            }


            this.geometry.bindTooltip(this.geofence.name, {
                direction: 'top',
                className: 'vehicle-tooltip',
            });

            this.geometry.on('mouseover', (e) => {
                let tooltip = e.target.getTooltip();
                tooltip.setLatLng(e.latlng);
            });

            this.geometry.on('mousemove', (e) => {
                let tooltip = e.target.getTooltip();
                tooltip.setLatLng(e.latlng);
            });

            this.container.addLayer(this.geometry);

            this.ready = true;
        }
    }

    get isVisible() {
        return false;
    }
}