import React from 'react';

class Interval extends React.PureComponent {
    componentDidMount() {
        this.interval = setInterval(() => this.forceUpdate(), this.props.delay);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children || null}
            </React.Fragment>
        )
    }
}

export default Interval;