import createGenericStore from "../generic_store";

const store = createGenericStore('positions', {
    pk: 'deviceId',
    initialState: {
        history: {},
    },
    onUpdated(state, action) {
        if (!action.payload || !action.payload.item) {
            return;
        }
        state.history[action.payload.item.deviceId] = state.history[action.payload.item.deviceId] || [];
        state.history[action.payload.item.deviceId].push(action.payload.item);
    }
});
export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}