import constants from "../../../config/constants";
import L from 'leaflet';
import DeviceImages from "../../DeviceImages";

export const CustomIcon = L.Icon.extend({});

export default class LeafletMarker {
    constructor(parent) {
        this.statusColor = constants.device.colors.unknown;
        this.iconColor = constants.device.defaultIconColor || null;
        this.rotation = 0;
        this.zoom = true;
        this.selected = false;
        this.marker = null;
        this.category = 'default';
        this.title = null;
        this.latLng = {lat: 0, lng: 0};
        this.id = 0;
        this.parent = parent;
        this.tooltipElement = null;
        this.type = 'device';
        this.visible = true;
        this.hidden = false;
    }

    _normalizeConfig(config) {
        config.lat = config.lat || this.latLng.lat;
        config.lng = config.lng || this.latLng.lng;
        config.rotation = config.rotation || this.rotation;
        config.statusColor = config.statusColor || this.statusColor;
        config.selected = undefined !== config.selected ? config.selected : this.selectedl
        config.category = config.category || this.category;
        config.title = config.title || this.text;
        config.statusColor = config.statusColor || this.statusColor;
        config.iconColor = config.iconColor || this.iconColor;
        return config;
    }

    _getImageInfo(statusColor, iconColor, zoom, angle, category) {
        return DeviceImages.getImageInfo(statusColor, iconColor, zoom, angle, category);
    }

    _getReportImageInfo(statusColor, iconColor, zoom, angle, category) {
        return DeviceImages.getReportImageInfo(statusColor, iconColor, zoom, angle, category);
    }

    _getIcon(statusColor, iconColor, zoom, angle, category, report) {
        const info = report ? this._getReportImageInfo(statusColor, iconColor, zoom, angle, category)
            : this._getImageInfo(statusColor, iconColor, zoom, angle, category);
        return new CustomIcon({
            iconUrl: info.src,
            iconSize: [info.width, info.height],
            iconAnchor: [info.width / 2, info.height / 2],
            popupAnchor: [0, -info.height / 2],

        })
    }


    create(config, report) {
        this.config = Object.assign({}, this._normalizeConfig(config));
        this.hidden = config.hidden;
        this.marker = new L.Marker([config.lat, config.lng], {
            icon: this._getIcon(config.statusColor, config.iconColor, config.selected, config.rotation, config.category, report),
            title: config.title,
            riseOnHover: true,
            name: config.id + '_marker'
            //renderer: new L.Canvas()
        });
        this.marker.on('mouseover', () => {
            this.marker._icon.style.zIndex = 999999;

        })
        this._createTooltip(config.title, true);
        return this.marker;
    }

    update(config, report) {
        let changed = false;
        this.hidden = config.hidden;
        config = this._normalizeConfig(config);
        if (config.statusColor !== this.config.statusColor || this.config.iconColor !== config.iconColor || this.config.selected !== config.selected || config.category !== this.config.category || config.rotation !== this.config.rotation) {
            this.marker.setIcon(this._getIcon(config.statusColor, config.iconColor, config.selected, config.rotation, config.category, report));
            changed = true;
        }
        if (this.config.lat !== config.lat || this.config.lng || config.lng) {
            this.marker.setLatLng([config.lat, config.lng])
            changed = true;
        }

        if (this.config.title !== config.title) {
            this.updateTooltip(config.title);
        }


        if (changed) {
            this.marker.update();
        }


        this.config = Object.assign(this.config, config);
    }

    remove() {
        this.parent.removeLayer(this.marker);
    }

    hide() {
        this.remove();
        this.visible = false;
    }

    show() {
        this.addToParent();
        this.visible = true;
    }

    _createTooltip(title, permanent) {
        this.marker.bindTooltip(title, {
            className: 'vehicle-tooltip',
            permanent: permanent,
            direction: 'top',
            sticky: false
        });
    }

    bindPopup(str) {
        this.marker.bindPopup(str)
    }

    unbindPopup() {
        this.marker.unbindPopup();
    }

    addToParent() {
        this.marker.addTo(this.parent);
    }

    updateTooltip(name) {
        this.marker.getTooltip().setContent(name);
    }

    showName() {
        const name = this.marker.getTooltip().getContent();
        this.marker.unbindTooltip();
        this._createTooltip(name, true);
        this.marker.openTooltip();
    }

    hideName() {
        const name = this.marker.getTooltip().getContent();
        this.marker.unbindTooltip();
        this._createTooltip(name, false);
        this.marker.closeTooltip()
    }


}