import React from 'react';
import DeviceList from '../DeviceList/list';
import { store } from '../../store';
import { GroupItem } from './item';
import { connect } from 'react-redux';

export class DeviceGroups extends React.Component {

    constructor(props) {
        super(props);
        this.isFirst = true;
    }

    componentWillMount() {
        this.isFirst = true;
    }

    componentWillUnmount() {
        this.isFirst = true;
    }

    shouldComponentUpdate(nextProps) {
        let changed= this.props.groups.length != nextProps.groups.length;
        if(!changed){
            const nextItems=nextProps.groups;
            const currItems=this.props.groups;
            nextItems.map(ng=>{
                const cg=currItems.find(o=>o.id===ng.id);
                if(!cg){
                    changed=true;
                }else{
                    if(ng.devices.length!=cg.devices.length){
                        changed=true;
                    }
                }
                
            })
        }
        return changed;
    }



    render() {
        const { groups } = this.props;
        return (
            <div className="device-groups">
                {groups.map(g => {
                    if (g.devices.length === 0) {
                        return null;
                    }

                    if (this.isFirst && g.open === undefined) {
                        g.open = true;
                        this.isFirst = false;
                    }

                    return <ConnectedItem id={g.id} key={g.id} />
                })}
            </div>
        )
    }
}

const ConnectedItem = connect((state, props) => ({
    group: state.groups.groups[props.id],
    items: state.groups.groups[props.id].devices || []
}))(GroupItem)

export default DeviceGroups;