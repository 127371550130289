import { App } from "../App";
import { Position, ReportStop, ReportSummary, ReportTrip } from "../models";
import { ReportEcoScore } from "../models/ReportEcoScore";
import api from "../utils/Api";
import EcoScoreUtils from "../utils/EcoScoreUtils";
import { convertToQs } from "../utils/functions";

export class ReportsService {
  constructor() {
    this.baseUrl = "reports";
  }

  async getReport(
    type,
    filter,
    config = { showMarkers: true, showStops: false, showIdle: false }
  ) {
    if (type === "driverTrips") type = "driver-trips";
    if (type === "driverSummary") type = "driver-summary";
    if (type === "driverEcoscores") type = "driver-ecoscores";
    if (type === "route") type = "route";

    const { data } = await api.get(
      this.baseUrl + "/" + type + "?" + convertToQs(filter)
    );

    const ret = {
      reportType: "route",
      filter: filter,
      config: config,
      data: {},
    };

    if (type === "idling") {
      Object.keys(data).forEach((deviceId) => {
        const positions = [];
        data[deviceId].positions.map((position) => {
          positions.push(new Position().deserialize(position));
        });
        data[deviceId].positions = positions;
      });
      ret.data = data;
      return ret;
    }

    // eslint-disable-next-line array-callback-return
    let total = null;

    data.map((d) => {
      let p;
      switch (type) {
        case "driver-trips":
        case "trips":
          p = new ReportTrip().deserialize(d);
          if (p.startTime === null) p.startTime = filter.from;
          if (p.endTime === null) p.endTime = filter.to;

          // if(type === 'driver-trips') {
          //     if (total === null) {
          //         const e = new ReportTrip().deserialize(d);
          //         if (e.startTime === null)
          //             e.startTime = filter.from
          //         if (e.endTime === null)
          //             e.endTime = filter.to
          //         total = e
          //         total.driverName = "Total"
          //         total.driverUniqueId = "Total"
          //         total.startAddress = ""
          //         total.endAddress = ""
          //     } else {
          //         total.deviceName += ", " + p.deviceName
          //         if (p.maxSpeed > total.maxSpeed)
          //             total.maxSpeed = p.maxSpeed
          //         total.averageSpeed += p.averageSpeed
          //         total.distance += p.distance
          //         total.startOdometer += p.startOdometer
          //         total.endOdometer += p.endOdometer
          //         total.spentFuel += p.spentFuel
          //         total.duration += p.duration
          //     }
          // }
          break;
        case "stops":
          p = new ReportStop().deserialize(d);
          break;
        case "driver-summary":
        case "summary":
          p = new ReportSummary().deserialize(d);
          if (p.startTime === null) p.startTime = filter.from;
          if (p.endTime === null) p.endTime = filter.to;

          // p.ecoScore = EcoScoreUtils.format(p.ecoScore)

          // if (type === 'driver-summary') {
          //     if (total === null) {
          //         const e = new ReportSummary().deserialize(d);
          //         if (e.startTime === null)
          //             e.startTime = filter.from
          //         if (e.endTime === null)
          //             e.endTime = filter.to
          //         total = e
          //         total.deviceId = total.deviceName
          //         total.driverName = "Total"
          //         total.driverUniqueId = "Total"
          //     } else {
          //         if (p.startTime < total.startTime) total.startTime = p.startTime
          //         if (p.endTime > total.endTime) total.endTime = p.endTime
          //         total.deviceName += ", " + p.deviceName
          //         total.deviceId += ", " + p.deviceName
          //         if (p.maxSpeed > total.maxSpeed) total.maxSpeed = p.maxSpeed
          //         total.averageSpeed += p.averageSpeed
          //         total.distance += p.distance
          //         total.startOdometer += p.startOdometer
          //         total.endOdometer += p.endOdometer
          //         total.engineHours += p.engineHours
          //         total.spentFuel += p.spentFuel
          //     }
          // }
          break;
        case "driver-ecoscores":
        case "ecoscores":
          p = new ReportEcoScore().deserialize(d);
          EcoScoreUtils.format(p);
          if (p.startTime === null) p.startTime = filter.from;
          if (p.endTime === null) p.endTime = filter.to;

          // if (type === 'driver-ecoscores') {
          //     if (total === null) {
          //     const e = new ReportEcoScore().deserialize(d);
          //     EcoScoreUtils.format(e)
          //     if (e.startTime === null)
          //         e.startTime = filter.from
          //     if (e.endTime === null)
          //         e.endTime = filter.to
          //     total = e
          //     total.driverName = "Total"
          //     total.driverUniqueId = "Total"
          // } else {
          //     total.acceleration += p.acceleration
          //     total.braking += p.braking
          //     total.cornering += p.cornering
          //     total.distance += p.distance
          //     total.hours += p.hours
          // }
          // }
          break;
        default:
          p = new Position().deserialize(d);
      }

      if (!ret.data[p.deviceId]) {
        ret.data[p.deviceId] = [];
      }

      ret.data[p.deviceId].push(p);
    });

    // if (total !== null) {
    //     ret.total = []
    //     ret.total.push(total)
    // }

    return ret;
  }

  async reportExel(type, filter, email = false) {
    if (type === "driverTrips") type = "driver-trips";
    if (type === "driverSummary") type = "driver-summary";
    if (type === "driverEcoscores") type = "driver-ecoscores";

    const { data } = await api.get(
      this.baseUrl + "/" + type + "?" + convertToQs(filter),
      {
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
        responseType: "blob", // important
      }
    );
    if (!email) {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", type + ".xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export default new ReportsService();
