import createGenericStore from "../generic_store";
const data = {
    h: {
        key: 'h',
        name: 'sharedHourAbbreviation',
        fullName: 'sharedHour'
    }
}

const store = createGenericStore('hours_units', {
    autoLoad: true,
    initialState: {
        hours_units: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}