import React from "react";
import AttributesPage from "../Attributes";
import {Form, FormControl, FormGroup, FormLabel,} from "react-bootstrap";
import {Button} from "@mui/material"
import {t} from "../../../utils/Translator";
import {Formik} from "formik";
import * as yup from "yup";
import {Calendar} from "../../../models";
import {deepClone, isEqual} from "../../../utils/functions";
import {AppService} from "../../../services";
import Paper from '@mui/material/Paper';
import {EditingState, IntegratedEditing, ViewState} from '@devexpress/dx-react-scheduler';
import {
    AppointmentForm,
    Appointments,
    AppointmentTooltip,
    ConfirmationDialog,
    DateNavigator,
    MonthView,
    Scheduler,
    TodayButton,
    Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import CalendarModal from "./CalendarModal";
import ical from "ical-generator";
import constants from "../../../config/constants";

export default class CalendarForm extends React.Component {
    constructor(props) {
        super(props);

        this.commitChanges = this.commitChanges.bind(this);

        this.state = {
            attributesPage: false,
            data: props.item && props.item._data.attributes ? props.item._data.attributes["calendar"] ?? [] : [],
            item: new Calendar().deserialize(deepClone(props.item)),
            sending: false,
            changed: false,
            formModal: false
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
        this.openAttributesPage = this.openAttributesPage.bind(this);
        this.onAttributesCancel = this.onAttributesCancel.bind(this);
        this.onAttributesClosed = this.onAttributesClosed.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }

    save(values) {
        const item = this.state.item.deserialize(values);

        if (!item._data.attributes) item._data.attributes = {}

        item._data.attributes["calendar"] = this.state.data

        const cal = ical({name: item.name});

        for (const e of this.state.data) {
            let repeating = null;

            if (e.rRule) {
                let rules = e.rRule.substring(6).split(";")
                let rulesObj = {}
                rules.forEach(r => {
                    const s = r.split("=")
                    rulesObj[s[0]] = s[1]
                })
                repeating = {
                    freq: rulesObj["FREQ"],
                    count: rulesObj["COUNT"] ? Number(rulesObj["COUNT"]) : undefined,
                    interval: rulesObj["INTERVAL"] ? Number(rulesObj["INTERVAL"]) : undefined,
                    until: rulesObj["UNTIL"] ? new Date(rulesObj["UNTIL"]) : undefined,
                    byDay: rulesObj["BYDAY"] ? rulesObj["BYDAY"].split(",") : undefined,
                    byMonth: rulesObj["BYMONTH"] ? rulesObj["BYMONTH"].split(",") : undefined,
                    byMonthDay: rulesObj["BYMONTHDAY"] ? rulesObj["BYMONTHDAY"].split(",") : undefined,
                    bySetPos: rulesObj["BYSETPOS"] ? Number(rulesObj["BYSETPOS"]) : undefined,
                    exclude: rulesObj["EXCLUDE"] ? rulesObj["EXCLUDE"].split(",").map(it => new Date(it)) : undefined,
                    excludeTimezone: rulesObj["EXCLUDETIMEZONE"],
                    wkst: rulesObj["WKST"]
                }
            }
            cal.createEvent({
                start: e.startDate,
                end: e.endDate,
                allDay: e.allDay,
                repeating: repeating,
                summary: '',
                location: null,
                description: e.notes,
            })
        }

        const reader = new FileReader();
        reader.readAsDataURL(cal.toBlob());
        reader.onload = () => {
            item._data.data = reader.result.split(",")[1]
            this.props.onSave(item, values);
        };
        reader.onerror = error => {
            AppService.showError(error);
        };
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    getBase64(file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.state.item.data = reader.result.split(",")[1];
            this.setState({
                item: this.state.item,
                changed: true
            });
        };
        reader.onerror = error => {
            AppService.showError(error);
        };
    }

    handleFile(e) {
        const files = Array.from(e.target.files);
        this.getBase64(files[0]);
    }

    cancel() {
        this.props.onHide();
    }

    openAttributesPage() {
        this.setState({attributesPage: true});
    }

    onAttributesCancel() {
        const clone = new Calendar().deserialize(deepClone(this.props.item));
        const item = this.state.item;
        item.attributes = clone.attributes;
        this.setState({
            ...this.state,
            item,
            attributesPage: false
        });
    }

    onAttributesClosed() {
        this.setState({
            ...this.state,
            attributesPage: false,
            changed: !isEqual(this.state.item.attributes, this.props.item.attributes)
        });
    }

    componentWillReceiveProps(newProps, newState) {
        this.setState({
            item: new Calendar().deserialize(deepClone(newProps.item))
        });
    }

    showForm() {
        this.setState({formModal: true}, () => {
            this.forceUpdate();
        });
    }

    hideForm() {
        this.setState({
            ...this.state,
            formModal: false,
            changed: !isEqual(this.state.item.event, this.props.item.event)
        });
    }

    makeICSfile(event) {

    }

    commitChanges({added, changed, deleted}) {
        this.setState((state) => {
            let {data} = state;
            if (added) {
                const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
                data = [...data, {id: startingAddedId, ...added}];
            }
            if (changed) {
                data = data.map(appointment => (
                    changed[appointment.id] ? {...appointment, ...changed[appointment.id]} : appointment));
            }
            if (deleted !== undefined) {
                data = data.filter(appointment => appointment.id !== deleted);
            }
            return {data: data, changed: true};
        });
    }

    render() {
        const {sending, item, data} = this.state;

        const schema = yup.object({
            name: yup.string().required()
        });

        const TimeTableCell = ({onDoubleClick, ...restProps}) => {
            return <MonthView.TimeTableCell onClick={onDoubleClick} {...restProps} />;
        };

        const DateEditor = ({locale, ...restProps}) => {
            const dateFormat =
                // Server.twelveHourFormat
                // ? constants.dateTimeFormat12 :
                constants.dateTimeFormat24;
            return (
                <AppointmentForm.DateEditor
                    {...restProps}
                    format={dateFormat}
                    locale={AppService.locale}
                />
            );
        };

        const today = {
            today: t("reportToday")
        }

        const appoint = {
            allDayLabel: t("allDay"),
            titleLabel: t("title"),
            moreInformationLabel: t("More Information"),
            repeatLabel: t("repeat"),
            notesLabel: t("Notes"),
            never: t("Never"),
            daily: t("Daily"),
            weekly: t("Weekly"),
            monthly: t("Monthly"),
            yearly: t("Yearly"),
            repeatEveryLabel: t("Repeat every"),
            daysLabel: t("day(s)"),
            endRepeatLabel: t("End repeat"),
            onLabel: t("On"),
            afterLabel: t("After"),
            occurrencesLabel: t("Occurrences"),
            weeksOnLabel: t("week(s) on:"),
            monthsLabel: t("month(s)"),
            ofEveryMonthLabel: t("of every month"),
            theLabel: t("The"),
            firstLabel: t("First"),
            secondLabel: t("Second"),
            thirdLabel: t("Third"),
            fourthLabel: t("Fourth"),
            lastLabel: t("Last"),
            yearsLabel: t("year(s)"),
            ofLabel: t("of"),
            everyLabel: t("Every"),
            detailsLabel: t("Details"),
        }

        return (
            <React.Fragment>
                {this.state.attributesPage ? (
                    <AttributesPage
                        item={item}
                        type="driversAttributes"
                        onHide={() => this.onAttributesClosed()}
                        onSave={() => this.onAttributesClosed()}
                        onCancel={() => this.onAttributesCancel()}
                    />
                ) : null}
                {this.state.formModal ? (
                    <CalendarModal
                        show={true}
                        onHide={() => this.hideForm()}
                    />
                ) : null}

                <div className="container-wrapper">
                    <Formik
                        validationSchema={schema}
                        onSubmit={this.save}
                        disabled={sending}
                        initialValues={{
                            name: item.name,
                            file: ""
                        }}
                    >
                        {({
                              values,
                              handleSubmit,
                              handleChange,
                              dirty,
                              isSubmitting,
                              errors,
                              isValid
                          }) => {
                            return (
                                <Form
                                    onSubmit={handleSubmit}
                                    noValidate
                                    className="d-flex flex-column h-100 flex-grow-1 form form-sm "
                                >
                                    <React.Fragment>
                                        <div className="flex-grow-1 pt-3 overflow">
                                            <div className="container ">
                                                <React.Fragment>
                                                    <h3 className="internal-title">
                                                        <i className="mdi mdi-calendar"></i>{" "}
                                                        {this.state.item.id
                                                            ? t("sharedEdit")
                                                            : t("sharedAdd")}{" "}
                                                        {t("sharedCalendar")}
                                                    </h3>
                                                </React.Fragment>
                                                <FormGroup>
                                                    <FormLabel>{t("sharedName")}</FormLabel>
                                                    <FormControl
                                                        type="text"
                                                        name="name"
                                                        defaultValue={values.name}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.name}
                                                    />
                                                </FormGroup>
                                                <FormGroup>
                                                    {/*<FormLabel>{t("sharedFile")}</FormLabel>*/}
                                                    {/*<FormControl*/}
                                                    {/*  type="file"*/}
                                                    {/*  name="file"*/}
                                                    {/*  onChange={this.handleFile}*/}
                                                    {/*  isInvalid={!!errors.file}*/}
                                                    {/*/>*/}
                                                    <Paper>
                                                        <Scheduler
                                                            data={data}
                                                            height={660}
                                                            locale={AppService.locale}
                                                        >
                                                            <ViewState/>
                                                            <EditingState
                                                                onCommitChanges={this.commitChanges}
                                                            />
                                                            <IntegratedEditing/>
                                                            <MonthView
                                                                timeTableCellComponent={TimeTableCell}
                                                            />
                                                            <Toolbar/>
                                                            <DateNavigator/>
                                                            <TodayButton
                                                                messages={today}
                                                            />
                                                            <ConfirmationDialog/>
                                                            <Appointments/>
                                                            <AppointmentTooltip
                                                                showOpenButton
                                                                showDeleteButton
                                                            />
                                                            <AppointmentForm
                                                                messages={appoint}
                                                                dateEditorComponent={DateEditor}
                                                            />
                                                        </Scheduler>
                                                    </Paper>
                                                </FormGroup>
                                            </div>
                                        </div>
                                        <footer className="footer">
                                            <div className="container d-flex">
                                                <div className="flex-grow-1 d-flex"/>
                                                {/*{App.App.user.administrator ?*/}
                                                {/*    <div className="flex-grow-1 d-flex">*/}
                                                {/*        <Button*/}
                                                {/*            variant="secondary"*/}
                                                {/*            className="align-self-stretch"*/}
                                                {/*            onClick={() => this.openAttributesPage()}*/}
                                                {/*        >*/}
                                                {/*            <i className="mdi mdi-playlist-play"></i>{" "}*/}
                                                {/*            {t("sharedAttributes")}*/}
                                                {/*        </Button>*/}
                                                {/*    </div>*/}
                                                {/*    : null}*/}
                                                <Button
                                                    variant="contained" size="large"
                                                    className="btn-red mr-2"
                                                    onClick={() => this.cancel()}
                                                >
                                                    <i className="mdi mdi-close"></i> {t("sharedCancel")}
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained" size="large" className="btn-blue"
                                                    disabled={
                                                        !this.state.changed &&
                                                        (!dirty || !isValid)
                                                    }
                                                >
                                                    <i className="mdi mdi-content-save"></i>{" "}
                                                    {t("sharedSave")}
                                                </Button>
                                            </div>
                                        </footer>
                                    </React.Fragment>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </React.Fragment>
        );
    }
}
