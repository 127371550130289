import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AppHeader from '../components/AppHeader';
import ConnectionStatus from '../components/General/ConnectionStatus';
import CrudPages from '../components/Crud';

export class DefaultLayout extends React.Component {

    componentWillMount() {
    }

    render() {
        return (
            <div id="layoutWrapper">
                <AppHeader />
               
                <div id="overlayLogo">
                    <img src="img/logo-dark.svg" alt="IQTrack" height="30px" />
                </div>
                <div id="contentWrapper">
                    {this.props.children}
                </div>
               <ConnectionStatus />
               <CrudPages />
            </div>
        );
    }
}

DefaultLayout.propTypes = {
    //dispatch: PropTypes.func.isRequired,
    children: PropTypes.object
};

const mapStateToProps = state => ({
    online: state.app.online
})

const mapDispatchToProps = dispatch => ({
    /*increment: () => dispatch(increment()),
    decrement: () => dispatch(decrement()),*/
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)