import { BaseModel } from "./BaseModel";
import { App } from "../App";

const fields = [{
    name: 'type',
    type: 'string',
    isPk: true,
}, {
    name: 'name',
    convert: (v, rec) => {
        return App.AttributeFormatter.getFormatter('commandType')(rec.type);
        //return Traccar.AttributeFormatter.getFormatter('commandType')(rec.get('type'));
    }
}, {
    name: 'parameters'
}];

export class KnownCommand extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}