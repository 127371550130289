import React from 'react';

import AttributeFormatter from './utils/traccar/AttributeFormatter';
import CoordinateFormatsConverter from './utils/traccar/CoordinateFormatsConverter';
import DistanceUnitsConverter from './utils/traccar/DistanceUnitsConverter';
import HoursUnitsConverter from './utils/traccar/HoursUnitsConverter';
import SpeedUnitsConverter from './utils/traccar/SpeedUnitsConverter';
import VolumeUnitsConverter from './utils/traccar/VolumeUnitsConverter';

import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { App } from './App';
import AppLoader from './components/AppLoader';
import { WebRoutes } from './routes';
import { AppService, SessionService } from './services';
import { history, store } from './store';
import app from './store/app';
import PositionAttributes from './utils/traccar/PositionAttributes';
import TimeUnitsConverter from './utils/traccar/TimeUnitsConverter';

class Root extends React.Component {

    constructor(props) {
        super(props);


        App.Store = store;
        App.App = AppService;
        App.DistanceUnitsConverter = DistanceUnitsConverter;
        App.VolumeUnitsConverter = VolumeUnitsConverter;
        App.HoursUnitsConverter = HoursUnitsConverter;
        App.SpeedUnitsConverter = SpeedUnitsConverter;
        App.CoordinateFormatsConverter = CoordinateFormatsConverter;
        App.AttributeFormatter = AttributeFormatter;
        App.PositionAttributes = PositionAttributes;
        App.TimeUnitsConverter = TimeUnitsConverter;
    }

    /**
     * All initial data is loading here
     */
    async componentWillMount() {


        //prepare sounds
        document.addEventListener('click', function enableSound() {
            document.removeEventListener('click', enableSound, false);
            AppService.loadSound('sounds/beep.wav');
        }, false);


        AppService.prepare();

        window.onbeforeunload = () => {
            store.dispatch({ type: app.types.LOADING })
        }

        try {
            if (navigator.userAgent.indexOf('iPhone') !== -1 || navigator.userAgent.indexOf('Android') !== -1) {
                window.addEventListener("load", () => {
                    setTimeout(() => window.scrollTo(0, 1), 0);
                }, false);
            }
        } catch (ex) {

        }

        //load the lang parameters
        //try to get from url
        const url = new URLSearchParams(document.location.search);
        let requestLang = url.get('lang');
        if (!requestLang) {
            requestLang = AppService.getLocalPreference('language');
            if (!requestLang) {
                requestLang = navigator.language || navigator.userLanguage;
            }
        }

        let token = url.get('token');
        if (token !== undefined && token !== null) {
            try {
                const user = await SessionService.tokenSession(token)
                console.log("TOKEN USER", user)
                url.delete('token')
                history.replace({
                    search: url.toString(),
                })
            }
            catch (e) {
                console.log("TOKEN USER", e)
            }
        }

        AppService.locale = requestLang;

        await AppService.init();
    }

    render() {
        const { loading, loaded, locale, messages } = this.props.app;
        return (
            <div id="appWrapper">
                <React.Fragment>

                    <AppLoader className={!loading ? 'hidden' : 'active'} />
                    {loaded ?
                        <ConnectedRouter history={history}>
                            {WebRoutes(store)}
                        </ConnectedRouter> : null}
                </React.Fragment>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    app: state.app
})

const mapDispatchToProps = dispatch => ({
    /*increment: () => dispatch(increment()),
    decrement: () => dispatch(decrement()),*/
})

export default connect(mapStateToProps, mapDispatchToProps)(Root)