import { t } from "../Translator";
import { App } from "../../App";

export class DistanceUnitsConverter {
    convertValue(value, unit, back = false) {
        if (!unit) {
            unit = 'km';
        }
        const model = App.Store.getState().distance_units.distance_units[unit];


        return (back ? value / model.factor : value * model.factor).toFixed(0);
    }

    formatValue(value, unit, convert) {
        if (!unit) {
            unit = 'km';
        }
        const model = App.Store.getState().distance_units.distance_units[unit];
        return (convert ? this.convertValue(value, unit) : value) + ' ' + t(model.name);
    }
}

export default new DistanceUnitsConverter();