import { BaseModel } from "./BaseModel";

export const fields = [{
    name: 'id',
    type: 'int'
}, {
    name: 'registration',
    type: 'boolean'
}, {
    name: 'readonly',
    type: 'boolean'
}, {
    name: 'deviceReadonly',
    type: 'boolean'
}, {
    name: 'limitCommands',
    type: 'boolean'
}, {
    name: 'map',
    type: 'string'
}, {
    name: 'bingKey',
    type: 'string'
}, {
    name: 'mapUrl',
    type: 'string'
}, {
    name: 'latitude',
    type: 'float'
}, {
    name: 'longitude',
    type: 'float'
}, {
    name: 'zoom',
    type: 'int'
}, {
    name: 'twelveHourFormat',
    type: 'boolean'
}, {
    name: 'forceSettings',
    type: 'boolean'
}, {
    name: 'coordinateFormat',
    type: 'string'
}, {
    name: 'poiLayer',
    type: 'string'
}, {
    name: 'attributes'
}];

/**
 * Server info model (singleton)
 */
export class Server extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
    /*
    get mapConfig() {
        let config = maps.services[this.map] || maps.services['osm'];
        if (this.map === 'custom') {
            config = { url: this.mapUrl, subdomains: [] }
        }

        config.zoom = this.zoom || maps.defaultZoom;
        config.center = (this.latitude !== 0 && this.longitude !== 0) ? [this.latitude, this.longitude] : maps.defaultCenter;
        return config;
    }*/
}

export default new Server();