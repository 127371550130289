import React from 'react';
import { CompactPicker, SketchPicker } from 'react-color'

export default class CustomColorField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: props.displayColorPicker || false,
            currentColor: props.value || '#EEEEEE',
            pickerType: props.pickerType || 'compact',
        }
        this.toggleColorPicker = this.toggleColorPicker.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(newProps) {
        this.setState({
            displayColorPicker: newProps.displayColorPicker || false,
            currentColor: newProps.value || this.state.color,
        })
    }

    toggleColorPicker() {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    }

    getPicker() {
        switch (this.state.pickerType) {
            case 'full':
                return SketchPicker
            default:
                return CompactPicker
        }
    }

    handleChange(color) {
        this.setState({ currentColor: color.hex })
        if (this.props.onChange) {
            this.props.onChange(color.hex);
        }
    };

    render() {
        const Picker = this.getPicker();
        return <div className={"color-field " + (this.state.displayColorPicker ? 'active' : '')}>
            <div className="current" onClick={this.toggleColorPicker} style={{ background: this.state.currentColor }}>{this.state.currentColor}</div>
            {
                this.state.displayColorPicker ? <div className="popup"><Picker onChange={this.handleChange} color={this.state.currentColor} /></div> : null
            }
        </div>;
    }
}