import React from 'react';
import { connect } from 'react-redux';
import { t } from '../../utils/Translator';

class ConnectionStatus extends React.PureComponent {
    render() {
        return (
            <div className={'connection-status shadow-lg ' + (this.props.status )}>
                {this.props.online ? t('deviceStatusOnline') : t('deviceStatusOffline')}
            </div>
        )
    }
}


const mapStateToProps = state => ({
    online: state.app.online,
    status: state.app.status
})

const mapDispatchToProps = dispatch => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionStatus)