import createGenericStore from "../generic_store";
import { t } from '../../utils/Translator';
import constants from "../../config/constants";

const data = [{
    key: 'web.liveRouteLength',
    name: t('attributeWebLiveRouteLength'),
    valueType: 'number',
    allowDecimals: false
}, {
    key: 'web.selectZoom',
    name: t('attributeWebSelectZoom'),
    valueType: 'number',
    allowDecimals: false,
    minValue: constants.mapDefaultZoom,
    maxValue: constants.mapMaxZoom
}, {
    key: 'web.maxZoom',
    name: t('attributeWebMaxZoom'),
    valueType: 'number',
    allowDecimals: false,
    minValue: constants.mapDefaultZoom,
    maxValue: constants.mapMaxZoom
}, {
    key: 'ui.disableReport',
    name: t('attributeUiDisableReport'),
    valueType: 'boolean'
}, {
    key: 'ui.disableEvents',
    name: t('attributeUiDisableEvents'),
    valueType: 'boolean'
}, {
    key: 'ui.disableVehicleFetures',
    name: t('attributeUiDisableVehicleFetures'),
    valueType: 'boolean'
}, {
    key: 'ui.disableDrivers',
    name: t('attributeUiDisableDrivers'),
    valueType: 'boolean'
}, {
    key: 'ui.disableComputedAttributes',
    name: t('attributeUiDisableComputedAttributes'),
    valueType: 'boolean'
}, {
    key: 'ui.disableCalendars',
    name: t('attributeUiDisableCalendars'),
    valueType: 'boolean'
}, {
    key: 'ui.disableMaintenance',
    name: t('attributeUiDisableMaintenance'),
    valueType: 'boolean'
}, {
    key: 'ui.hidePositionAttributes',
    name: t('attributeUiHidePositionAttributes'),
    valueType: 'string'
}, {
    key: 'distanceUnit',
    name: t('settingsDistanceUnit'),
    valueType: 'string',
    dataType: 'distanceUnit'
}, {
    key: 'speedUnit',
    name: t('settingsSpeedUnit'),
    valueType: 'string',
    dataType: 'speedUnit'
}, {
    key: 'volumeUnit',
    name: t('settingsVolumeUnit'),
    valueType: 'string',
    dataType: 'volumeUnit'
}, {
    key: 'timezone',
    name: t('sharedTimezone'),
    valueType: 'string',
    dataType: 'timezone'
}]


//custom
data.push({
    key: 'web.enableStreetView',
    name: t('attributeWebEnableStreetView'),
    valueType: 'boolean'
},
    {
        key: 'web.streetViewApiKey',
        name: t('attributeWebStreetViewApiKey'),
        valueType: 'string'
    })

const attr_data = {};


Object.values(data).map(o => {
    attr_data[o.key] = o;
})

const store = createGenericStore('common_user_attributes', {
    autoLoad: true,
    initialState: {
        common_user_attributes: attr_data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}