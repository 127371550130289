import constants from "../../config/constants";

export const types = {
    LOADED: 'app/LOADED',
    UNLOADED: 'app/UNLOADED',
    LOADING: 'app/LOADING',
    ERROR: 'app/ERROR',
    CONNECTION_CHANGED: 'app/CONNECTION_CHANGED',

    MAP_FULL_SCREEN: 'app/MAP_FULL_SCREEN',
    MAP_SHOW_ROUTES: 'app/MAP_SHOW_ROUTES',
    MAP_SHOW_DEVICES: 'app/MAP_SHOW_DEVICES',
    MAP_SHOW_NAMES: 'app/MAP_SHOW_NAMES',
    MAP_FIT_OBJECTS: 'app/MAP_FIT_OBJECTS',
    MAP_SHOW_GEOFENCES: 'app/MAP_SHOW_GEOFENCES',
    MAP_CONFIG: 'app/MAP_CONFIG',
    MAP_OPTIONS_CHANGE: 'app/MAP_OPTIONS_CHANGE',
    MAP_OPTIONS_LOADED: 'app/MAP_OPTIONS_LOADED',

    SET_NOTIFICATIONS_SETTINGS: 'app/SET_NOTIFICATIONS_SETTINGS',

    MAP_STATE_CHANGED: 'app/MAP_STATE_CHANGED',

    SHOW_MODAL: 'app/SHOW_MODAL',
    HIDE_MODAL: 'app/HIDE_MODAL',

}

export const initialState = {
    locale: 'en',
    messages: {},
    loading: true,
    loaded: false,
    online: false,
    status: 'offline',
    modal: null,
    mapstate: {
        options: {
            is_full_screen: constants.mapTypes[constants.selectedMapType].mapOptions.is_full_screen.value,
            show_routes: constants.mapTypes[constants.selectedMapType].mapOptions.show_routes.value,
            show_devices: constants.mapTypes[constants.selectedMapType].mapOptions.show_devices.value,
            show_names: constants.mapTypes[constants.selectedMapType].mapOptions.show_names.value,
            fit_objects: constants.mapTypes[constants.selectedMapType].mapOptions.fit_objects.value,
            show_geofences: constants.mapTypes[constants.selectedMapType].mapOptions.show_geofences.value,
            cluster_enabled: constants.mapTypes[constants.selectedMapType].mapOptions.cluster_enabled.value,
            follow_devices: constants.mapTypes[constants.selectedMapType].mapOptions.follow_devices.value,
        },
        map_config: constants.mapTypes[constants.selectedMapType].mapServices['default'],
        latitude: constants.mapDefaultLat,
        longitude: constants.mapDefaultLon,
        zoom: constants.mapDefaultZoom
    },
    notificationstate: {
        soundEnabled: constants.notifications.soundEnabled,
        enabled: constants.notifications.enabled
    },
}

export const actions = {
    loaded(payload) {
        return {
            type: types.LOADED,
            payload: payload,
        }
    },
    unloaded() {
        return {
            type: types.UNLOADED
        }
    },
    loading() {
        return {
            type: types.LOADING
        }
    },
    connectionChanged(status) {
        return {
            type: types.CONNECTION_CHANGED,
            payload: status
        }
    },
    setNotificationsSettings(payload) {
        return {
            type: types.SET_NOTIFICATIONS_SETTINGS,
            payload: payload
        }
    },

    mapStateChanged(state) {
        return {
            type: types.MAP_STATE_CHANGED,
            payload: state
        }
    },

    showModal(config, props) {
        return {
            type: types.SHOW_MODAL,
            payload: { config, props }
        }
    },

    hideModal() {
        return {
            type: types.HIDE_MODAL
        }
    }
}

export const reducers = (state = initialState, action) => {
    switch (action.type) {
        case types.HIDE_MODAL: {
            return {
                ...state,
                modal: null
            }
        }
        case types.SHOW_MODAL: {
            return {
                ...state,
                modal: Object.assign({}, action.payload)
            }
        }
        case types.LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                locale: action.payload.locale || state.messages,
                messages: action.payload.messages || state.messages,
            };
        case types.CONNECTION_CHANGED:
            return {
                ...state,
                online: action.payload === 'online',
                status: action.payload
            }
        case types.UNLOADED:
            return {
                ...state,
                loading: true,
                loaded: false,
            }
        case types.LOADING:
            return {
                ...state,
                loading: true,
                loaded: false
            }
        case types.SET_NOTIFICATIONS_SETTINGS: {
            return {
                ...state,
                notificationstate: {
                    soundEnabled: action.payload.soundEnabled,
                    enabled: action.payload.enabled
                }
            }
        }
        case types.MAP_OPTIONS_LOADED:
            const newstate = {
                ...state,

            };
            newstate.mapstate.options = {};
            Object.keys(action.payload).map(i => {
                newstate.mapstate.options[i] = action.payload[i].value;
            });
            return newstate
        case types.MAP_OPTIONS_CHANGE:
            const options = Object.assign({}, state.mapstate.options);
            Object.keys(options).map(i => {
                options[i] = action.payload[i];
            })
            return {
                ...state,
                mapstate: {
                    ...state.mapstate,
                    options: options
                }
            }
        case types.MAP_STATE_CHANGED:
            return {
                ...state,
                mapstate: {
                    ...state.mapstate,
                    zoom: action.payload.zoom,
                    latitude: action.payload.latitude,
                    longitude: action.payload.longitude
                }
            }
        case types.MAP_CONFIG:
            return {
                ...state,
                mapstate: {
                    ...state.mapstate,
                    map_config: action.payload.config
                }
            }
        case types.MAP_SHOW_ROUTES:
            return {
                ...state,
                mapstate: {
                    ...state.mapstate,
                    show_routes: action.payload
                }
            }
        case types.MAP_FULL_SCREEN:
            return {
                ...state,
                mapstate: {
                    ...state.mapstate,
                    is_full_screen: action.payload
                }
            }
        case types.MAP_OPTIONS_CHANGE:
            return {
                ...state,
                mapstate: action.payload
            }
        default:
            return state
    }
}

export default {
    types,
    reducers,
    actions,
    state: initialState
}