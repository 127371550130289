import _ from 'lodash';
import {isArray} from 'util';

String.prototype.ucFirst = function () {
    if (this.length === 0)
        return
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ellipsisEnd = function (size) {
    if (size === undefined)
        size = 15

    return String(this.substring(0, this.length > size ? size : this.length).concat(this.length > size ? "..." : ""))
};

Array.prototype.delete = function (value, comparator = null) {
    if (comparator) {
        try {
            this.map(i => {
                if (comparator(i)) {
                    this.splice(i, 1);
                }
            });
        } catch (ex) {
        }

    } else {
        try {
            this.find(k => k === value).map(i => {
                this.splice(i, 1);
            });
        } catch (ex) {
        }
    }

}

export const getRandomId = () => {
    let result = '';
    let characters = '0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < 15; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export const hexToRGB = (hex, alpha) => {
    if (!hex) {
        hex = '#ff0000';
    }
    let r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}

export const extend = (ns, ns_string) => {
    const parts = ns_string.split('.');
    let pl, i;
    if (parts[0] == "myApp") {
        parts = parts.slice(1);
    }
    pl = parts.length;
    for (i = 0; i < pl; i++) {
        //create a property if it doesnt exist
        if (typeof ns[parts[i]] == 'undefined') {
            ns[parts[i]] = {};
        }
        ns = ns[parts[i]];
    }
    return ns;
}
export const getAttr = (obj, is, value) => {
    if (typeof is == 'string')
        return getAttr(obj, is.split('.'), value);
    else if (is.length == 1 && value !== undefined)
        return obj[is[0]] = value;
    else if (is.length == 0)
        return obj;
    else
        return getAttr(obj[is[0]], is.slice(1), value);
}

export const setAttr = (obj, key, value) => {
    extend(obj, key);
    getAttr(obj, key, value);
}

export const encodeBase64 = (str) => {
    return btoa(str);
}

export const decodeBase64 = (str) => {
    return atob(str);
}

export const convertToQs = (data) => {
    const ret = [];
    Object.keys(data).map(key => {
        let values = data[key];
        if (isArray(values)) {
            values.map(v => {
                if (v != '' && v != null && v != undefined) {
                    ret.push(`${key}=${encodeURIComponent(v)}`)
                }
            })
        } else {
            const v = data[key];
            if (v != '' && v != null && v != undefined) {
                ret.push(`${key}=${encodeURIComponent(v)}`);
            }
        }
    })
    return ret.filter(v => v != null && v != '').join('&');
}

export const clone = (obj) => {
    return Object.create(
        Object.getPrototypeOf(obj),
        Object.getOwnPropertyDescriptors(obj)
    );
}

export const deepClone = (obj) => {
    return _.cloneDeep(obj)
}

export const isEqual = (obj1, obj2) => {
    return _.isEqual(obj1, obj2);
}

export function upperCaseFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}