import React from 'react';
import {ListGroup, Button, Row} from 'react-bootstrap';
import {t} from '../../../utils/Translator';
import TextFilter from '../../Controls/TextFilter';
import CustomCheckbox from '../../Controls/CustomCheckBox';
import {App} from '../../../App';
import {store} from '../../../store';
import moment from "moment";
import constants from "../../../config/constants";
import {MDBDataTable} from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import Pencil from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";


export default class List extends React.Component {

    static deleteEvent = false

    constructor(props) {
        super(props);
        this.state = {
            filter: null,
            selectedItems: props.selectedItems || [],
            allSelected: false,
        }
        this.deleteItem = this.deleteItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.toggleItem = this.toggleItem.bind(this);
        this.toggleAll = this.toggleAll.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.unselectAll = this.unselectAll.bind(this);

        this.columns = [
            {
                label: t("sharedName"),
                field: "name",
                sort: 'asc',
                height: 80
            },
            {
                label: t("sharedType"),
                field: "type",
                sort: 'asc',
            },
            {
                label: t("maintenanceStart"),
                field: "start",
                sort: 'asc',
            },
            {
                label: t("maintenancePeriod"),
                field: "period",
                sort: 'asc',
            },
            {
                label: t("Date"),
                field: "attributes.date",
                sort: 'asc',
            },
            {
                label: t("repeat"),
                field: "attributes.repeatable",
                sort: 'asc',
            },
        ]


        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;

        if (enableDelete)
            this.columns.push({
                label: t("actions"),
                field: "sharedRemove",
                sort: "disabled",
            })

        const enableSelection = this.props.enableSelection !== undefined ? this.props.enableSelection : false;

        if (enableSelection)
            this.columns.splice(0, 0, {
                label: "",
                field: "checkBox",
                sort: "disabled",
            })


        // this.columns = [{
        //     text: t('sharedName'),
        //     dataField: 'name',
        //     sort: true,
        //     formatter: function (cell, row, rowIndex) {
        //         return t(String(row.name));
        //     },
        //     headerFormatter: function () {
        //         return <div>{t('sharedName')}</div>;
        //     },
        //     style: {
        //         width: '20%',
        //         fontSize: 13,
        //         fontWeight: 'bold',
        //         wordBreak: 'break-word',
        //         borderRightStyle: 'hidden',
        //         cursor: 'pointer'
        //     },
        //     headerStyle: {
        //         width: '20%',
        //         fontSize: 13,
        //         wordBreak: 'break-word',
        //         borderRightStyle: 'hidden',
        //
        //     }
        // },
        //     {
        //         text: t('sharedType'),
        //         dataField: 'type',
        //         sort: true,
        //         formatter: function (cell, row, rowIndex) {
        //             if (row.type && row.type.length > 0) {
        //                 const split = row.type.split(",")
        //                 console.log("SPLIT SIZE", split)
        //                 if (split.length === 1)
        //                     return t(row.getFormattedProperty('type'));
        //
        //                 let name = ""
        //                 split.forEach(e => {
        //                     if (name === "")
        //                         name = t(String(e.trim()))
        //                     else
        //                         name = name + ", ".concat(t(String(e.trim())))
        //                 })
        //                 return name;
        //             }
        //
        //             return t(row.getFormattedProperty('type'));
        //         },
        //         headerFormatter: function () {
        //             return <div>{t('sharedType')}</div>;
        //         },
        //         style: {
        //             width: '30%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             overflowX: "auto",
        //             borderRightStyle: 'hidden',
        //             cursor: 'pointer'
        //         },
        //         headerStyle: {
        //             width: '30%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderRightStyle: 'hidden'
        //         }
        //     }, {
        //         text: t('maintenanceStart'),
        //         dataField: 'start',
        //         sort: true,
        //         formatter: function (cell, row, rowIndex) {
        //             return row.type === 'date' ? "" : App.AttributeFormatter
        //                 .renderAttribute(row.start,
        //                     store.getState().maintenance_types.maintenance_types[row.type]);
        //         },
        //         headerFormatter: function () {
        //             return <div>{t('maintenanceStart')}</div>;
        //         },
        //         style: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderBottomStyle: 'hidden',
        //             borderLeftStyle: 'hidden',
        //             borderRightStyle: 'hidden',
        //             cursor: 'pointer'
        //         },
        //         headerStyle: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word'
        //         }
        //     }, {
        //         text: t('maintenancePeriod'),
        //         dataField: 'period',
        //         sort: true,
        //         formatter: function (cell, row, rowIndex) {
        //             return row.type === 'date' ? "" : App.AttributeFormatter.renderAttribute(row.period, store.getState().maintenance_types.maintenance_types[row.type]);
        //         },
        //         headerFormatter: function () {
        //             return <div>{t('maintenancePeriod')}</div>;
        //         },
        //         style: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderBottomStyle: 'hidden',
        //             borderLeftStyle: 'hidden',
        //             borderRightStyle: 'hidden',
        //             cursor: 'pointer'
        //         },
        //         headerStyle: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word'
        //         }
        //     }, {
        //         text: t('Date'),
        //         dataField: 'attributes.date',
        //         sort: true,
        //         formatter: function (cell, row, rowIndex) {
        //             return row.type === 'date' ? row.attributes["date"] ? moment(new Date(row.attributes["date"])).format(constants.dateFormat) : "" : "";
        //         },
        //         headerFormatter: function () {
        //             return <div>{t('Date')}</div>;
        //         },
        //         style: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderBottomStyle: 'hidden',
        //             borderRightStyle: 'hidden',
        //             cursor: 'pointer'
        //         },
        //         headerStyle: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderRightStyle: 'hidden'
        //         }
        //     },
        //     {
        //         text: t('Date'),
        //         dataField: 'attributes.repeatable',
        //         sort: true,
        //         formatter: function (cell, row, rowIndex) {
        //             return row.type === 'date' ? row.attributes['repeatable'] === true?t('Yes'):t('No') : "";
        //         },
        //         headerFormatter: function () {
        //             return <div>{t('Repeat')}</div>;
        //         },
        //         style: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderBottomStyle: 'hidden',
        //             borderRightStyle: 'hidden',
        //             cursor: 'pointer'
        //         },
        //         headerStyle: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderRightStyle: 'hidden'
        //         }
        //     }
        // ]
        //
        // const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;
        //
        // if (enableDelete)
        //     this.columns.push({
        //         text: t('sharedRemove'),
        //         dataField: 'sharedRemove',
        //         sort: true,
        //         formatter: (cell, row, rowIndex, formatExtraData) => {
        //             return <Button variant="default" className="text-danger" onClick={() => {
        //                 this.deleteItem(row.pk)
        //                 List.deleteEvent = true;
        //             }}><i className="mdi mdi-delete"></i></Button>
        //         },
        //         formatExtraData: this,
        //         headerFormatter: function () {
        //             return <div>{t('sharedRemove')}</div>;
        //         },
        //         style: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word',
        //             borderBottomStyle: 'hidden',
        //             borderLeftStyle: 'hidden',
        //             cursor: 'pointer'
        //         },
        //         headerStyle: {
        //             width: '20%',
        //             fontSize: 13,
        //             wordBreak: 'break-word'
        //         }
        //     });
    }

    deleteItem(id) {
        if (this.props.onDelete) {
            this.props.onDelete(id);
        }
    }

    selectItem(id) {
        this.toggleItem(id, () => {
            if (this.props.onSelect) {
                this.props.onSelect(id, this.isItemSelected(id));
            }
        });

    }

    setFilter(value) {
        if (value !== this.state.filter) {
            this.setState({filter: value})
        }
    }

    filterValues(values) {
        if (this.state.filter && this.state.filter !== '') {
            return Object.values(values).filter(o => o.name.toLowerCase().includes(this.state.filter.toLowerCase()))
        }
        return Object.values(values)
    }

    toggleItem(id, callback) {
        const selectionMode = this.props.selectionMode !== undefined ? this.props.selectionMode : 'multiple';
        if (selectionMode !== 'multiple') {
            this.setState({selectedItems: []});
        }
        let selectedItems = this.state.selectedItems;
        if (this.isItemSelected(id)) {
            selectedItems = selectedItems.filter(o => o !== id);
        } else {
            selectedItems.push(id);
        }
        this.setState({selectedItems: selectedItems}, callback);
    }

    isItemSelected(id) {
        return (this.state.selectedItems ? this.state.selectedItems.includes(id) : false);
    }

    selectAll() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({selectedItems: selectedItems, allSelected: true}, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    unselectAll() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const selectedItems = [];
        values.map(v => {
            selectedItems.push(v.id);
        });
        this.setState({selectedItems: [], allSelected: false}, () => {
            selectedItems.map(id => {
                this.props.onSelect(id, this.isItemSelected(id));
            })
        });
    }

    toggleAll() {
        if (this.state.allSelected) {
            this.unselectAll();
        } else {
            this.selectAll();
        }
    }

    render() {
        const {items} = this.props;
        const values = this.filterValues(items).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        const enableFilter = this.props.enableFilter !== undefined ? this.props.enableFilter : true;
        const enableDelete = this.props.enableDelete !== undefined ? this.props.enableDelete : false;
        const enableSelection = this.props.enableSelection !== undefined ? this.props.enableSelection : false;

        const rows = []

        values.forEach(row => {
            let type = ""
            if (row.type && row.type.length > 0) {
                const split = row.type.split(",")

                if (split.length === 1)
                    type = t(row.getFormattedProperty('type'));
                else {
                    let name = ""
                    split.forEach(e => {
                        if (name === "")
                            name = t(String(e.trim()))
                        else
                            name = name + ", ".concat(t(String(e.trim())))
                    })
                    type = name;
                }
            } else type = t(row.getFormattedProperty('type'));

            rows.push(
                {
                    name:  <div searchvalue={row.name.ucFirst()}>{
                        !row.name?"":t(String(row.name)).ellipsisEnd()
                    }</div>,
                    type: type,
                    start: <div searchvalue={row.type === 'date' ? "" : row.start ?? 0}>{
                        !String(row.type).includes('odometer') ? "" : App.AttributeFormatter.renderAttribute(row.start, store.getState().maintenance_types.maintenance_types['odometer'])
                    }</div>,
                    period: <div searchvalue={row.type === 'date' ? "" : row.period ?? 0}>{
                        !String(row.type).includes('odometer') ? "" : App.AttributeFormatter.renderAttribute(row.period, store.getState().maintenance_types.maintenance_types['odometer'])
                    }</div>,
                    "attributes.date":  <div searchvalue={row.type === 'date' ? "" : row.attributes["date"] ?? 0}>{
                        String(row.type).includes('date') ? row.attributes["date"] ? moment(new Date(row.attributes["date"])).format(constants.dateFormat) : "" : ""
                    }</div>,
                    "attributes.repeatable": String(row.type).includes('date') ? row.attributes['repeatable'] === true ? t('Yes') : t('No') : "",
                    "sharedRemove":
                        <div className="flexbox-container"
                             style={{
                                 display: "flex",
                                 flexDirection: "row"
                             }}>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="primary" className="p-0 ml-3 mr-4 mt-0 mb-0" onClick={() => {
                                if (List.deleteEvent) {
                                    List.deleteEvent = false;
                                    return
                                }
                                this.selectItem(row.pk)
                            }}><Pencil color="primary" fontSize="small"></Pencil></IconButton>
                            <IconButton style={{
                                border: "none",
                                outline: "none"
                            }} color="error" className="p-0 mr-2" onClick={() => {
                                if (!row.disabled || App.App.user.administrator) {
                                    this.deleteItem(row.pk)
                                    List.deleteEvent = true;
                                }
                            }}><DeleteIcon color="warn" fontSize="small"></DeleteIcon></IconButton>
                        </div>,
                    checkBox: enableSelection ? <CustomCheckbox checked={this.isItemSelected(row.pk)} onChange={() => {
                            this.selectItem(row.pk)
                        }}/>
                        : null,
                }
            )
        })

        const data = {
            columns: this.columns,
            rows: rows
        }

        return <React.Fragment>
            <header className="header-small w-100">
                <div className="container d-flex pb-0">
                    <h3 className="text-white"><i className="mdi mdi-car"></i> {
                        t('sharedMaintenance')}</h3>
                </div>
            </header>

            <div className="p-2">
                <div className="list-tools">
                    {enableSelection ?
                        <div className="list-controls">
                            <CustomCheckbox checked={this.state.allSelected} onChange={() => this.toggleAll()}/>
                        </div>
                        : null}
                    {enableFilter ? <React.Fragment><TextFilter onFilter={(value) => this.setFilter(value)}/>
                    </React.Fragment> : null}
                    <hr/>
                </div>

                <section className="ml-2 mr-2 statisc-table">
                    <div className="flex-grow-1 overflow">
                    <MDBDataTable
                        infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                        noRecordsFoundLabel={t("sharedEmtpyList")}
                        paginationLabel={[t("previous"), t("next")]}
                        entriesLabel={t("showEntries")}
                        striped
                        hover
                        searching={false}
                        bordered
                        small
                        noBottomColumns={true}
                        data={data}
                        sortRows={['name','start','period','attributes.date']}
                    />
                    </div>
                </section>
            </div>

            {/*{values.length > 0 ? <ListGroup>*/}
            {/*    {values.map(i => {*/}
            {/*        return <ListGroup.Item className="list-group-item list-group-item-action border-1" key={i.pk}>*/}
            {/*            {enableSelection ?*/}
            {/*                <CustomCheckbox checked={this.isItemSelected(i.pk)} onChange={() => this.selectItem(i.pk)}/>*/}
            {/*                : null}*/}
            {/*            <div className="content" onClick={() => this.selectItem(i.pk)}>*/}
            {/*                <strong className="name">{i.name}</strong>*/}
            {/*                <span className="value">*/}
            {/*                    <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('sharedType')}:</span>*/}
            {/*                        <span className="attr-value">{t(i.getFormattedProperty('type'))}</span>*/}
            {/*                    </span>*/}
            {/*                    {*/}
            {/*                        i._data.type === 'odometer' ?*/}
            {/*                            <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('maintenanceStart')}:</span>*/}
            {/*                        <span*/}
            {/*                            className="attr-value">{App.AttributeFormatter.renderAttribute(i.start, store.getState().maintenance_types.maintenance_types[i.type])}</span>*/}
            {/*                    </span> : null}*/}
            {/*                    {*/}
            {/*                        i._data.type === 'odometer' ?*/}
            {/*                            <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('maintenancePeriod')}:</span>*/}
            {/*                        <span*/}
            {/*                            className="attr-value">{App.AttributeFormatter.renderAttribute(i.period, store.getState().maintenance_types.maintenance_types[i.type])}</span>*/}
            {/*                        </span> : null*/}
            {/*                    }*/}

            {/*                    {*/}
            {/*                        i._data.type === 'date' ?*/}
            {/*                            <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('Date')}:</span>*/}
            {/*                        <span*/}
            {/*                            className="attr-value">{i.attributes["date"] ? moment(new Date(i.attributes["date"])).format(constants.dateFormat) : ""}</span>*/}
            {/*                    </span> : null}*/}
            {/*                    {*/}
            {/*                        i._data.type === 'date' ?*/}
            {/*                            <span className="attribute">*/}
            {/*                        <span className="attr-name">{t('Repeat')}:</span>*/}
            {/*                        <span*/}
            {/*                            className="attr-value">{i.attributes['repeatable'] === true?t('Yes'):t('No')}</span>*/}
            {/*                        </span> : null*/}
            {/*                    }*/}
            {/*                </span>*/}
            {/*            </div>*/}
            {/*            {enableDelete ? <div className="actions">*/}
            {/*                <Button variant="default" className="danger" onClick={() => this.deleteItem(i.pk)}><i*/}
            {/*                    className="mdi mdi-delete"></i></Button>*/}
            {/*            </div> : null}*/}
            {/*        </ListGroup.Item>*/}
            {/*    })}*/}
            {/*</ListGroup> : <EmptyList/>}*/}
        </React.Fragment>
    }

}