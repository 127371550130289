import createGenericStore from "../generic_store";
const data = {
    km: {
        key: 'km',
        name: 'sharedKm',
        factor: 0.001
    },
    mi: {
        key: 'mi',
        name: 'sharedMi',
        factor: 0.000621371
    },
    nmi: {
        key: 'nmi',
        name: 'sharedNmi',
        factor: 0.000539957
    }
}


const store = createGenericStore('distance_units', {
    autoLoad: true,
    initialState: {
        distance_units: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}