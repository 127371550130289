import createGenericStore from "../generic_store";
const data = {
    dd: {
        key: 'dd',
        name: 'sharedDecimalDegrees'
    },
    ddm: {
        key: 'ddm',
        name: 'sharedDegreesDecimalMinutes'
    },
    dms: {
        key: 'dms',
        name: 'sharedDegreesMinutesSeconds'
    }
};
const store = createGenericStore('coordinate_formats', {
    autoLoad: true,
    initialState: {
        coordinate_formats: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}