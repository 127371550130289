import L from 'leaflet';


export class LeafletGeofenceConverter {

    wktToGeometry(wkt) {
        let geometry, points = [], center, radius, content, i, lat, lon, coordinates;
        if (wkt.lastIndexOf('POLYGON', 0) === 0) {
            content = wkt.match(/\([^()]+\)/);
            if (content !== null) {
                coordinates = content[0].match(/-?\d+\.?\d*/g);
                if (coordinates !== null) {
                    for (i = 0; i < coordinates.length; i += 2) {
                        lat = Number(coordinates[i]);
                        lon = Number(coordinates[i + 1]);
                        points.push([lat, lon]);
                    }
                    geometry = new L.Polygon([points]);
                }
            }
        } else if (wkt.lastIndexOf('CIRCLE', 0) === 0) {
            content = wkt.match(/\([^()]+\)/);
            if (content !== null) {
                coordinates = content[0].match(/-?\d+\.?\d*/g);
                if (coordinates !== null) {
                    center = [Number(coordinates[0]), Number(coordinates[1])];
                    radius = Number(coordinates[2]);
                    geometry = new L.Circle(center, {
                        radius: radius
                    });
                }
            }
        } else if (wkt.lastIndexOf('LINESTRING', 0) === 0) {
            content = wkt.match(/\([^()]+\)/);
            if (content !== null) {
                coordinates = content[0].match(/-?\d+\.?\d*/g);
                if (coordinates !== null) {
                    for (i = 0; i < coordinates.length; i += 2) {
                        lat = Number(coordinates[i]);
                        lon = Number(coordinates[i + 1]);
                        points.push([lat, lon]);
                    }
                    geometry = new L.Polyline([points]);
                }
            }
        }
        return geometry;
    }

    geometryToWkt(geometry) {
        let result, i, center, radius, points, edgeCoordinate;
        if (geometry instanceof L.Circle) {
            center = geometry.getLatLng();
            radius = geometry.getRadius();
            edgeCoordinate = [center[0] + radius, center[1]];            
            result = 'CIRCLE (';
            result += center.lat + ' ' + center.lng + ', ';
            result += radius.toFixed(1) + ')';
        } else if (geometry instanceof L.Polygon) {            
            points = geometry.getLatLngs();
            result = 'POLYGON((';
            for (i = 0; i < points[0].length; i += 1) {
                result += points[0][i].lat + ' ' + points[0][i].lng + ', ';
            }
            result = result.substring(0, result.length - 2) + '))';
        } else if (geometry instanceof L.Polyline) {
            points = geometry.getLatLngs();
            result = 'LINESTRING (';
            for (i = 0; i < points.length; i += 1) {
                result += points[i].lat + ' ' + points[i].lng + ', ';
            }
            result = result.substring(0, result.length - 2) + ')';
        }
        return result;
    }
}

export default new LeafletGeofenceConverter();