import createGenericStore from "../generic_store";
const data = {
    kn: {
        key: 'kn',
        name: 'sharedKn',
        factor: 1
    },
    kmh: {
        key: 'kmh',
        name: 'sharedKmh',
        factor: 1.852
    },
    mph: {
        key: 'mph',
        name: 'sharedMph',
        factor: 1.15078
    }
}


const store = createGenericStore('speed_units', {
    autoLoad: true,
    initialState: {
        speed_units: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}