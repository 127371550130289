import ApiService from "./ApiService";

export class PermissionsService extends ApiService {
    constructor() {
        super();
        this.baseUrl = 'permissions';
    }

    serialize(model){
        return model;
    }
}

export default new PermissionsService();