import { t } from "../Translator";


class HoursUnitsConverter {
    convertValue(value, unit, back) {
        return back ? value * 3600000 : value / 3600000;
    }

    formatValue(value, unit, convert) {
        var hours, minutes;
        if (convert) {
            hours = Math.floor(value / 3600000);
            minutes = Math.floor(value % 3600000 / 60000);
        } else {
            hours = Math.floor(value);
            minutes = Math.floor(value % 1 * 60);
        }
        
        return hours + ' ' + t('sharedHourAbbreviation') + ' ' + minutes + ' ' + t('sharedMinuteAbbreviation');
    }
}

export default new HoursUnitsConverter();