import PlusIcon from "@mui/icons-material/Add";
import React from 'react';
import { Button, FormControl, FormGroup, InputGroup, Tab, Tabs } from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import "../../assets/scss/components/settings.scss";
import AppSidebar from '../../components/AppSidebar';
import { DeviceGroups } from '../../components/DeviceGroup';
import { Settings as DeviceListSettings } from '../../components/DeviceList';
import DeviceStatus from '../../components/DeviceStatus';
import { EventsList } from '../../components/EventsList';
import NotificationsSettings from '../../components/General/NotificationsSettings';
import { MainMap, MapControls } from '../../components/MainMap';
import ReportsConfig from '../../components/Reports/config';
import { Device } from '../../models';
import { AppService, DevicesService } from '../../services';
import { store } from '../../store';
import app from '../../store/app';
import devices from '../../store/devices';
import events_store from '../../store/events';
import events from '../../utils/Events';
import { t } from '../../utils/Translator';
import ReportPage from '../report';
import {App, customReadOnlyUser} from '../../App';

import {
    getAllDevices,
    getEvents,
    getMapConfig,
    getSelectedDevice,
    getSelectedDevicePosition,
    getSidebarGroups
} from './selectors';

export class HomePage extends React.PureComponent {
    constructor() {
        super();
        this.state = { currentModal: '', activeKey: 'devices' };
        this.showAddDeviceForm = this.showAddDeviceForm.bind(this);
        this.selectReport = this.selectReport.bind(this);
    }


    toggleMap(fullscreen) {
        if (fullscreen) {
            if (!document.body.classList.contains('map-full-screen')) {
                document.body.classList.add('map-full-screen');
            }
        } else {
            document.body.classList.remove('map-full-screen');
        }
    }

    componentWillMount() {
        AppService.showNotifications();
        this.addEventListeners();
    }

    componentDidMount() {
        // requestForToken().then((t) => {
        //     console.log("Token " + t)
        // })
        //     .catch(e => console.log(e))
    }

    componentWillUnmount() {
        this.removeEventListeners();
    }

    addEventListeners() {
        events.on(app.types.MAP_FULL_SCREEN, this.toggleMap)
        events.on("deviceExternalReport", this.selectReport)
    }

    removeEventListeners() {
        events.off(app.types.MAP_FULL_SCREEN, this.toggleMap)
        events.off("deviceExternalReport", this.selectReport)
    }

    selectDevice(id) {
        store.dispatch(devices.actions.setSelectedDevice(id))
        events.emit(devices.types.DEVICE_SELECTED, { id: id });
    }

    setDevicesFilterValue(str) {
        this.props.changeDevicesFilter(str.toLowerCase());
        events.emit(devices.types.FILTER_BY_NAME, str.toLowerCase());
    }

    setEventsFilterValue(str) {
        this.props.changeEventsFilter(str.toLowerCase());
        events.emit(events_store.types.FILTER_BY_NAME, str.toLowerCase());
    }

    selectReport(e) {
        this.setState({ activeKey: 'reports' })
    }

    toggleSounds(e) {

    }

    clearNotifications() {
        store.dispatch(events_store.actions.clear());
    }

    showAddDeviceForm() {
        store.dispatch(app.actions.showModal({
            name: 'device-form',
            icon: 'mdi mdi mdi-car',
            title: t('sharedAdd') + ' ' + t('deviceTitle')
        }, { item: new Device(), onSave: this.saveDevice }))
    }

    async saveDevice(item) {
        try {
            item = await DevicesService.save(item);
            store.dispatch(app.actions.hideModal());
            store.dispatch(devices.actions.updated(item));
            events.emit(devices.types.UPDATED, item)
            store.dispatch(app.actions.hideModal());
        } catch (ex) {
            AppService.showError(ex);
        }
    }

    renderDevicesMenu() {

    }


    render() {
        const { user } = this.props;
        const { activeKey } = this.state;
        if (!user) {
            return null;
        }
        return (
            <div className="page" id="pgHome">

                <AppSidebar controlled="false">
                    <Tabs fill activeKey={activeKey} onSelect={(e) => this.setState({ activeKey: e })}>
                        <Tab title={t('deviceTitle')} eventKey="devices">
                            <div className="tab-content-wrapper" >
                                <div className="filter border-bottom">
                                    <FormGroup className="m-3">
                                        <InputGroup>
                                            <FormControl placeholder={t('sharedSearch')}
                                                onKeyUp={(e) => this.setDevicesFilterValue(e.target.value)} />
                                            {App.App.user.id === customReadOnlyUser ? null :
                                                <Button className="md-pd-btn" onClick={this.showAddDeviceForm} size="sm"
                                                    variant="primary">
                                                    <PlusIcon color="warn" fontSize="medium"></PlusIcon> </Button>
                                            }
                                            <DeviceListSettings></DeviceListSettings>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                                <div className="content">
                                    <DeviceGroupsContainer></DeviceGroupsContainer>
                                </div>
                            </div>
                        </Tab>
                        <Tab title={t('reportEvents')} eventKey="events">
                            <div className="tab-content-wrapper">
                                <div className="filter border-bottom">
                                    <FormGroup className="m-3">
                                        <InputGroup>
                                            <FormControl placeholder={t('sharedSearch')}
                                                onKeyUp={(e) => this.setEventsFilterValue(e.target.value)} />
                                            <NotificationsSettings></NotificationsSettings>
                                        </InputGroup>
                                    </FormGroup>
                                </div>
                                <div className="content">
                                    <Scrollbars>
                                        <EventsContainer></EventsContainer>
                                    </Scrollbars>
                                </div>
                            </div>
                        </Tab>
                        <Tab title={t('reportTitle')} eventKey="reports" >
                            <div className="tab-content-wrapper">
                                <ReportsConfig />

                            </div>
                        </Tab>
                    </Tabs>
                </AppSidebar>
                <div className="map-wrapper">
                    <div className="map">
                        <ConnectedMainMap></ConnectedMainMap>

                    </div>

                </div>
                <MapControls></MapControls>
                <ConnectedDeviceStatus></ConnectedDeviceStatus>
                <ConnectedReportPage />
            </div>
        )
    }
}

const DeviceGroupsContainer = connect((state) => ({
    groups: getSidebarGroups(state),
}))(DeviceGroups)

const ConnectedDeviceStatus = connect((state) => ({
    device: getSelectedDevice(state),
    position: getSelectedDevicePosition(state),
}))(DeviceStatus)

const ConnectedMainMap = connect((state) => ({
    devices: getAllDevices(state),
    mapConfig: getMapConfig(state)
}))(MainMap);

const ConnectedReportPage = connect((state) => ({
    mapConfig: getMapConfig(state)
}))(ReportPage);

const EventsContainer = connect((state) => ({
    events: getEvents(state),
}))(EventsList)

const mapStateToProps = (state) => ({
    user: state.session.user,
})

const mapDispatchToProps = (dispatch) => ({
    /*increment: () => dispatch(increment()),
    decrement: () => dispatch(decrement()),*/
    changeDevicesFilter: (str) => dispatch(devices.actions.filterByName(str)),
    changeEventsFilter: (str) => dispatch(events_store.actions.filterByName(str))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)