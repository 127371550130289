import { BaseModel } from "./BaseModel";
import { App } from "../App";

export const fields = [{
    name: 'type',
    type: 'string',
    isPk: true,
}, {
    name: 'name',
    convert: (v, rec) => {
        return App.App.getEventString(rec.type);
    }
}];

export class KnownNotification extends BaseModel {
    constructor(data = {}) {
        super(fields, data);
    }
}