import createGenericStore from "../generic_store";
import { t } from '../../utils/Translator';

const data = {
    route: {
        key: 'route',
        name: t('reportRoute')
    }, events: {
        key: 'events',
        name: t('reportEvents')
    }, trips: {
        key: 'trips',
        name: t('reportTrips')
    }, stops: {
        key: 'stops',
        name: t('reportStops')
    }, summary: {
        key: 'summary',
        name: t('reportSummary')
    }, chart: {
        key: 'chart',
        name: t('reportChart')
    }, ecoscores: {
        key: 'ecoscores',
        name: t('ecoScore')
    }, driverTrips: {
        key: 'driverTrips',
        name: t('driverTrips')
    }, driverSummary: {
        key: 'driverSummary',
        name: t('driverSummary')
    }, driverEcoscores: {
    key: 'driverEcoscores',
    name: t('driverEcoscores')
}
}


const store = createGenericStore('report_types', {
    autoLoad: true,
    initialState: {
        report_types: data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}
