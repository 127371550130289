import createGenericStore from "../generic_store";
import { t } from '../../utils/Translator';
import { KnownCommand } from "../../models/KnownCommand";

const data = [{
    type: 'custom',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'positionPeriodic',
    parameters: [{
        key: 'frequency',
        name: t('commandFrequency'),
        valueType: 'number',
        allowDecimals: false,
        minValue: 0,
        dataType: 'frequency'
    }]
}, {
    type: 'setTimezone',
    parameters: [{
        key: 'timezoneName',
        name: t('commandTimezone'),
        valueType: 'string',
        dataType: 'timezone'
    }]
}, {
    type: 'sendSms',
    parameters: [{
        key: 'phone',
        name: t('commandPhone'),
        valueType: 'string'
    }, {
        key: 'message',
        name: t('commandMessage'),
        valueType: 'string'
    }]
}, {
    type: 'message',
    parameters: [{
        key: 'message',
        name: t('commandMessage'),
        valueType: 'string'
    }]
}, {
    type: 'sendUssd',
    parameters: [{
        key: 'phone',
        name: t('commandPhone'),
        valueType: 'string'
    }]
}, {
    type: 'sosNumber',
    parameters: [{
        key: 'index',
        name: t('commandIndex'),
        valueType: 'number',
        allowDecimals: false,
        minValue: 0
    }, {
        key: 'phone',
        name: t('commandPhone'),
        valueType: 'string'
    }]
}, {
    type: 'silenceTime',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'setPhonebook',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'voiceMessage',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'outputControl',
    parameters: [{
        key: 'index',
        name: t('commandIndex'),
        valueType: 'number',
        allowDecimals: false,
        minValue: 0
    }, {
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'voiceMonitoring',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'setAgps',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'setIndicator',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'configuration',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'setConnection',
    parameters: [{
        key: 'server',
        name: t('commandServer'),
        valueType: 'string'
    }, {
        key: 'port',
        name: t('commandPort'),
        valueType: 'number',
        allowDecimals: false,
        minValue: 1,
        maxValue: 65535
    }]
}, {
    type: 'setOdometer',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'modePowerSaving',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'modeDeepSleep',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'movementAlarm',
    parameters: [{
        key: 'radius',
        name: t('commandRadius'),
        valueType: 'number',
        allowDecimals: false,
        minValue: 0
    }]
}, {
    type: 'alarmBattery',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'alarmSos',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'alarmRemove',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'alarmClock',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'alarmSpeed',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}, {
    type: 'alarmFall',
    parameters: [{
        key: 'enable',
        name: t('commandEnable'),
        valueType: 'boolean'
    }]
}, {
    type: 'alarmVibration',
    parameters: [{
        key: 'data',
        name: t('commandData'),
        valueType: 'string'
    }]
}]

const attr_data = {};


data.map(o => {
    attr_data[o.type] = new KnownCommand().deserialize(o);
})

const store = createGenericStore('known_commands', {
    autoLoad: true,
    initialState: {
        known_commands: attr_data
    }
});


export default {
    types: store.types,
    reducers: store.reducers,
    actions: store.actions,
    state: store.state
}