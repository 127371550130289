import React from 'react';
import { t } from '../../utils/Translator';

import Events from '../../utils/Events';
import { App } from '../../App';
import { PositionsService } from '../../services';
import { ReportStop, ReportSummary, ReportPosition, ReportTrip, Position } from '../../models';

/*var CanvasJSReact = require('/assets/js/canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;*/
import { isArray } from 'util';
import { store } from '../../store';

import { CanvasJSChart } from 'assets/js/canvasjs.react';
import * as moment from 'moment';
import { deepClone, getRandomId } from '../../utils/functions';
import EcoScoreUtils from "../../utils/EcoScoreUtils";
import { MDBDataTable } from "mdbreact";
import { Link } from "@mui/material";


export default class ReportData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportType: null,
            reportData: [],
            reportTotal: null,
            toggled: false,
            showMarkers: false,
            showIdle: false,
            showStops: false
        }

        this.onData = this.onData.bind(this);
        this.onShow = this.onShow.bind(this);
        this.renderData = this.renderData.bind(this);
        this.renderChart = this.renderChart.bind(this);
        this.updateTableRow = this.updateTableRow.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentWillMount() {
        Events.on('reports/CLEAR', this.onClear)
        Events.on('reports/DATA', this.onData)
        Events.on('reports/SHOW', this.onShow)
        Events.on('reports/UPDATE_POSITION', this.updateTableRow)
    }

    componentWillUnmount() {
        Events.off('reports/CLEAR', this.onClear)
        Events.off('reports/DATA', this.onData)
        Events.off('reports/SHOW', this.onShow)
        Events.off('reports/UPDATE_POSITION', this.updateTableRow)
    }

    async onClear() {
        this.setState({ reportData: [] })
    }

    async onData(data) {
        let reportData = [];
        const positionIds = [];
        let total = null
        if (data.total !== null && data.total !== undefined) {
            total = data.total;
        }

        switch (this.state.reportType.key) {
            case 'events':
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        positionIds.push(d.positionId);
                        d.id = d.positionId;
                        reportData.push(d);
                    })
                });
                break;
            case 'driverTrips':
            case 'trips':
                for (const arr of Object.values(data.data)) {
                    for (const d of arr) {
                        d.id = d.startPositionId;
                        d.ecoScore = t("sharedLoading")
                        d.idleTime = t("sharedLoading")
                        reportData.push(d);
                    }
                }

                async function addTripsMetadata(ref, data, reportData) {
                    const newState = [...reportData];

                    for (const pos of newState) {
                        const d = deepClone(pos)
                        const filter = deepClone(data.filter)
                        filter.deviceId = d._data.deviceId
                        filter.from = new Date(d._data.startTime).toISOString()
                        filter.to = new Date(d._data.endTime).toISOString()
                        await EcoScoreUtils.addEcoScore(d, false, filter)
                        await EcoScoreUtils.addIdle(d, filter)
                        const p = new ReportTrip().deserialize(deepClone(d));
                        Events.emit('reports/UPDATE_POSITION', { id: d.id, data: p })
                    }
                }

                addTripsMetadata(this, data, reportData)
                break;
            case 'stops':
                Object.values(data.data).map(arr => {
                    arr.map(d => {
                        positionIds.push(d.positionId);
                        d.id = d.positionId;
                        reportData.push(d);
                    })
                });
                if (positionIds.length > 0) {
                    const mapData = Object.assign({}, data);
                    const positions = await PositionsService.list({ id: positionIds });
                    const mapPositions = {};
                    positions.map(p => {
                        if (!mapPositions[p.deviceId]) {
                            mapPositions[p.deviceId] = [];
                        }
                        if (!p.address) {
                            p.address = reportData.find(o => o.id === p.id).address;
                        }
                        mapPositions[p.deviceId].push(p);
                    });
                    mapData.data = mapPositions;
                    mapData.config.showRoutes = false;
                    Events.emit('reports/MAP_DATA', mapData);
                }
                break;
            case 'driverSummary':
            case 'summary':
                for (const arr of Object.values(data.data)) {
                    for (const d of arr) {
                        d.id = getRandomId()
                        d.idleTime = t("sharedLoading")
                        d.ecoScore = t("sharedLoading")
                        data.filter.deviceId = d.deviceId
                        reportData.push(d);
                    }
                }

            async function addSummaryMetadata(ref, data, reportData) {
                const newState = [...reportData];
                for (const pos of newState) {
                    const d = deepClone(pos)
                    const filter = deepClone(data.filter)
                    filter.deviceId = d._data.deviceId

                    await EcoScoreUtils.addEcoScore(d, false, filter)
                    await EcoScoreUtils.addIdle(d, filter)
                    const p = new ReportSummary().deserialize(deepClone(d));
                    Events.emit('reports/UPDATE_POSITION', {id: d.id, data: p})
                }
            }

                addSummaryMetadata(this, data, reportData)
                break;

            case 'driverEcoscores':
            case 'ecoscores':
                for (const arr of Object.values(data.data)) {
                    for (const d of arr) {
                        reportData.push(d);
                    }
                }
                break;

            case 'chart':
                reportData = data;
                data.config.showRoutes = true;
                Events.emit('reports/MAP_DATA', data);
                break;

            default:
                Object.values(data.data).map(arr => {
                if(arr!==undefined)
                    arr.map(d => {
                    if(d!==undefined)
                        reportData.push(d);
                    })
                });
                data.config.showRoutes = true;
                Events.emit('reports/MAP_DATA', Object.assign({}, data));
                break;
        }

        const reportType = this.state.reportType

        this.setState({
            reportType: null,
            reportTotal: null
        })
        this.setState({
            reportType: reportType,
            showMarkers: data.config.showMarkers,
            reportData: reportData,
            reportTotal: total,
            showIdle: data.config.showIdle,
            showStops: data.config.showStops,
            filter: data.filter
        })

    }

    onShow(config) {
        this.setState({
            reportType: config.reportType
        })
    }

    getAddress(lat, lng) {
        try {
            return PositionsService.findAddressFromLatLng(lat, lng);
        } catch (ex) {
            App.App.showError(ex);
        }
    }

    getAddressByPositionId(position) {
        try {
            return PositionsService.findAddressFromPosition(position);
        } catch (ex) {
            App.App.showError(ex);
        }
    }

    renderChart() {
        if (isArray(this.state.reportData)) {
            return null;
        }
        this.data = [];
        const reportData = this.state.reportData;


        const title = (t('position' + reportData.config.chartType.ucFirst()));

        Object.keys(reportData.data).map(k => {
            const device = store.getState().devices.devices[k];
            if (device) {
                const d = {
                    type: "line",
                    //xValueType: 'dateTime',
                    dataPoints: [],
                    id: k,
                    color: App.App.getReportColor(k),
                    click: (e) => {
                        Events.emit('reports/SELECT_POSITION', e.dataPoint.id);
                    },
                }
                reportData.data[k].map(p => {
                    let value = null;
                    switch (reportData.config.chartType) {
                        case 'fuel' :
                            value = p._data.attributes["io89"] ?
                            p._data.attributes["io89"] :
                            p._data.attributes["io84"] ?
                            p._data.attributes["io84"] / 10 :
                            p.getAttribute(reportData.config.chartType)
                            break;
                        case 'odometer':   
                            value = p._data.attributes["io87"] ?
                            p._data.attributes["io87"] :
                            p._data.attributes["io389"] ?
                            p._data.attributes["io389"] :
                            p.getAttribute(reportData.config.chartType)
                            break;
                        case 'rpm':   
                            value = p._data.attributes["io85"] ?
                            p._data.attributes["io85"] :
                            p._data.attributes["io36"] ?
                            p._data.attributes["io36"] :
                            p.getAttribute(reportData.config.chartType)
                            break;    
                        case 'temperature':
                            value = 
                            p._data.attributes["temp5"] ? (p._data.attributes["temp5"]/10) : 
                            p._data.attributes["io25"] ? (p._data.attributes["io25"]/1000) : 
                            0
                            p.getAttribute(reportData.config.chartType)
                            break;
                        case 'speed':
                            value = p._data.attributes["io81"] ?
                            p._data.attributes["io81"] :
                            p._data.attributes["io37"] ?
                            p._data.attributes["io37"] :
                            p._data.speed ? p._data.speed :
                            p.getProperty(reportData.config.chartType)
                            break;
                        case 'distance':
                        case 'altitude':
                        case 'accuracy':
                        case 'course':
                            value = p.getProperty(reportData.config.chartType);
                            break;
                        default:
                            value = p.getAttribute(reportData.config.chartType);
                    }
                    d.dataPoints.push({
                        x: p.fixTime,
                        y: value,
                        label: moment(p.fixTime).format('YYYY-MM-DD HH:mm:ss'),
                        id: p.id,
                    })
                })
                this.data.push(d);
            }
        });

        /*
        reportData.data.map(p => {
            if (!this.data[p.deviceId]) {
                this.data[p.deviceId] = [];
                this.columns.push(store.getState().devices.devices[p.deviceId].name);
            }
    
        });*/
        const options = {
            axisX: {
                valueFormatString: 'YYYY-MM-DD \n HH:mm:ss',
                gridThickness: 1,
                labelAngle: 0,
            },
            axisY: {
                title: title,
            },
            exportEnabled: true,
            zoomEnabled: true,
            data: this.data,
        }
        /*
        const CanvasJSChart = CanvasJSReact.CanvasJSChart;*/

        return <CanvasJSChart options={options} />
    }

    updateTableRow(payload) {
        const newState = [...this.state.reportData];
        const index = this.state.reportData.findIndex(o => o.id === payload.id);
        if (index > -1) {
            if (this.state.reportType.key === 'route') {
                const p = new ReportPosition().deserialize(deepClone(payload.data));
                newState[index] = p;
                this.setState({ reportData: newState });
            } else if (this.state.reportType.key === 'trips' || this.state.reportType.key === 'driverTrips') {
                newState[index] = payload.data;
                this.setState({ reportData: newState });
            } else if (this.state.reportType.key === 'summary' || this.state.reportType.key === 'driverSummary') {
                newState[index] = payload.data;
                this.setState({ reportData: newState });
            } else if (this.state.reportType.key === 'stops') {
                newState[index] = payload.data;
                this.setState({ reportData: newState });
            }
        }
    }

    renderData() {
        if (!this.state.reportType) {
            return null;
        }


        if (this.state.reportType.key === 'chart') {
            return this.renderChart();
        } else {

            let columns = [];

            let reportData = {}

            switch (this.state.reportType.key) {
                case 'route':
                    reportData = {
                        columns: this.getRouteColumns(),
                        rows: this.getRouteRows(this.state.reportData)
                    }
                    break;
                case 'events':
                    reportData = {
                        columns: this.getEventColumns(),
                        rows: this.getEventRows(this.state.reportData)
                    }
                    break;
                case 'driverTrips':
                    reportData = {
                        columns: this.getDriverTripColumns(),
                        rows: this.getDriverTripRows(this.state.reportData)
                    }
                    break
                case 'trips':
                    reportData = {
                        columns: this.getTripColumns(),
                        rows: this.getTripRows(this.state.reportData)
                    }
                    break;
                case 'stops':
                    reportData = {
                        columns: this.getStopColumns(),
                        rows: this.getStopRows(this.state.reportData)
                    }
                    break;
                case 'driverSummary':
                    reportData = {
                        columns: this.getDriverSummaryColumns(),
                        rows: this.getDriverSummaryRows(this.state.reportData)
                    }
                    break;
                case 'summary':
                    reportData = {
                        columns: this.getSummaryColumns(),
                        rows: this.getSummaryRows(this.state.reportData)
                    }
                    break;
                case 'driverEcoscores':
                    reportData = {
                        columns: this.getDriverEcoScoreColumns(),
                        rows: this.getDriverEcoScoreRows(this.state.reportData)
                    }
                    break
                case 'ecoscores':
                    reportData = {
                        columns: this.getEcoScoreColumns(),
                        rows: this.getEcoScoreRows(this.state.reportData)
                    }
                    break
            }

            const sortRows = []
            reportData.columns.forEach(it => sortRows.push(it.field))

            return /* <FormGroup className="m-3"> */ <div className="data-wrapper">
                {/*{this.state.reportType.key === 'route' ?*/}
                <MDBDataTable
                    className="ml-1 mr-1 mt-1 mb-1"
                    id="myTableID"
                    info={false}
                    infoLabel={[t("showing"), t("to"), t("of"), t("entries")]}
                    noRecordsFoundLabel={t("sharedEmtpyList")}
                    paginationLabel={[t("previous"), t("next")]}
                    entriesLabel={t("showEntries")}
                    displayEntries={true}
                    striped
                    hover
                    searching={false}
                    bordered
                    small
                    autoWidth={false}
                    noBottomColumns={true}
                    data={reportData}
                    sortRows={sortRows}
                    nSearch={(value) => console.log(value)}
                    onPageChange={(e) => console.log(e)}
                    onSort={(value) => console.log(value)}

                />

                {/*// :*/}
                {/*// <div className="table-wrapper">*/}
                {/*//     <div className="table-fixed">*/}
                {/*//         /!*{this.state.reportType.key === "driverSummary" && this.state.reportTotal !== null ?*!/*/}
                {/*//         /!*    <BootstrapTable bootstrap4={true} classes="table table-sm table-condensed table-hover"*!/*/}
                {/*//         /!*                    rowClasses={rowClasses} rowEvents={rowEvents} keyField='id'*!/*/}
                {/*//         /!*                    data={this.state.reportTotal} columns={columns}*!/*/}
                {/*//         /!*                    pagination={pagination}/> : null}*!/*/}
                {/*//*/}
                {/*//         <BootstrapTable bootstrap4={true}*/}
                {/*//                         classes="table table-sm table-condensed table-hover"*/}
                {/*//                         rowClasses={rowClasses} rowEvents={rowEvents} keyField='id'*/}
                {/*//                         data={this.state.reportData} columns={columns}*/}
                {/*//                         pagination={pagination}/>*/}
                {/*//*/}
                {/*//     </div>*/}
                {/*// </div>*/}
                {/* } */}


            </div>
            {/*</FormGroup>*/
            }
        }
    }

    render() {
        return <React.Fragment>
            {this.renderData()}
        </React.Fragment>
    }

    getRouteColumns() {
        return [
            {
                label: t("reportDeviceName"),
                field: "deviceName",
                sort: 'asc'
            },
            {
                label: t("positionFixTime"),
                field: "fixTime",
                sort: 'asc'
            },
            {
                label: t("positionIgnition"),
                field: "ignition",
                sort: 'asc'
            },
            {
                label: t("positionMotion"),
                field: "moving",
                sort: 'asc'
            },
              {
                            label: t("Fuel"),
                            field: "fuel",
                            sort: 'asc'
                        },
//            {
//                label: t("positionLatitude"),
//                field: "latitude",
//                sort: 'asc'
//            },
//            {
//                label: t("positionLongitude"),
//                field: "longitude",
//                sort: 'asc'
//            },
            {
                label: t("positionAltitude"),
                field: "altitude",
                sort: 'asc'
            },
            {
                label: t("positionSpeed"),
                field: "speed",
                sort: 'asc'
            },
            {
                label: t("positionAddress"),
                field: "address",
                sort: 'asc'
            },
        ]
    }

    getEventColumns() {
        return [
            {
                label: t("Event Time"),
                field: "EventTime",
                sort: 'asc'
            },
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("sharedType"),
                field: "type",
                sort: 'asc'
            },
            {
                label: t("sharedGeofence"),
                field: "geofenceId",
                sort: 'asc'
            },
            {
                label: t("sharedMaintenance"),
                field: "maintenanceId",
                sort: 'asc'
            }
        ]
    }

    getTripColumns() {
        return [
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportStartTime"),
                field: "startTime",
                sort: 'asc'
            },
            {
                label: t("reportStartOdometer"),
                field: "startOdometer",
                sort: 'asc'
            },
            {
                label: t("reportStartAddress"),
                field: "startAddress",
                sort: 'asc'
            },
            {
                label: t("reportEndTime"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("reportEndOdometer"),
                field: "endOdometer",
                sort: 'asc'
            },
            {
                label: t("reportEndAddress"),
                field: "endAddress",
                sort: 'asc'
            },
            {
                label: t("sharedDistance"),
                field: "distance",
                sort: 'asc'
            },
            {
                label: t("reportAverageSpeed"),
                field: "averageSpeed",
                sort: 'asc'
            },
            {
                label: t("reportMaximumSpeed"),
                field: "maxSpeed",
                sort: 'asc'
            },
            {
                label: t("reportDuration"),
                field: "duration",
                sort: 'asc'
            },
            {
                label: t("reportSpentFuel"),
                field: "spentFuel",
                sort: 'asc'
            },
            {
                label: t("Fuel Consumption"),
                field: "fuelConsumption",
                sort: 'asc'
            },
            {
                label: t("sharedDriver"),
                field: "driverUniqueId",
                sort: 'asc'
            },
            {
                label: t("idlingTime"),
                field: "idleTime",
                sort: 'asc'
            },
            {
                label: t("ecoScore"),
                field: "ecoScore",
                sort: 'asc'
            }
        ]
    }

    getStopColumns() {
        return [
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportStartTime"),
                field: "startTime",
                sort: 'asc'
            },
            {
                label: t("positionOdometer"),
                field: "startOdometer",
                sort: 'asc'
            },
            {
                label: t("positionAddress"),
                field: "address",
                sort: 'asc'
            },
            {
                label: t("reportEndTime"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("reportDuration"),
                field: "duration",
                sort: 'asc'
            },
            {
                label: t("reportEngineHours"),
                field: "engineHours",
                sort: 'asc'
            },
            {
                label: t("reportSpentFuel"),
                field: "spentFuel",
                sort: 'asc'
            }
        ]
    }

    getDriverSummaryColumns() {
        return [
            {
                label: t("sharedDriver"),
                field: "driverUniqueId",
                sort: 'asc'
            },
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportPeriod"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("sharedDistance"),
                field: "distance",
                sort: 'asc'
            },
            {
                label: t("reportStartOdometer"),
                field: "startOdometer",
                sort: 'asc'
            },
            {
                label: t("reportEndOdometer"),
                field: "endOdometer",
                sort: 'asc'
            },
            {
                label: t("reportAverageSpeed"),
                field: "averageSpeed",
                sort: 'asc'
            },
            {
                label: t("reportMaximumSpeed"),
                field: "maxSpeed",
                sort: 'asc'
            },

            {
                label: t("reportEngineHours"),
                field: "engineHours",
                sort: 'asc'
            },

            {
                label: t("reportSpentFuel"),
                field: "spentFuel",
                sort: 'asc'
            },

            {
                label: t("Fuel Consumption"),
                field: "fuelConsumption",
                sort: 'asc'
            },
            {
                label: t("idlingTime"),
                field: "idleTime",
                sort: 'asc'
            },


            {
                label: t("ecoScore"),
                field: "ecoScore",
                sort: 'asc'
            }
        ]
    }

    getEcoScoreColumns() {
        return [
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportPeriod"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("sharedDistance"),
                field: "distance",
                sort: 'asc'
            },
            {
                label: t("reportEngineHours"),
                field: "hours",
                sort: 'asc'
            },
            {
                label: t("alarmHardBraking"),
                field: "hardBraking",
                sort: 'asc'
            },
            {
                label: t("alarmHardCornering"),
                field: "hardCornering",
                sort: 'asc'
            },
            {
                label: t("alarmHardAcceleration"),
                field: "hardAcceleration",
                sort: 'asc'
            },
            {
                label: t("total"),
                field: "total",
                sort: 'asc'
            },
        ]
    }

    getDriverEcoScoreColumns() {
        return [
            {
                label: t("sharedDriver"),
                field: "driverUniqueId",
                sort: 'asc'
            },
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportPeriod"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("sharedDistance"),
                field: "distance",
                sort: 'asc'
            },
            {
                label: t("reportEngineHours"),
                field: "hours",
                sort: 'asc'
            },
            {
                label: t("alarmHardBraking"),
                field: "hardBraking",
                sort: 'asc'
            },
            {
                label: t("alarmHardCornering"),
                field: "hardCornering",
                sort: 'asc'
            },
            {
                label: t("alarmHardAcceleration"),
                field: "hardAcceleration",
                sort: 'asc'
            },
            {
                label: t("total"),
                field: "total",
                sort: 'asc'
            },
        ]
    }

    getDriverTripColumns() {
        return [
            {
                label: t("sharedDriver"),
                field: "driverUniqueId",
                sort: 'asc'
            },
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportStartTime"),
                field: "startTime",
                sort: 'asc'
            },
            {
                label: t("reportStartOdometer"),
                field: "startOdometer",
                sort: 'asc'
            },
            {
                label: t("reportStartAddress"),
                field: "startAddress",
                sort: 'asc'
            },
            {
                label: t("reportEndTime"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("reportEndOdometer"),
                field: "endOdometer",
                sort: 'asc'
            },
            {
                label: t("reportEndAddress"),
                field: "endAddress",
                sort: 'asc'
            },
            {
                label: t("sharedDistance"),
                field: "distance",
                sort: 'asc'
            },
            {
                label: t("reportAverageSpeed"),
                field: "averageSpeed",
                sort: 'asc'
            },
            {
                label: t("reportMaximumSpeed"),
                field: "maxSpeed",
                sort: 'asc'
            },
            {
                label: t("reportDuration"),
                field: "duration",
                sort: 'asc'
            },
            {
                label: t("reportSpentFuel"),
                field: "spentFuel",
                sort: 'asc'
            },
            {
                label: t("Fuel Consumption"),
                field: "fuelConsumption",
                sort: 'asc'
            },
            {
                label: t("idlingTime"),
                field: "idleTime",
                sort: 'asc'
            },
            {
                label: t("ecoScore"),
                field: "ecoScore",
                sort: 'asc'
            }
        ]
    }

    getSummaryColumns() {
        return [
            {
                label: t("reportDeviceName"),
                field: "deviceId",
                sort: 'asc'
            },
            {
                label: t("reportPeriod"),
                field: "endTime",
                sort: 'asc'
            },
            {
                label: t("sharedDistance"),
                field: "distance",
                sort: 'asc'
            },
            {
                label: t("reportStartOdometer"),
                field: "startOdometer",
                sort: 'asc'
            },
            {
                label: t("reportEndOdometer"),
                field: "endOdometer",
                sort: 'asc'
            },
            {
                label: t("reportAverageSpeed"),
                field: "averageSpeed",
                sort: 'asc'
            },
            {
                label: t("reportMaximumSpeed"),
                field: "maxSpeed",
                sort: 'asc'
            },
            {
                label: t("reportEngineHours"),
                field: "engineHours",
                sort: 'asc'
            },
            {
                label: t("reportSpentFuel"),
                field: "spentFuel",
                sort: 'asc'
            },
            {
                label: t("Fuel Consumption"),
                field: "fuelConsumption",
                sort: 'asc'
            },
            {
                label: t("idlingTime"),
                field: "idleTime",
                sort: 'asc'
            },
            {
                label: t("ecoScore"),
                field: "ecoScore",
                sort: 'asc'
            }
        ]
    }

    getSummaryRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                endTime: <div
                    searchvalue={row.startTime ?? 0}>{
                        moment(row.startTime).format('DD/MM/YYYY') + " - " + moment(row.endTime).format('DD/MM/YYYY')
                    }</div>,
                distance: <div searchvalue={row.distance ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.distance)
                }</div>,
                startOdometer: <div searchvalue={row.startOdometer ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.startOdometer)
                }</div>,
                endOdometer: <div
                    searchvalue={row.endOdometer ?? 0}>{
                        App.AttributeFormatter.getFormatter('distance')(row.endOdometer)
                    }</div>,
                averageSpeed: <div
                    searchvalue={row.averageSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.averageSpeed)
                    }</div>,
                maxSpeed: <div
                    searchvalue={row.maxSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.maxSpeed)
                    }</div>,
                engineHours: <div
                    searchvalue={row.engineHours ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.engineHours)
                    }</div>,
                spentFuel: <div
                    searchvalue={row.spentFuel ?? 0}>{
                        App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel)
                    }</div>,
                fuelConsumption: <div
                    searchvalue={row.fuelConsumption ?? 0}>{
                        App.AttributeFormatter.getFormatter('consumption')(row.fuelConsumption)
                    }</div>,
                idleTime: <div
                    searchvalue={row.idleTime ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.idleTime)
                    }</div>,
                ecoScore: <div
                    searchvalue={row.ecoScore === t("sharedLoading") || !row.ecoScore ? "" : row.ecoScore ?? 0}>{
                                        <strong className="device-sensor">
                                            <strong className="name">
                                                <strong className={
                                                    (() => {
                                                        if (row.ecoScore === t("sharedLoading")) {
                                                            return 'text-info'
                                                        } else if (row.ecoScore >= 80) {
                                                            return 'text-success';
                                                        } else if (row.ecoScore >= 40) {
                                                            return 'text-warning'
                                                        } else {
                                                            return 'text-danger'
                                                        }
                                                    })()
                                                }>
                                                    {row.ecoScore}
                                                </strong>
                                            </strong>
                                        </strong>

                    }</div>,
                clickEvent: () => this.handleClick(row)
            })
        })
        return rows;
    }

    getDriverTripRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                driverUniqueId: <div
                    searchvalue={row.driverName ?? ""}>{
                        row.driverName
                    }</div>,
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                startTime: <div searchvalue={row.startTime ?? 0}>{
                    App.AttributeFormatter.getFormatter('time')(row.startTime)
                }</div>,
                startOdometer: <div searchvalue={row.startOdometer ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.startOdometer)
                }</div>,
                startAddress: <div searchvalue={row.startAddress ?? ""}>{
                    !row.startAddress ?
                        <Link href="#" component="button" color="primary" onClick={(e) => {
                            e.stopPropagation();
                            this.getAddress(row.startLat, row.startLon).then(data => {
                                const p = new ReportTrip().deserialize(deepClone(row));
                                p.startAddress = data;
                                Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                            }).catch(ex => App.App.showError(ex))
                        }}>{t('sharedShowAddress')}</Link>
                        : App.AttributeFormatter.getFormatter('address')(row.startAddress)
                }</div>,
                endTime: <div searchvalue={row.endTime ?? 0}>{
                    App.AttributeFormatter.getFormatter('time')(row.endTime)
                }</div>,
                endOdometer: <div
                    searchvalue={row.endOdometer ?? 0}>{
                        App.AttributeFormatter.getFormatter('distance')(row.endOdometer)
                    }</div>,
                endAddress: <div
                    searchvalue={row.endAddress ?? ""}>{
                        !row.endAddress ? <Link href="#" component="button" color="primary" onClick={(e) => {
                            e.stopPropagation();
                            this.getAddress(row.endLat, row.endLon).then(data => {
                                const p = new ReportTrip().deserialize(deepClone(row));
                                p.endAddress = data;
                                Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                            }).catch(ex => App.App.showError(ex))
                        }}>{t('sharedShowAddress')}</Link>
                            : App.AttributeFormatter.getFormatter('address')(row.endAddress)
                    }</div>,
                distance: <div
                    searchvalue={row.distance ?? 0}>{
                        App.AttributeFormatter.getFormatter('distance')(row.distance)
                    }</div>,
                averageSpeed: <div
                    searchvalue={row.averageSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.averageSpeed)
                    }</div>,
                maxSpeed: <div
                    searchvalue={row.maxSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.maxSpeed)
                    }</div>,
                duration: <div
                    searchvalue={row.duration ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.duration)
                    }</div>,
                spentFuel: <div
                    searchvalue={row.spentFuel ?? 0}>{
                        App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel)
                    }</div>,
                fuelConsumption: <div
                    searchvalue={row.fuelConsumption ?? 0}>{
                        App.AttributeFormatter.getFormatter('consumption')(row.fuelConsumption)
                    }</div>,
                idleTime: <div
                    searchvalue={row.idleTime === t("sharedLoading") || !row.idleTime ? "" : row.idleTime ?? 0}>{
                        row.idleTime === t("sharedLoading") ?
                            <strong className="device-sensor">
                                <strong className="name">
                                    <strong className={
                                        (() => {
                                            return 'text-info'
                                        })()
                                    }>
                                        {row.idleTime}
                                    </strong>
                                </strong>
                            </strong>
                            :
                            App.AttributeFormatter.getFormatter('duration')(row.idleTime)
                    }</div>,
                ecoScore: <div
                    searchvalue={row.ecoScore === t("sharedLoading") || !row.ecoScore ? "" : row.ecoScore ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row.ecoScore === t("sharedLoading")) {
                                            return 'text-info'
                                        } else if (row.ecoScore >= 80) {
                                            return 'text-success';
                                        } else if (row.ecoScore >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row.ecoScore}
                                </strong>
                            </strong>
                        </strong>

                    }</div>,
                clickEvent: () => this.handleClick(row)
            })
        })
        return rows;
    }

    getDriverEcoScoreRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                driverUniqueId: <div searchvalue={row.driverName ?? ""}>{
                    row.driverName
                }</div>,
                endTime: <div searchvalue={row.startTime ?? 0}>{
                    moment(row.startTime).format('DD/MM/YYYY') + " - " + moment(row.endTime).format('DD/MM/YYYY')
                }</div>,
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                distance: <div searchvalue={row.distance ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.distance)
                }</div>,
                hours: <div searchvalue={row.hours ?? 0}>{
                    App.AttributeFormatter.getFormatter('duration')(row.hours)
                }</div>,
                hardBraking: <div
                    searchvalue={row._data.braking ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row._data.braking === undefined || row._data.braking === "-") {
                                            return 'text-info'
                                        } else if (row._data.braking >= 80) {
                                            return 'text-success';
                                        } else if (row._data.braking >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row._data.braking === undefined ? "-" : row._data.braking}
                                </strong>

                            </strong>
                        </strong>
                    }</div>,
                hardCornering: <div searchvalue={row._data.cornering ?? 0}>{
                    <strong className="device-sensor">
                        <strong className="name">
                            <strong className={
                                (() => {
                                    if (row._data.cornering === undefined || row._data.cornering === "-") {
                                        return 'text-info'
                                    } else if (row._data.cornering >= 80) {
                                        return 'text-success';
                                    } else if (row._data.cornering >= 40) {
                                        return 'text-warning'
                                    } else {
                                        return 'text-danger'
                                    }
                                })()
                            }>
                                {row._data.cornering === undefined ? "-" : row._data.cornering}
                            </strong>

                        </strong>
                    </strong>
                }</div>,
                hardAcceleration: <div
                    searchvalue={row._data.acceleration ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row._data.acceleration === undefined || row._data.acceleration === "-") {
                                            return 'text-info'
                                        } else if (row._data.acceleration >= 80) {
                                            return 'text-success';
                                        } else if (row._data.acceleration >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row._data.acceleration === undefined ? "-" : row._data.acceleration}
                                </strong>

                            </strong>
                        </strong>
                    }</div>,
                total: <div
                    searchvalue={row._data.total ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row._data.total === undefined || row._data.total === "-") {
                                            return 'text-info'
                                        } else if (row._data.total >= 80) {
                                            return 'text-success';
                                        } else if (row._data.total >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row._data.total === undefined ? "-" : row._data.total}
                                </strong>
                            </strong>
                        </strong>
                    }</div>
            })
        })
        return rows;
    }

    getEcoScoreRows(values) {
        const rows = []
        values.forEach(row => {
            console.log("ECO ROW",row)
            rows.push({
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                endTime: <div searchvalue={row.startTime ?? 0}>{
                    App.AttributeFormatter.getFormatter('time')(row.startTime) 
                    + " - " +  App.AttributeFormatter.getFormatter('time')(row.endTime)
                }</div>,
                distance: <div searchvalue={row.distance ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.distance)
                }</div>,
                hours: <div searchvalue={row.hours ?? 0}>{
                    App.AttributeFormatter.getFormatter('duration')(row.hours)
                }</div>,
                hardBraking: <div
                    searchvalue={row._data.braking ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row._data.braking === undefined || row._data.braking === "-") {
                                            return 'text-info'
                                        } else if (row._data.braking >= 80) {
                                            return 'text-success';
                                        } else if (row._data.braking >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row._data.braking === undefined ? "-" : row._data.braking}
                                </strong>

                            </strong>
                        </strong>
                    }</div>,
                hardCornering: <div searchvalue={row._data.cornering ?? 0}>{
                    <strong className="device-sensor">
                        <strong className="name">
                            <strong className={
                                (() => {
                                    if (row._data.cornering === undefined || row._data.cornering === "-") {
                                        return 'text-info'
                                    } else if (row._data.cornering >= 80) {
                                        return 'text-success';
                                    } else if (row._data.cornering >= 40) {
                                        return 'text-warning'
                                    } else {
                                        return 'text-danger'
                                    }
                                })()
                            }>
                                {row._data.cornering === undefined ? "-" : row._data.cornering}
                            </strong>

                        </strong>
                    </strong>
                }</div>,
                hardAcceleration: <div
                    searchvalue={row._data.acceleration ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row._data.acceleration === undefined || row._data.acceleration === "-") {
                                            return 'text-info'
                                        } else if (row._data.acceleration >= 80) {
                                            return 'text-success';
                                        } else if (row._data.acceleration >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row._data.acceleration === undefined ? "-" : row._data.acceleration}
                                </strong>

                            </strong>
                        </strong>
                    }</div>,
                total: <div
                    searchvalue={row._data.total ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row._data.total === undefined || row._data.total === "-") {
                                            return 'text-info'
                                        } else if (row._data.total >= 80) {
                                            return 'text-success';
                                        } else if (row._data.total >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row._data.total === undefined ? "-" : row._data.total}
                                </strong>
                            </strong>
                        </strong>
                    }</div>
            })
        })
        return rows;
    }

    getDriverSummaryRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                driverUniqueId: <div searchvalue={row.driverName ?? ""}>{
                    row.driverName
                }</div>,
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                endTime: <div
                    searchvalue={row.startTime ?? 0}>{
                        moment(row.startTime).format('DD/MM/YYYY') + " - " + moment(row.endTime).format('DD/MM/YYYY')}</div>,
                distance: <div searchvalue={row.distance ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.distance)
                }</div>,
                startOdometer: <div searchvalue={row.startOdometer ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.startOdometer)
                }</div>,
                endOdometer: <div
                    searchvalue={row.endOdometer ?? 0}>{
                        App.AttributeFormatter.getFormatter('distance')(row.endOdometer)
                    }</div>,
                averageSpeed: <div
                    searchvalue={row.averageSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.averageSpeed)
                    }</div>,
                maxSpeed: <div
                    searchvalue={row.maxSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.maxSpeed)
                    }</div>,

                engineHours: <div
                    searchvalue={row.engineHours ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.engineHours)
                    }</div>,

                spentFuel: <div
                    searchvalue={row.spentFuel ?? 0}>{
                        App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel)
                    }</div>,

                fuelConsumption: <div
                    searchvalue={row.fuelConsumption ?? 0}>{
                        App.AttributeFormatter.getFormatter('consumption')(row.fuelConsumption)
                    }</div>,

                idleTime: <div
                    searchvalue={!row.idleTime || row.idleTime === t("sharedLoading") ? 0 : row.idleTime ?? 0}>{
                        row.idleTime === t("sharedLoading") ?
                            <strong className="device-sensor">
                                <strong className="name">
                                    <strong className={
                                        (() => {
                                            return 'text-info'
                                        })()
                                    }>
                                        {row.idleTime}
                                    </strong>
                                </strong>
                            </strong>
                            : App.AttributeFormatter.getFormatter('duration')(row.idleTime)
                    }</div>,

                ecoScore: <div
                    searchvalue={!row.ecoScore || row.ecoScore === t("sharedLoading") ? 0 : row.ecoScore ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row.ecoScore === t("sharedLoading")) {
                                            return 'text-info'
                                        } else if (row.ecoScore >= 80) {
                                            return 'text-success';
                                        } else if (row.ecoScore >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row.ecoScore}
                                </strong>

                            </strong>
                        </strong>
                    }</div>,
            })
        })
        return rows;
    }

    getStopRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                startTime: <div searchvalue={row.startTime ?? 0}>{
                    App.AttributeFormatter.getFormatter('time')(row.startTime)
                }</div>,
                startOdometer: <div searchvalue={row.startOdometer ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.startOdometer)
                }</div>,
                address: <div searchvalue={row.address ?? ""}>{
                    !row.address ? <Link href="#" component="button" color="primary" onClick={(e) => {
                        e.stopPropagation();
                        this.getAddress(row.latitude, row.longitude).then(data => {
                            const p = new ReportStop().deserialize(deepClone(row));
                            p.address = data;
                            Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                        }).catch(ex => App.App.showError(ex))
                    }}>{t('sharedShowAddress')}</Link>
                        : App.AttributeFormatter.getFormatter('address')(row.address)
                }</div>,
                endTime: <div
                    searchvalue={row.endTime ?? 0}>{
                        App.AttributeFormatter.getFormatter('time')(row.endTime)
                    }</div>,
                duration: <div
                    searchvalue={row.duration ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.duration)
                    }</div>,
                engineHours: <div
                    searchvalue={row.engineHours ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.engineHours)
                    }</div>,
                spentFuel: <div
                    searchvalue={row.spentFuel ?? 0}>{
                        App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel)
                    }</div>,
                clickEvent: () => this.handleClick(row)
            })
        })
        return rows;
    }

    getTripRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                startTime: <div searchvalue={row.startTime ?? 0}>{
                    App.AttributeFormatter.getFormatter('time')(row.startTime)
                }</div>,
                startOdometer: <div searchvalue={row.startOdometer ?? 0}>{
                    App.AttributeFormatter.getFormatter('distance')(row.startOdometer)
                }</div>,
                startAddress: <div searchvalue={row.startAddress ?? ""}>{
                    !row.startAddress ? <Link href="#" component="button" color="primary" onClick={(e) => {
                        e.stopPropagation();
                        this.getAddress(row.startLat, row.startLon).then(data => {
                            const p = new ReportTrip().deserialize(deepClone(row));
                            p.startAddress = data;
                            Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                        }).catch(ex => App.App.showError(ex))
                    }}>{t('sharedShowAddress')}</Link>
                        : App.AttributeFormatter.getFormatter('address')(row.startAddress)
                }</div>,
                endTime: <div searchvalue={row.endTime ?? 0}>{
                    App.AttributeFormatter.getFormatter('time')(row.endTime)
                }</div>,
                endOdometer: <div
                    searchvalue={row.endOdometer ?? 0}>{
                        App.AttributeFormatter.getFormatter('distance')(row.endOdometer)
                    }</div>,
                endAddress: <div
                    searchvalue={row.endAddress ?? ""}>{
                        !row.endAddress ? <Link href="#" component="button" color="primary" onClick={(e) => {
                            e.stopPropagation();
                            this.getAddress(row.endLat, row.endLon).then(data => {
                                const p = new ReportTrip().deserialize(deepClone(row));
                                p.endAddress = data;
                                Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                            }).catch(ex => App.App.showError(ex))
                        }}>{t('sharedShowAddress')}</Link>
                            : App.AttributeFormatter.getFormatter('address')(row.endAddress)
                    }</div>,
                distance: <div
                    searchvalue={row.distance ?? 0}>{
                        App.AttributeFormatter.getFormatter('distance')(row.distance)
                    }</div>,
                averageSpeed: <div
                    searchvalue={row.averageSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.averageSpeed)
                    }</div>,
                maxSpeed: <div
                    searchvalue={row.maxSpeed ?? 0}>{
                        App.AttributeFormatter.getFormatter('speed')(row.maxSpeed)
                    }</div>,
                duration: <div
                    searchvalue={row.duration ?? 0}>{
                        App.AttributeFormatter.getFormatter('duration')(row.duration)
                    }</div>,
                spentFuel: <div
                    searchvalue={row.spentFuel ?? 0}>{
                        App.AttributeFormatter.getFormatter('spentFuel')(row.spentFuel)
                    }</div>,
                fuelConsumption: <div
                    searchvalue={row.fuelConsumption ?? 0}>{
                        App.AttributeFormatter.getFormatter('consumption')(row.fuelConsumption)
                    }</div>,
                driverUniqueId: <div
                    searchvalue={row.driverName ?? ""}>{
                        row.driverName
                    }</div>,
                idleTime: <div
                    searchvalue={row.idleTime === t("sharedLoading") || !row.idleTime ? "" : row.idleTime ?? 0}>{
                        row.idleTime === t("sharedLoading") ?
                            <strong className="device-sensor">
                                <strong className="name">
                                    <strong className={
                                        (() => {
                                            return 'text-info'
                                        })()
                                    }>
                                        {row.idleTime}
                                    </strong>
                                </strong>
                            </strong>
                            :
                            App.AttributeFormatter.getFormatter('duration')(row.idleTime)
                    }</div>,
                ecoScore: <div
                    searchvalue={row.ecoScore === t("sharedLoading") || !row.ecoScore ? "" : row.ecoScore ?? 0}>{
                        <strong className="device-sensor">
                            <strong className="name">
                                <strong className={
                                    (() => {
                                        if (row.ecoScore === t("sharedLoading")) {
                                            return 'text-info'
                                        } else if (row.ecoScore >= 80) {
                                            return 'text-success';
                                        } else if (row.ecoScore >= 40) {
                                            return 'text-warning'
                                        } else {
                                            return 'text-danger'
                                        }
                                    })()
                                }>
                                    {row.ecoScore}
                                </strong>
                            </strong>
                        </strong>

                    }</div>,
                clickEvent: () => this.handleClick(row)
            })
        })
        return rows;
    }

    getEventRows(values) {
        const rows = []
        values.forEach(row => {
            rows.push({
                EventTime: <div searchvalue={App.AttributeFormatter.getFormatter('time')(row.eventTime).ucFirst()}>{
                    App.AttributeFormatter.getFormatter('time')(row.eventTime)
                }</div>,
                deviceId: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                type: <div
                    searchvalue={row.type === 'alarm' ? t('alarm' + row.attributes.alarm.ucFirst()) :
                     row.type === 'deviceRefuel' ?
                        
                      App.App.getEventString(row.type) + " (" + App.AttributeFormatter.getFormatter('spentFuel')(row.attributes["refuelThreshold"]) + ")" 
                     : App.App.getEventString(row.type)}>{
                        row.type === 'alarm' ? t('alarm' + row.attributes.alarm.ucFirst()) : row.type === 'deviceRefuel' ? App.App.getEventString(row.type) + " (" + App.AttributeFormatter.getFormatter('spentFuel')(row.attributes["refuelThreshold"]) + ")" : App.App.getEventString(row.type)}</div>,
                geofenceId: <div searchvalue={App.AttributeFormatter.getFormatter('geofenceId')(row.geofenceId)}>{
                    App.AttributeFormatter.getFormatter('geofenceId')(row.geofenceId)
                }</div>,
                maintenanceId: <div
                    searchvalue={App.AttributeFormatter.getFormatter('maintenanceId')(row.maintenanceId)}>{
                        App.AttributeFormatter.getFormatter('maintenanceId')(row.maintenanceId)
                    }</div>,
                clickEvent: () => this.handleClick(row)
            })
        })
        return rows;
    }

    getRouteRows(values) {
        console.log("VALUES",values)
        const rows = []
        values.forEach(row => {
            rows.push({
                deviceName: <div searchvalue={App.AttributeFormatter.getFormatter('deviceId')(row.deviceId).ucFirst()}>{
                    App.AttributeFormatter.getFormatter('deviceId')(row.deviceId)
                }</div>,
                ignition: <div searchvalue={row.ignition ?? false}>{
                    App.AttributeFormatter.getFormatter('ignition')(row.getAttribute('ignition') ? t("on") : t("off"))
                }</div>,
                fixTime: <div searchvalue={row.fixTime}>{
                    App.AttributeFormatter.getFormatter('time')(row.fixTime)
                }</div>,
                moving: <div searchvalue={row._data.attributes["motion"]}>{
                    row._data.attributes['motion'] ? t('eventDeviceMoving') : t('eventDeviceStopped')
                }</div>,
                fuel: <div searchvalue={row._data.attributes["io84"] || row._data.attributes["io89"]}>{
                    row._data.attributes["io89"] ? row._data.attributes["io89"] +"%"
                    : row._data.attributes["io84"] ? App.AttributeFormatter.volumeFormatter(row._data.attributes["io84"] / 10)
                    : "0"
                }</div>,
//                latitude: <div searchvalue={row.latitude}>{
//                    App.AttributeFormatter.getFormatter('latitude')(row.latitude)
//                }</div>,
//                longitude: <div searchvalue={row.longitude}>{
//                    App.AttributeFormatter.getFormatter('longitude')(row.longitude)
//                }</div>,
                altitude: <div searchvalue={row.altitude}>{
                    App.AttributeFormatter.getFormatter('altitude')(row.altitude)
                }</div>,
                speed: <div searchvalue={row.speed}>{
                    App.AttributeFormatter.getFormatter('speed')(row.speed)
                }</div>,
                address: <div searchvalue={row.address ?? ""}>{
                    !row.address ?
                        <Link href="#" component="button" color="primary" onClick={(e) => {
                            e.stopPropagation();
                            this.getAddress(row.latitude, row.longitude).then(data => {
                                const p = new ReportPosition().deserialize(deepClone(row));
                                p.address = data;
                                Events.emit('reports/UPDATE_POSITION', { id: row.id, data: p })
                            }).catch(ex => App.App.showError(ex))
                        }}>{t('sharedShowAddress')}</Link> :
                        App.AttributeFormatter.getFormatter('address')(row.address)
                }</div>,
                clickEvent: () => this.handleClick(row)
            })
        })
        return rows;
    }

    async handleClick(row) {
        //
        // if (row.selected) {
        //     return;
        // }
        //
        // const newData = [...this.state.reportData];
        // newData.map(d => {
        //     d.selected = false;
        // });
        // newData[rowIndex].selected = true;
        // this.setState({
        //     reportData: [...this.state.reportData]
        // })

        if (row instanceof ReportPosition || row instanceof Position) {
            if (this.state.reportType.key === 'route' ||
                this.state.reportType.key === 'stops') {
                const positions = [row];
                Events.emit('reports/MAP_DATA', {
                    data: {
                        [row.deviceId]: positions
                    },
                    config: {
                        showRoutes: true,
                        showMarkers: this.state.showMarkers,
                        showStops: this.state.showStops,
                        showIdle: this.state.showIdle,
                        route: true,
                        clearRoutes: false
                    },
                    filter: this.state.filter
                });
                Events.emit('reports/SELECT_POSITION', row.id);
            } else if (this.state.reportType.key === 'summary' || this.state.reportType.key === 'driverSummary') {

            } else if (this.state.reportType.key === 'events') {
                App.App.showLoading();
                try {
                    if (row.positionId === 0) {
                        return
                    }
                    const epositions = await PositionsService.list({ id: row.positionId });
                    const mapPositions = {};
                    epositions.map(p => {
                        if (!mapPositions[p.deviceId]) {
                            mapPositions[p.deviceId] = [];
                        }
                        mapPositions[p.deviceId].push(p);
                    });
                    Events.emit('reports/MAP_DATA', {
                        data: mapPositions,
                        config: {
                            showRoutes: true,
                            showMarkers: true,
                            event: true,
                            showStops: this.state.showStops,
                            showIdle: this.state.showIdle,
                        }
                    });
                    Events.emit('reports/SELECT_POSITION', row.id);
                } catch (ex) {
                } finally {

                    App.App.hideLoading();
                }
            } else {
                App.App.showLoading();
                try {
                    const filter = {
                        from: new Date(row.startTime).toISOString(),
                        to: new Date(row.endTime).toISOString(),
                        deviceId: row.deviceId,
                    }
                    const positions = await PositionsService.list(filter);
                    Events.emit('reports/MAP_DATA', {
                        data: {
                            [row.deviceId]: positions
                        },
                        config: {
                            showRoutes: true,
                            showMarkers: this.state.showMarkers,
                            showStops: this.state.showStops,
                            showIdle: this.state.showIdle
                        },
                        filter: filter
                    });
                    Events.emit('reports/SELECT_POSITION', row.id);
                } catch (ex) {
                } finally {
                    App.App.hideLoading();
                }
            }
        } else if (row instanceof ReportStop) {
            App.App.showLoading();
            try {
                const pos = await PositionsService.getOne({ id: row.positionId }, false)

                const positions = [pos];
                Events.emit('reports/MAP_DATA', {
                    data: {
                        [row.deviceId]: positions
                    },
                    config: {
                        showRoutes: true,
                        showMarkers: this.state.showMarkers,
                        showStops: this.state.showStops,
                        showIdle: this.state.showIdle,
                    }
                });

                Events.emit('reports/SELECT_POSITION', row.id);
            } catch (ex) {
                console.log(ex)
            } finally {
                App.App.hideLoading();
            }
        } else if (row instanceof ReportTrip) {
            App.App.showLoading();
            try {
                const filter = {
                    from: new Date(row.startTime).toISOString(),
                    to: new Date(row.endTime).toISOString(),
                    deviceId: row.deviceId,
                }
                const positions = await PositionsService.listReportPositions({
                    from: filter.from,
                    to: filter.to,
                    deviceId: filter.deviceId,
                });
                Events.emit('reports/MAP_DATA', {
                    data: {
                        [row.deviceId]: positions
                    },
                    config: {
                        showRoutes: true,
                        showMarkers: this.state.showMarkers,
                        showStops: this.state.showStops,
                        showIdle: this.state.showIdle
                    },
                    filter: filter
                });
            } catch (ex) {
            } finally {
                App.App.hideLoading();
            }
        }
         else if (row instanceof ReportSummary) {
                    App.App.showLoading();
                    try {
                        const filter = {
                            from: new Date(row.startTime).toISOString(),
                            to: new Date(row.endTime).toISOString(),
                            deviceId: row.deviceId,
                        }
                        const positions = await PositionsService.listReportPositions({
                            from: filter.from,
                            to: filter.to,
                            deviceId: filter.deviceId,
                        });
                        Events.emit('reports/MAP_DATA', {
                            data: {
                                [row.deviceId]: positions
                            },
                            config: {
                                showRoutes: true,
                                showMarkers: this.state.showMarkers,
                                showStops: this.state.showStops,
                                showIdle: this.state.showIdle
                            },
                            filter: filter
                        });
                    } catch (ex) {
                    } finally {
                        App.App.hideLoading();
                    }
                }
    }

}