import React from 'react';

export default class Collapse extends React.Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            open: props.open
        }
    }

    toggle() {
        this.setState({
            open: !this.state.open
        })
    }


    render() {
        return (
            <div className={'collapsible ' + (this.state.open ? 'expanded' : 'collapsed')}>
                <div className="header" onClick={() => this.toggle()}>
                    {this.props.title}
                </div>
                <div className="content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}